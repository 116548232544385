import { fs2RecyclerLocation } from "../firestore_schemas/fs2RecyclerLocation"
import { setDocIn_fs2RecyclerLocation } from "../firestore_schemas/fs2RecyclerLocation"
import { getDocFrom_fs2RecyclerLocation } from "../firestore_schemas/fs2RecyclerLocation"
import { setValueOfOptionalFields } from "../firestore_writers/include/general"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
  document_id?: string;
};

export async function fs2Writer(
  w_type: string,
  fs1_id: string,
  fs2_id: string,
  data: fs2RecyclerLocation
 ) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs2_writer:fs2Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if (!fs1_id) {
    retval.success = false;
    retval.return_msg += "fs1_id argument missing.";
    retval.error_title = "fs1_id argument missing.";
    retval.error_summary = "Recycler business location UID missing.";
    retval.error_next_steps = "Please provide correct Recycler business location UID.";
    return retval;
  }

  if (!fs2_id) {
    retval.success = false;
    retval.return_msg += "fs2_id argument missing.";
    retval.error_title = "fs2_id argument missing.";
    retval.error_summary = "Employee feedback record UID missing.";
    retval.error_next_steps = "Please provide correct Employee feedback record UID.";
    return retval;
  }
  /// <END> Validate arguments according to write w_type

  if (w_type === "e") {
    let response_data = await getDocFrom_fs2RecyclerLocation(fs1_id, fs2_id)

    if (response_data.success === false) {
      retval.success = false;
      retval.return_msg += "Record not found.";
      retval.error_title = "Record not found.";
      retval.error_summary = "FS2 record not found in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    const fs2Data: fs2RecyclerLocation = {
      recycler_location_uid: response_data['doc_data'].recycler_location_uid,
      recycler_name: data.recycler_name || response_data['doc_data'].recycler_name,
      phone: data.phone || response_data['doc_data'].phone,
      text_number: setValueOfOptionalFields(data.text_number, response_data['doc_data'].text_number, ""),
      email: data.email || response_data['doc_data'].email,
      city: data.city || response_data['doc_data'].city,
      state: data.state || response_data['doc_data'].state,
      postal_code: data.postal_code || response_data['doc_data'].postal_code,
      address_line_1: data.address_line_1 || response_data['doc_data'].address_line_1,
      address_line_2: setValueOfOptionalFields(data.address_line_2, response_data["doc_data"].address_line_2, ""),
      facebook_url: setValueOfOptionalFields(data.facebook_url, response_data["doc_data"].facebook_url, ""),
      google_maps_url: setValueOfOptionalFields(data.google_maps_url, response_data["doc_data"].google_maps_url, ""),
      review_link_url: setValueOfOptionalFields(response_data["doc_data"].review_link_url,null,null),
      website: setValueOfOptionalFields(data.website, response_data["doc_data"].website, ""),
      css_files: setValueOfOptionalFields(response_data['doc_data'].css_files,null,null),
      payment_api_keys_lookup_uid: setValueOfOptionalFields(response_data['doc_data'].payment_api_keys_lookup_uid,null,null),
      review_provider_account_uid: setValueOfOptionalFields(response_data['doc_data'].review_provider_account_uid,null,null),
      assigned_payment_method: setValueOfOptionalFields(data.assigned_payment_method, response_data['doc_data'].assigned_payment_method,null),
      sms_only_messaging: setValueOfOptionalFields(data.sms_only_messaging, response_data['doc_data'].sms_only_messaging, false),
      connect_account_id: setValueOfOptionalFields(data.connect_account_id, response_data['doc_data'].connect_account_id, null),
      conversation_starters_enabled: setValueOfOptionalFields(data.conversation_starters_enabled, response_data['doc_data'].conversation_starters_enabled, false),
      timezone: setValueOfOptionalFields(data.timezone, response_data['doc_data'].timezone, ""),
      days_open: setValueOfOptionalFields(data.days_open, response_data['doc_data'].days_open, null),
      business_hours: setValueOfOptionalFields(data.business_hours, response_data['doc_data'].business_hours, null),
      autosend_review_link_url: setValueOfOptionalFields(data.autosend_review_link_url, response_data['doc_data'].autosend_review_link_url, false),
      after_hours_message: setValueOfOptionalFields(data.after_hours_message, response_data['doc_data'].after_hours_message, ""),
      quick_queue_entry: setValueOfOptionalFields(data.quick_queue_entry, response_data['doc_data'].quick_queue_entry, false),
    } as fs2RecyclerLocation;

    let response = await setDocIn_fs2RecyclerLocation(
      fs1_id,
      fs2_id,
      fs2Data
    )

    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS2 record not updated in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }
    retval.document_id = response.id;
    return retval
  }
}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
