<template>
  <div>

    <!-- Desktop view -->
    <div :class="P_customClass" class="md:block hidden h-full">

      <!-- Navigation sandwich -->
      <div class="relative">
        <div class="grid grid-cols-2 gap-4 relative">
          <div @click.stop="DV_isOptionsExpanded = !DV_isOptionsExpanded" @blur="DV_isOptionsExpanded = false" class="w-min menu-button flex flex-row items-center justify-start w-48">
            <svg :class="DV_isOptionsExpanded ? 'rotate-180' : 'rotate-0'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="menu-button w-6 h-6 stroke-2 cursor-pointer transform transition-transform duration-200 ease-in-out">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
            </svg>
            <h2 class="menu-button font-bold pl-1">MENU</h2>
          </div>
          <div class="flex flex-row items-center justify-end">
            <img
              v-if="DV_recyclerLogo"
              class="recycler-header-logo"
              :src="DV_recyclerLogo"
            />

            <img
              v-else
              class="recycler-header-logo"
              :src="require('../../assets/images/logo.png')"
            />
          </div>
        </div>

        <transition
          enter-active-class="transform transition duration-500 ease-custom"
          enter-class="-translate-y-1/2 scale-y-0 opacity-0"
          enter-to-class="translate-y-0 scale-y-100 opacity-100"
          leave-active-class="transform transition duration-300 ease-custom"
          leave-class="translate-y-0 scale-y-100 opacity-100"
          leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
        >
          <ul v-show="DV_isOptionsExpanded" class="absolute left-0 right-0 top-12 mb-4 bg-white divide-y rounded-b-lg drop-shadow-lg overflow-hidden w-48 z-10">
            
            <!-- Sales Dashboard -->
            <li v-if="P_activePage == 'dashboard'" class="text-left font-medium cursor-pointer transition-colors duration-300 hover:bg-gray-200 z-10 w-full !mt-0">
              <a href="/sales-dashboard" class="px-3 py-2 block">Sales Dashboard</a>
            </li>

            <router-link v-else to="/sales-dashboard" tag="li" @click="DV_isOptionsExpanded = false" class="menu-list-item">
              Sales Dashboard
            </router-link>

            <!-- Reporting -->
            <!-- <li v-if="P_activePage == 'reporting'" class="text-left font-medium cursor-pointer transition-colors duration-300 hover:bg-gray-200 z-10 !mt-0 w-full">
              <a href="/reporting-dashboard" class="px-3 py-2 block">Reporting</a>
            </li>

            <router-link v-else to="/reporting-dashboard" tag="li" @click="DV_isOptionsExpanded = false" class="menu-list-item">
              Reporting
            </router-link> -->
            
            <!-- Transactions -->
            <li v-if="P_activePage == 'transactions'" class="text-left font-medium cursor-pointer transition-colors duration-300 hover:bg-gray-200 z-10 !mt-0 w-full">
              <a href="/transactions" class="px-3 py-2 block">Transactions</a>
            </li>
            
            <router-link v-else to="/transactions" v-show="C_businessType == 1" tag="li" @click="DV_isOptionsExpanded = false"
              class="menu-list-item"
            >
              Transactions
            </router-link>

            <!-- Contacts -->
            <li v-if="P_activePage == 'contacts'" class="text-left font-medium cursor-pointer transition-colors duration-300 hover:bg-gray-200 z-10 !mt-0 w-full">
              <a href="/contacts" class="px-3 py-2 block">Contacts</a>
            </li>
            
            <router-link v-else to="/contacts" tag="li" @click="DV_isOptionsExpanded = false"
              class="menu-list-item"
            >
              Contacts
            </router-link>

            <!-- Settings -->
            <li v-if="P_activePage == 'settings'" v-show="C_isAdmin" class="text-left font-medium cursor-pointer transition-colors duration-300 hover:bg-gray-200 z-10 !mt-0 w-full">
              <a href="/settings" class="px-3 py-2 block">Settings</a>
            </li>

            <router-link v-else v-show="C_isAdmin" to="/settings" tag="li" @click="DV_isOptionsExpanded = false" class="menu-list-item">
              Settings
            </router-link>

            <!-- Help & support -->
            <li v-if="P_activePage == 'help_&_support'" class="text-left font-medium cursor-pointer transition-colors duration-300 hover:bg-gray-200 z-10 !mt-0 w-full">
              <a href="/help_&_support" class="px-3 py-2 block">Help & Support</a>
            </li>

            <router-link v-else to="/help_&_support" tag="li" @click="DV_isOptionsExpanded = false" class="menu-list-item">
              Help & Support
            </router-link>

            <!-- Logout -->
            <router-link to="#" tag="li" @click.native="logoutUser" class="menu-list-item">
              Logout
            </router-link>
          </ul>
        </transition>
      </div>
    </div>

    <!-- Mobile View -->
    <div class="md:hidden block">
      <div class="grid grid-cols-2 gap-4">
        <div @click.stop="DV_isOptionsExpanded = !DV_isOptionsExpanded" @blur="DV_isOptionsExpanded = false" class="menu-button flex flex-row items-center justify-start">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="menu-button w-6 h-6 stroke-2 primary-text cursor-pointer">
            <path class="menu-button" stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
          </svg>
          <h2 class="menu-button font-bold pl-1">MENU</h2>
        </div>

        <transition
          enter-active-class="transform transition duration-500 ease-custom"
          enter-class="-translate-y-1/2 scale-y-0 opacity-0"
          enter-to-class="translate-y-0 scale-y-100 opacity-100"
          leave-active-class="transform transition duration-300 ease-custom"
          leave-class="translate-y-0 scale-y-100 opacity-100"
          leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
        >

          <!-- Navigation buttons -->
          <ul v-show="DV_isOptionsExpanded" class="absolute left-0 right-0 top-16 bg-white divide-y rounded-b-lg drop-shadow-lg overflow-hidden w-48 z-10">
            <router-link to="/sales-dashboard" tag="li" @click="DV_isOptionsExpanded = false" class="menu-list-item">
              Sales Dashboard
            </router-link>

            <!-- <router-link to="/reporting-dashboard" tag="li" @click="DV_isOptionsExpanded = false" class="menu-list-item">
              Reporting
            </router-link> -->

            <router-link to="/contacts" tag="li" @click="DV_isOptionsExpanded = false" class="menu-list-item">
              Contacts
            </router-link>

            <router-link to="/transactions" tag="li" @click="DV_isOptionsExpanded = false" class="menu-list-item">
              Transactions
            </router-link>

            <router-link to="/settings" tag="li" @click="DV_isOptionsExpanded = false" class="menu-list-item">
              Settings
            </router-link>

            <router-link to="/settings" tag="li" @click="DV_isOptionsExpanded = false" class="menu-list-item">
              Help & Support
            </router-link>

            <router-link to="#" tag="li" @click.native="logoutUser" class="menu-list-item">
              Logout
            </router-link>
          </ul>
        </transition>

        <div class="flex flex-row items-center justify-end">
          <img
            v-if="DV_recyclerLogo"
            class="w-1/4"
            :src="DV_recyclerLogo"
          />

          <img
            v-else
            class="w-1/4"
            :src="require('../../assets/images/logo.png')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "c9MainMenu",

  props: ['P_activePage', 'P_customClass'],

  data() {
    return {
      DV_currentEmployee: window.G_bi2_data.IV_active_employee_info,
      DV_businesses: window.G_bi2_data.IV_businesses,
      DV_isOptionsExpanded: false,
      DV_recyclerLogos: {},
      DV_recyclerLogo: null,
    }
  },

  computed: {
    C_isAdmin() {
      if (Object.entries(window.G_bi2_data.IV_active_employee_info).keys === 0) { return false; }
      return window.G_bi2_data.IV_active_employee_info.permissions.admin === true;
    },

    C_businessType() {
      if (Object.entries(this.DV_businesses).length == 0) { return 0; }
      return this.DV_businesses[this.DV_currentEmployee.business_uid].business_type;
    }
  },

  mounted() {
    const CI = this;

    CI.DV_currentEmployee = window.G_bi2_data.IV_active_employee_info;
    CI.DV_businesses = window.G_bi2_data.IV_businesses;

    document.addEventListener('click', (e) => {
      if (e.target.classList.contains("menu-button")) { return; };
      CI.DV_isOptionsExpanded = false;
    }, false);

    CI.DV_recyclerLogos = window.G_bi2_data.IV_recyclers_logo_urls;
  },

  methods: {
    logoutUser() {
      this.DV_isOptionsExpanded = false;
      window.G_firebase_auth.signOut();
    },

    setLogoUrl() {
      const CI = this;

      const activeBusiness = window.G_bi2_data.IV_active_employee_info.business_uid
      const activeLocation = window.G_bi2_data.IV_active_employee_info.recycler_location_uid

      let recycler_business_location_uid = `${activeBusiness}-${activeLocation}`

      if (recycler_business_location_uid in CI.DV_recyclerLogos === true) {
        CI.DV_recyclerLogo = CI.DV_recyclerLogos[recycler_business_location_uid];
      }
    }
  },

  beforeDestroy() {
    const CI = this;

    document.removeEventListener('click', (e) => {
      if (e.target.classList.contains("menu-button")) { return; };
      CI.DV_isOptionsExpanded = false;
    }, false);
  },

  watch: {
    DV_recyclerLogos: {
      handler() {
        const CI = this;

        CI.setLogoUrl();
      },

      deep: true
    }
  }
}
</script>

<style scoped>
.recycler-header-logo {
  display: block;
  max-width: 125px;
  max-height: 60px;
  width: auto;
  height: auto;
}
</style>