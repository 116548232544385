<template>
  <PopupModal ref="quotesPopupModal" :P_mediumModal="true" :P_showClose="true" @close-modal="closeSelf">
    
    <div slot="title"> <h3 class="font-bold">Payment Links</h3> </div>
    
    <template slot="body">
      <div id="c1c8" class="block mt-4 border border-gray-400 shadow-sm p-3 rounded-xl max-h-[76vh] overflow-scroll hide-scrollbar">
        <h3 class="font-semibold text-center mb-2 mx-auto"> Open Payment Links</h3>
        <div v-if="Object.keys(DV_allPaymentLinksData[P_conversationUuid]) == 0" class="flex justify-center text-sm text-red-400">No open payment links for this customer</div>

        <div class="flex items-center justify-between">
          <!-- Flex buttons if the number of vehicles are less than 3 -->
          <div v-if="P_conversationUuid in DV_allPaymentLinksData" class="flex items-center justify-center flex-wrap w-full">
            <button 
              v-for="item in DV_allPaymentLinksData[P_conversationUuid]" 
              :key="item.payment_link_uid"
              class="text-sm filter-button flex flex-col w-fit h-fit m-3"
              :class="{active:DV_selectedPayLinkUid == item.payment_link_uid}"
              @click.stop="toggleSelectActivePaymentLink(item.payment_link_uid)"
            >
              <span>Description: {{ item.description }}</span>
              <span>Order No: {{ item.order_number }}</span>
              <span>Amount: ${{ item.amount.toFixed(2) }}</span>
              
              <span v-if="item.payment_status === 'partial_refund'">Refunded Amount: ${{ item.refunded_amount.toFixed(2) }}</span>

              <span v-if="item.payment_status === 'no_status'">Status: No Actions </span>
              <span v-if="item.payment_status === 'paid'">Status: Paid</span>
              <span v-if="item.payment_status === 'failed'">Status: Failed</span>
              <span v-if="item.payment_status === 'in_progress'">Status: Processing</span>
              <span v-if="item.payment_status === 'partial_refund'">Status: Partial Refund</span>
              <span v-if="item.payment_status === 'refunded'">Status: Refunded</span>

            </button> 
          </div>
        </div>

        <div class="flex justify-center">
          <button v-if="DV_activeMode !== DV_modeCreate" @click.stop="createPaymentLink" class="btn btn-lime text-sm mr-1">Create New Pay Link</button>  
        </div>

        <div id="payment-link-error" class="text-red-400 hidden text-center mt-2 text-sm">
            {{ DV_paymentErrorMessage }}
        </div>
        
        <div class="flex flex-col pb-5 items-center" style="grid-template-columns: max-content min-content;">
          <h3 class="font-medium text-center mt-8 mb-2 mx-auto"> Payment Link Details</h3>
          <!-- <h3 v-if="DV_activeMode === DV_modeEdit" class="font-bold text-center mt-8 mb-2"> Editing Payment Link</h3>
          <h3 v-if="DV_activeMode === DV_modeCreate" class="font-bold text-center mt-8 mb-2"> Creating Payment Link</h3> -->

          <div class="flex w-4/5 mb-2">
            <span class="text-left w-64 mr-4">Description: 
              <span class="tooltip">
                ?
                <span class="tooltiptext whitespace-pre-line" :style="DV_toolTipStyle">Optional: Add a brief description of the transaction. This will be displayed on the customer's receipt. For example, 'Replacement brake pads' or 'Front bumper for 2015 Honda Civic'.</span>
              </span>
            </span>
            <input autocomplete="off" v-model="DV_paymentLinkDescription" type="text" name="order-number" class="" :disabled="DV_savingPaymentLink == true">
          </div>
            
          <div class="flex w-4/5 mb-2">
            <span class="text-left whitespace-nowrap w-64 mr-4">Amount ($) *:
              <span class="tooltip">
                ?
                <span class="tooltiptext whitespace-pre-line" :style="DV_toolTipStyle">Required: Enter the total billable amount for the customer. This should be an all-inclusive amount, comprising product cost, taxes, shipping, or any other fees. Remember, you don't need to itemize taxes or shipping details here since those breakdowns are managed by the Yard Management System (YMS).</span>
              </span>
            </span>
            <input autocomplete="off" v-model="DV_paymentLinkAmount" pattern="[0-9]*" inputmode="numeric" type="number" name="payment" class="w-full" :disabled="DV_savingPaymentLink == true">
          </div>
            
          <div class="flex w-4/5 mb-2">
            <span class="text-left whitespace-nowrap w-64 mr-4">Order Number:
              <span class="tooltip">
                ?
                <span class="tooltiptext whitespace-pre-line" :style="DV_toolTipStyle">Optional: Input the YMS order number if available. This helps in matching the payment transaction to the specific order in the Yard Management System later on.</span>
              </span>
            </span>
            <input autocomplete="off" v-model="DV_paymentLinkOrderNumber"  type="text" name="order-number" class="" :disabled="DV_savingPaymentLink == true">
          </div>
            
          <div class="flex w-4/5 mb-2">
            <span class="w-64 mr-4 flex">
              <span class="text-left whitespace-nowrap">Payment URL:
                <span class="tooltip">
                  ?
                  <span class="tooltiptext whitespace-pre-line" :style="DV_toolTipStyle">This field will automatically populate with the secured payment link once you select 'Create New Pay Link'. The link will also be sent directly to the customer for payment completion.</span>
                </span>
              </span>
              <svg @click.stop="copyPaymentUrlToClipBoard" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="col-start-2 text-gray-500 mx-1 w-6 h-6 hover:text-gray-900 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
              </svg>
            </span>
            <input autocomplete="off" :disabled="true" v-model="DV_paymentLinkUrl" type="text" name="order-number" class="">
          </div>

          <div v-show="Object.keys(C_transactionTermsAndConditions).length > 0"  class="flex w-4/5">
            <span class="text-left whitespace-nowrap w-64 mr-4">Transaction terms:
              <span class="tooltip">
                ?
                <span class="tooltiptext whitespace-pre-line" :style="DV_toolTipStyle">Optional: Select the appropriate transaction term and condition rule.</span>
              </span>
            </span>

            <select v-model="DV_paymentTermsTypeNumber">
              <option v-for="[key, value] in Object.entries(C_transactionTermsAndConditions)" :value="value.type_number">{{value.type_description}}</option>
            </select>
          </div>

          <div class="flex items-center justify-center mt-8 w-full">
            <button :disabled="DV_savingPaymentLink == true" v-if="DV_activeMode === DV_modeEdit" @click.stop="validateAndSavePaymentLink" class=" btn btn-lime text-sm mr-3">{{DV_savingPaymentLink == true ? 'Updating' : 'Update'}} Payment Link</button>
            <button :disabled="DV_savingPaymentLink == true" v-if="DV_activeMode === DV_modeCreate" @click.stop="validateAndSavePaymentLink" class=" btn btn-lime text-sm mr-3">{{DV_savingPaymentLink == true ? 'Creating' : 'Create'}} New Pay Link</button>

            <button :disabled="DV_savingPaymentLink == true" @click.stop="discardChanges" class="btn btn-lime text-sm">Discard Changes</button>
          </div>

          <!-- Create link form -->
        </div>
      </div>
      <PopupModal ref="verifyStripeIdentity" :P_fullModal="true" :P_showClose="true" P_modalClasses="!w-11/12 verify-stripe-identity" @close-modal="closeStripeIdentity">
        <div slot="title"> 
          <h3 class="font-bold">Identity Theft Verification</h3> 
        </div>
    
        <template slot="body">
          <p class="text-center">Cost: $1.75 per verification</p>
          <p class="text-center">The "Identity Theft Verification" feature confirms that your customer is indeed who they claim to be, minimizing the chances of fraud. While this verification aligns with the custom requirements set by your admin on your account, please note its frequent use can significantly inflate your monthly Synergy Auto Solutions bill. We recommend exercising discretion.</p>

          <h2 class="text-xl text-center">Do you wish to proceed with this verification?</h2>

          <div class="flex flex-row mt-4 justify-around">
            <button @click.stop="savePaymentLink(true)" class="btn btn-lime">Yes, I confirm</button>
            <button @click.stop="savePaymentLink(false)" class="btn btn-white">No, proceed with payment link</button>
          </div>

          <p class="text-sm text-center mt-4">By clicking "Yes, I Confirm", you consent to a $1.75 charge <br>being added to your monthly usage for Synergy Auto Solutions.</p>
        </template>
      </PopupModal>
    </template>
  </PopupModal>
</template>

<script>
  import PopupModal from "../shared/PopupModal"
  import {fs36Writer} from '../../includes/firestore_writers/fs36_writer'
  import createUpdatePaymentLink from '../../includes/server_calls/p1s3/p1s3e22'
  import createIdentityVerificationLink from '../../includes/server_calls/p1s7/p1s7e2'

  export default {
    name: "c1c8ConversationQuotes",

    props: ["P_conversationUuid"],

    components: { PopupModal },

    data() {
      return {
        DV_showUnfinishedFeatures: window.G_show_unfinished_features,
        DV_selectedPayLinkUid: null,
        DV_paylinkDescription: "",
        DV_paymentLinkAmount: 0,
        DV_paymentLinkOrderNumber: "",
        DV_paymentLinkDescription: "",
        DV_paymentLinkUrl: "",
        DV_paymentTermsTypeNumber: 0,
        DV_activeMode: 0,
        DV_modeEdit: 1,
        DV_modeCreate: 2,
        DV_activePaymentLink: {},
        DV_paymentErrorMessage: "",
        DV_allPaymentLinksData: window.G_bi2_data.IV_consumer_conversation_payment_links.value,
        DV_savingPaymentLink: false,
        DV_toolTipStyle:{},
        DV_identityVerificationActive: false,
        DV_minimumCharges: 0
      }
    },

    mounted() {
      const CI = this;

      CI.DV_activeMode = CI.DV_modeCreate;

      if (CI.DV_activeRenderMode < CI.DV_renderModeTablet) {
        CI.DV_toolTipStyle = reactive({
          width: '80vw',
          position: 'absolute',
          left:'-65vw'
        });
      }

      const recyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;
      if (recyclerLocationUid in window.G_bi2_data.IV_stripe_identity_verification.value) {
        const stripe_identity_info = window.G_bi2_data.IV_stripe_identity_verification.value[recyclerLocationUid];

        CI.DV_identityVerificationActive = stripe_identity_info.stripe_identity_verification_enabled;
        CI.DV_minimumCharges = stripe_identity_info.minimum_transaction_amount;
      }

      this.resetTerms()
    },

    methods: {
      showSelf() {
        this.$refs.quotesPopupModal.show();
      },

      closeSelf() {
        this.clearFields();
        this.$refs.quotesPopupModal.hide();
      },

      discardChanges() {
        if (this.DV_selectedPayLinkUid === undefined || this.DV_selectedPayLinkUid === null) {
          this.clearFields();
          this.closeSelf();
        } else {
          this.loadPaymentLinkData(this.DV_selectedPayLinkUid);
          this.closeSelf();
        }
        return;
      },

      copyPaymentUrlToClipBoard() {
        navigator.clipboard.writeText(this.DV_paymentLinkUrl);

      },

      loadPaymentLinkData(id) {
        this.DV_selectedPayLinkUid = id

        this.DV_activePaymentLink = this.DV_allPaymentLinksData[this.P_conversationUuid][id];
        this.DV_paymentLinkAmount = this.DV_allPaymentLinksData[this.P_conversationUuid][id].amount;
        this.DV_paymentLinkOrderNumber = this.DV_allPaymentLinksData[this.P_conversationUuid][id].order_number;
        this.DV_paymentLinkDescription = this.DV_allPaymentLinksData[this.P_conversationUuid][id].description;
        this.DV_paymentTermsTypeNumber = this.DV_allPaymentLinksData[this.P_conversationUuid][id].terms_type_number;
        this.DV_paymentLinkUrl = window.G_bi2_data.IV_consumer_conversations.value[this.P_conversationUuid].conversation_invite_url + "?p=" + id
        this.DV_activeMode = this.DV_modeEdit;
      },

      clearFields() {
        this.DV_activePaymentLink = {}
        this.DV_paymentLinkAmount = 0;
        this.DV_paymentLinkOrderNumber = "";
        this.DV_paymentLinkDescription = "";
        this.DV_paymentLinkUrl = "";
        this.DV_selectedPayLinkUid = null;
        this.DV_activeMode = this.DV_modeCreate;
        this.resetTerms()
      },

      resetTerms() {
        if (Object.keys(this.C_transactionTermsAndConditions).length > 0) {
          let firstItem = Object.values(this.C_transactionTermsAndConditions)[0]
          this.DV_paymentTermsTypeNumber = firstItem.type_number;
        }
      },

      toggleSelectActivePaymentLink(id) {
        document.activeElement.blur();
        if (this.DV_selectedPayLinkUid === id) {
          this.clearFields();
          return;
        }
        this.loadPaymentLinkData(id);
      },

      validateAndSavePaymentLink() {
        const CI = this;

        CI.DV_savingPaymentLink = true;
        if (CI.DV_identityVerificationActive === true && +CI.DV_paymentLinkAmount >= +CI.DV_minimumCharges) {
          CI.$refs.verifyStripeIdentity.show();
          CI.DV_savingPaymentLink = false;
          return;
        }

        CI.savePaymentLink(false);
      },

      savePaymentLink(request_verification=false) {
        const CI = this;

        let write_type = "n";
        if (CI.DV_selectedPayLinkUid !== null ) { write_type = "e";}
        CI.DV_savingPaymentLink = true;

        if (+CI.DV_paymentLinkAmount == 0) {
          CI.DV_paymentErrorMessage = "Amount must be greater than $0"
          let errorArea = document.getElementById("payment-link-error")
          errorArea.classList.remove("hidden");
          setTimeout(() => { errorArea.classList.add("hidden");}, 2000)
          CI.DV_savingPaymentLink = false;
          return;
        }

        let paymentAmount = Number(CI.DV_paymentLinkAmount)

        let consumer_contact_uid;
        let conversation_uid;

        if (!window.G_bi2_data.IV_employees_active_conversations.value[CI.P_conversationUuid]) {
          [consumer_contact_uid, conversation_uid] = CI.P_conversationUuid.split("-");
        } else {
          consumer_contact_uid = window.G_bi2_data.IV_employees_active_conversations.value[CI.P_conversationUuid].consumer_uid;
          conversation_uid = window.G_bi2_data.IV_employees_active_conversations.value[CI.P_conversationUuid].conversation_uid;
        }

        fs36Writer(write_type,
          window.G_bi2_data.IV_active_employee_info.business_uid,
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          consumer_contact_uid,
          conversation_uid,
          CI.DV_selectedPayLinkUid,
          {
            description: CI.DV_paymentLinkDescription,
            amount: paymentAmount,
            order_number: CI.DV_paymentLinkOrderNumber,
            payment_processor_id: 1,
            terms_type_number: CI.DV_paymentTermsTypeNumber,
            ...(write_type === "n" && { payment_status: "no_status" })
          }
        ).then((call_result) => {
          if (call_result.success !== true) {
            CI.$awn.alert("There was an issue saving the information. Please reload page and try again.")
            CI.DV_savingPaymentLink = false;
            return;
          }

          CI.DV_savingPaymentLink = false;
          CI.$refs.verifyStripeIdentity.hide();
          CI.closeSelf();

          let params = {
            recycler_uid: window.G_bi2_data.IV_active_employee_info.business_uid,
            recycler_location_uid: window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            employee_uid: window.G_bi2_data.IV_active_employee_info.employee_uid,
            consumer_uid: consumer_contact_uid,
            conversation_uid: conversation_uid ,
            fs36_doc_id: call_result.document_id,            
          }

          // Generate identity verification link
          if (request_verification === true) {
            createIdentityVerificationLink(params).then((resp) => {
              if (resp.success !== true) {
                CI.$awn.alert("There was an issue requesting the verification link. Please reload page and try again.")
                if (write_type === "n") { CI.deletePaymentLink(call_result.document_id); }
              }
            }).catch((error) => {
              CI.$awn.alert("There was an issue requesting the verification link. Please reload page and try again.")
              if (write_type === "n") { CI.deletePaymentLink(call_result.document_id); }
            });

            return;
          }

          // Else generate payment link          
          params.employee_uid = window.G_bi2_data.IV_active_employee_info.employee_uid;
          params.conversation_invite_url = window.G_bi2_data.IV_consumer_conversations.value[CI.P_conversationUuid].conversation_invite_url;

          createUpdatePaymentLink(params).then((resp) => {
            if (resp.success !== true) {
              CI.$awn.alert("There was an issue saving the information. Please reload page and try again.")
              if (write_type === "n") { CI.deletePaymentLink(call_result.document_id); }
            }
          }).catch((error) => {
            CI.$awn.alert("There was an issue saving the information. Please reload page and try again.")
            if (write_type === "n") { CI.deletePaymentLink(call_result.document_id); }
          });
        })
      },

      deletePaymentLink(document_id) {
        const CI = this;
 
        let consumer_contact_uid;
        let conversation_uid;

        if (!window.G_bi2_data.IV_employees_active_conversations.value[CI.P_conversationUuid]) {
          [consumer_contact_uid, conversation_uid] = CI.P_conversationUuid.split("-");
        } else {
          consumer_contact_uid = window.G_bi2_data.IV_employees_active_conversations.value[CI.P_conversationUuid].consumer_uid;
          conversation_uid = window.G_bi2_data.IV_employees_active_conversations.value[CI.P_conversationUuid].conversation_uid;
        }
        
        fs36Writer("d",
          window.G_bi2_data.IV_active_employee_info.business_uid,
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          consumer_contact_uid,
          conversation_uid,
          document_id
        )
      },

      createPaymentLink() {
        this.DV_activeMode = this.DV_modeCreate;
        this.clearFields();
      },

      closeStripeIdentity() {
        const CI = this;

        CI.$refs.verifyStripeIdentity.hide();
      }
    },

    watch: {
      DV_activePaymentLink: {      
        handler() {
          if (this.DV_selectedPayLinkUid === undefined || this.DV_selectedPayLinkUid === null) {
            return;
          }
          this.loadPaymentLinkData(this.DV_selectedPayLinkUid)
        },
        
        deep: true
      }
    },

    computed: {
      C_fieldsChanged() {
        let current_data = {
          amount: this.DV_paymentLinkAmount,
          order_number: this.DV_paymentLinkOrderNumber,
          description: this.DV_paymentLinkDescription
        }

        if (this.DV_selectedPayLinkUid === null ) { 
          return false;
        }

        let changed_flag = false;
        let id = this.DV_selectedPayLinkUid;
        
        for (let key in current_data) {
          if (current_data[key] !== this.DV_allPaymentLinksData[this.P_conversationUuid][id][key]) {
            changed_flag = true; 
            break;
          }
        }

        return changed_flag;
      },

      C_transactionTermsAndConditions() {
        return window.G_bi2_data.IV_transaction_terms_and_conditions.value
      }
    }
  }
</script>