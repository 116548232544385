import { fs1RecyclerBusiness } from "../firestore_schemas/fs1RecyclerBusiness"
import { setDocIn_fs1RecyclerBusiness } from "../firestore_schemas/fs1RecyclerBusiness"
import { getDocFrom_fs1RecyclerBusiness } from "../firestore_schemas/fs1RecyclerBusiness"
import { setValueOfOptionalFields } from "../firestore_writers/include/general"

type Result = {
  debug_data: Array<string>;
  return_msg: string;
  success: boolean;
  error_title: string;
  error_summary: string;
  error_next_steps: string;
  document_id?: string;
};

export async function fs1Writer(
  w_type: string,
  fs1_id: string,
  data={
    business_uid: "",
    business_name: "",
    is_archived: false,
    phone: "",
    owner_first_name: "",
    owner_last_name: "",
    owner_email: "",
    owner_firebase_uid: "",
    billing_method_uid: "",
    business_type: 1,
  }) {

  const retval: Result = {
    debug_data: [],
    return_msg: "fs1_writer:fs1Writer ",
    success: true,
    error_title: "",
    error_summary: "",
    error_next_steps: "",
  };

  /// Validate arguments according to write type
  if((w_type === "d" || w_type === "e") && (!fs1_id || !data.phone || !data.business_name)) {
    retval.success = false;
    retval.return_msg += "fs1_id argument missing.";
    retval.error_title = "fs1_id argument missing.";
    retval.error_summary = "Recycler business UID missing.";
    retval.error_next_steps = "Please provide correct Recycler business UID.";
    return retval;
  }
  /// <END> Validate arguments according to write w_type

  if (w_type === "e") {
    let response_data = await getDocFrom_fs1RecyclerBusiness(fs1_id)

    if (response_data.success === false) {
      retval.success = false;
      retval.return_msg += "Record not found.";
      retval.error_title = "Record not found.";
      retval.error_summary = "FS1 record not found in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }

    
    const fs1Data: fs1RecyclerBusiness = {
      business_uid: response_data['doc_data'].business_uid,
      billing_method_uid: response_data['doc_data'].billing_method_uid,
      owner_firebase_uid: response_data['doc_data'].owner_firebase_uid,
      owner_first_name: setValueOfOptionalFields(data.owner_first_name, response_data['doc_data'].owner_first_name, ""),
      owner_last_name: setValueOfOptionalFields(data.owner_last_name, response_data['doc_data'].owner_last_name, ""),
      business_name: setValueOfOptionalFields(data.business_name, response_data['doc_data'].business_name, ""),
      is_archived: setValueOfOptionalFields(data.is_archived, response_data['doc_data'].is_archived, false),
      phone: setValueOfOptionalFields(data.phone, response_data['doc_data'].phone, ""),
      owner_email: setValueOfOptionalFields(data.owner_email, response_data['doc_data'].owner_email, ""),
      business_type: response_data['doc_data'].business_type,
    } as fs1RecyclerBusiness;

    let response = await setDocIn_fs1RecyclerBusiness(
      fs1_id,
      fs1Data
    )

    if (!response.id || response.id == "" ) {
      retval.success = false;
      retval.return_msg += "Firestore write error.";
      retval.error_title = "Firestore write error.";
      retval.error_summary = "FS1 record not updated in firestore.";
      retval.error_next_steps = "Please reload page and try again.";
      return retval;
    }
    retval.document_id = response.id;
    return retval
  }
}

function firestoreWriteError(error:any) {
  // TODO - complete this
}
