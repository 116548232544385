<template>
  <div id="p3s4">
    <div class="account-setup-step-body flex flex-col items-center mt-5 w-full">
      <div class="flex flex-col items-center mb-5">
        <span class="sm:text-3xl text-center">Join the Future of Auto-Recycling:<br> Sign Up for 
          <span class="font-bold">Synergy Auto Solutions Today!</span>
        </span>
        
        <span 
          v-if="DV_activeRenderMode > DV_renderModePhone"
          class="pt-3 pl-3 pr-3 text-sm sm:text-base"
          :class="{'text-left': DV_activeRenderMode < DV_renderModeDesktop}"
        >
          Explore Synergy Auto Solutions, a top-notch software platform designed for the automotive recycling industry! 
          Sign up in just a few minutes and access a range of exciting features. Upon completing your account setup, we will send you a
          welcome email to your provided address. This email will detail additional steps required to fully optimize your use of
          Synergy Auto Solutions and ensure you are 100% up and running.
        </span>
      </div>

      <div 
        :class="{'flex flex-col' : DV_activeRenderMode < DV_renderModeTablet,
                'grid grid-cols-2': DV_activeRenderMode > DV_renderModePhone}"
        class="border-2 border-slate-300  items-center w-fit gap-3 text-center pr-5 pl-5 pt-2 mx-auto"
      >
        <span class="whitespace-nowrap col-start-1 text-left">Business Name:
          <span class="tooltip">
            ?
            <span class="tooltiptext whitespace-pre-line" :style="DV_toolTipStyle">Enter the official, registered name of your business as it appears on legal documents and government records</span>
          </span>
        </span>

        <span class="col-start-2" :class="{'w-60' : DV_activeRenderMode == DV_renderModeTablet, 'w-80': DV_activeRenderMode != DV_renderModeTablet}">
          <input autocomplete="off" v-model="DV_businessName" @input="DV_businessNameError = ''" type="text">
          <span  v-if="DV_businessNameError !== ''" class=" col-3 text-sm text-red-400">{{ DV_businessNameError }} </span>
        </span>

        <span class="whitespace-nowrap col-start-1 text-left">Business Website:
          <span class="tooltip">
            ?
            <span class="tooltiptext whitespace-pre-line" :style="DV_toolTipStyle">Please enter your website URL. Make sure it matches your reigstered business name for verification purposes.</span>
          </span>
        </span>
        <span class="col-start-2" :class="{'w-60' : DV_activeRenderMode == DV_renderModeTablet, 'w-80': DV_activeRenderMode != DV_renderModeTablet}">
          <input autocomplete="off" v-model="DV_storeLocationWebsite" @input="DV_storeLocationWebsiteError = ''" type="text">
          <span  v-if="DV_storeLocationWebsiteError !== ''" class=" col-3 text-sm text-red-400">{{ DV_storeLocationWebsiteError }} </span>
        </span>
        
        <div class="whitespace-nowrap text-left col-start-1">Business Phone Number: 
          <div class="tooltip">
            ?
            <div class="tooltiptext whitespace-pre-line" :style="DV_toolTipStyle">
              This will be used as the Synergy support contact phone number.
              </div>
          </div>              
        </div>

        <div class="col-start-2" :class="{'w-60' : DV_activeRenderMode == DV_renderModeTablet, 'w-80': DV_activeRenderMode != DV_renderModeTablet}">
          <input autocomplete="off" v-model="DV_storeLocationPhoneNumber" @input="DV_storeLocationPhoneNumberError = ''" type="numeric" inputmode="numeric">
          <span v-if="DV_storeLocationPhoneNumberError !== ''" class="text-sm text-red-400">{{ DV_storeLocationPhoneNumberError }} </span>
        </div>  

        <span class="whitespace-nowrap col-start-1 text-left"> Business Street Address: 
          <div class="tooltip">
            ?
            <div class="tooltiptext whitespace-pre-line" :style="DV_toolTipStyle">
              Enter the address matching your business name. This will be verified when you initiate the business texting setup.
            </div>
          </div> 
        </span>

        <div class="col-start-2" :class="{'w-60' : DV_activeRenderMode == DV_renderModeTablet, 'w-80': DV_activeRenderMode != DV_renderModeTablet}">
          <input autocomplete="off" v-model="DV_storeLocationAddress" @input="DV_storeLocationAddressError = ''" type="text">
          <span v-if="DV_storeLocationAddressError !== ''" class="text-sm text-red-400">{{ DV_storeLocationAddressError }} </span>
        </div>

        <span class="whitespace-nowrap col-start-1 text-left">
          Business Type:
        </span>

        <div class="col-start-2" :class="{'w-60' : DV_activeRenderMode == DV_renderModeTablet, 'w-80': DV_activeRenderMode != DV_renderModeTablet}">
          <select v-model="DV_selectedBusinessType">
            <option v-for="[key, value] in Object.entries(DV_businessTypes)" :value="key">
              {{ value }}
            </option>
          </select>
        </div>

        <span class="col-start-1  text-left"> City:</span>
        <div class="col-start-2" :class="{'w-60' : DV_activeRenderMode == DV_renderModeTablet, 'w-80': DV_activeRenderMode != DV_renderModeTablet}">
          <input autocomplete="off" v-model="DV_storeLocationCity" @input="DV_storeLocationCityError = ''" type="text">
          <span v-if="DV_storeLocationCityError !== ''" class="text-sm text-red-400">{{ DV_storeLocationCityError }} </span>
        </div>

        <span class="col-start-1 text-left"> State:</span>
        <div class="col-start-2" :class="{'w-60' : DV_activeRenderMode == DV_renderModeTablet, 'w-80': DV_activeRenderMode != DV_renderModeTablet}">
          <select v-model="DV_storeLocationState">
            <option v-for="state in DV_stateList"
              style="text-align: left;"
              :value="state">{{ state }}
            </option>
          </select>
          <span v-if="DV_storeLocationStateError !== ''" class="text-sm text-red-400">{{ DV_storeLocationStateError }} </span>
        </div>

        <span class="col-start-1 text-left"> Zip Code:</span>
        <div class="col-start-2" :class="{'w-60' : DV_activeRenderMode == DV_renderModeTablet, 'w-80': DV_activeRenderMode != DV_renderModeTablet}">
          <input autocomplete="off" v-model="DV_storeLocationPostalCode" @input="DV_storeLocationPostalCodeError = ''" type="number" inputmode="numeric">          
          <span v-if="DV_storeLocationPostalCodeError !== ''" class="text-sm text-red-400">{{ DV_storeLocationPostalCodeError }} </span>
        </div>        
      
        <span v-if="!P_loggedInFlag" class="col-start-1 text-left">First Name: </span>
        <div class="col-start-2" :class="{'w-60' : DV_activeRenderMode == DV_renderModeTablet, 'w-80': DV_activeRenderMode != DV_renderModeTablet}">
          <input autocomplete="off" v-model="DV_firstName" @input="DV_firstNameError = ''" type="text">
          <span v-if="DV_firstNameError !== ''" class="text-sm text-red-400">{{ DV_firstNameError }} </span>
        </div>

        <span v-if="!P_loggedInFlag" class="col-start-1 text-left">Last Name: </span>
        <div class="col-start-2" :class="{'w-60' : DV_activeRenderMode == DV_renderModeTablet, 'w-80': DV_activeRenderMode != DV_renderModeTablet}">
          <input autocomplete="off" v-model="DV_lastName" @input="DV_lastNameError = ''" type="text">
          <span v-if="DV_lastNameError !== ''" class="text-sm text-red-400">{{ DV_lastNameError }} </span>
        </div>

        <span v-if="!P_loggedInFlag" class="col-start-1 text-left">Login Email: </span>
        <div v-if="!P_loggedInFlag" class="col-start-2" :class="{'w-60' : DV_activeRenderMode == DV_renderModeTablet, 'w-80': DV_activeRenderMode != DV_renderModeTablet}">
          <input autocomplete="off" v-model="DV_loginEmail" @input="DV_loginEmailError = ''" type="text">
          <span v-if="DV_loginEmailError !== ''" class="text-sm text-red-400">{{ DV_loginEmailError }} </span>
        </div>

        <span
          v-if="!P_loggedInFlag" 
          class="col-start-1 text-left"
          :class="{ 'text-red-700': DV_passwordRulesFulfilled === false }"
        >
          Login Password: 
        </span>

        <div v-if="!P_loggedInFlag" class="col-start-2" :class="{'w-60' : DV_activeRenderMode == DV_renderModeTablet, 'w-80': DV_activeRenderMode != DV_renderModeTablet}">
          <input autocomplete="off" v-model="DV_password" @blur="checkPassword" id="password" type="password" name="password" class="shadow appearance-none border rounded text-gray-700 leading-tight focus:outline-none focus:shadow-outline enabled:hover:border-gray-400 required:border-red-500 disabled:opacity-75">
        </div>

        <span v-if="DV_activeRenderMode > DV_renderModePhone"
         class="col-start-1 text-left text-sm italic pt-2"
        >
          All Fields Are Required
        </span>
        
        <span 
          v-if="!P_loggedInFlag"
          class="col-start-2 pt-0 col-span-1 text-xs pt-2 text-left pb-2"
          :class="{ 'text-red-700 text-sm': DV_passwordRulesFulfilled === false,
          'w-80': DV_activeRenderMode > DV_renderModeTablet }"
          >
            For security reasons your password must be longer than 6 characters and contain: 1 uppercase letter and 1 special character -!"$%^@ 
        </span>
        
        <!-- only on phones is the "all Fields are required" text supposed to be under the password hint"-->
        <span 
          v-if="DV_activeRenderMode < DV_renderModeTablet"
          class="text-left text-sm italic pt-0 pb-2"
        >
          All Fields Are Required
        </span>
      </div>

      <div class=" mt-4 flex-row items-center mb-5">
        <button  @click.stop="createLocation()" class="btn btn-lime mr-3">Next</button>
      </div>

    </div>
  </div>

</template>

<script>
import dataValidator from "../../includes/bi1Datavalidation"
import {CR,RC} from "../../includes/bi2SuccessCodes"
import locationSpecific from "../../mixins/locationSpecific.js"
import m3RenderingSettings from "../../mixins/m3RenderingSettings"
import { reactive } from "vue";


  export default {
    name: "p3s4AccountSetupCreateLocation",

    props: ['P_formData','P_errorData','P_loggedInFlag'],

    mixins: [locationSpecific,m3RenderingSettings],

    data() {
      return {
        // information used to create firebase account
        DV_loginEmail: '',
        DV_loginEmailError: '',
        DV_password: '',
        DV_passwordError: '',
        DV_passwordRulesFulfilled: true,
        DV_firstName: '',
        DV_firstNameError: '',
        DV_lastName: '',
        DV_lastNameError: '',
        DV_firestoreAccountExits: false,

        // Business Info
        DV_businessName: "",
        DV_businessNameError: "",
        DV_selectedBusinessType: 1,
        DV_businessTypes: {
          1: "Full-Service",
          2: "Self-Service",
        },

        // Location Info
        DV_storeLocationPhoneNumber: "",
        DV_storeLocationPhoneNumberError: "",
        DV_storeLocationAddress: "",
        DV_storeLocationAddressError: "",
        DV_storeLocationCity: "",
        DV_storeLocationCityError: "",
        DV_storeLocationState: "",
        DV_storeLocationStateError: "",
        DV_storeLocationPostalCode: "",
        DV_storeLocationPostalCodeError: "",
        DV_storeLocationWebsite: "",
        DV_storeLocationWebsiteError: "",

        DV_toolTipStyle:{},     
    }
  },

    mounted() {
      if(this.DV_activeRenderMode < this.DV_renderModeTablet) {
        this.DV_toolTipStyle = reactive({
          width: '80vw',
          position: 'absolute',
          left:'-65vw'
        });
      }
      this.DV_businessName = this.P_formData.businessInfo.businessName;
      this.DV_storeLocationPhoneNumber = this.P_formData.locationInfo.phone;
      this.DV_storeLocationAddress = this.P_formData.locationInfo.address;
      this.DV_storeLocationCity = this.P_formData.locationInfo.city;
      this.DV_storeLocationState = this.P_formData.locationInfo.state;
      this.DV_storeLocationPostalCode = this.P_formData.locationInfo.postalCode
      this.DV_storeLocationPhoneNumber = this.P_formData.locationInfo.phone;
      this.DV_storeLocationWebsite = this.P_formData.locationInfo.website;
      this.DV_loginEmail = this.P_formData.accountInfo.email;
      this.DV_firstName = this.P_formData.accountInfo.firstName;
      this.DV_lastName = this.P_formData.accountInfo.lastName;

      setTimeout(function() {
        document.getElementById("p3s4").scrollIntoView();
      }, 100);

      this.DV_loginEmailError = this.P_errorData.accountInfo.emailError;
      this.DV_passwordError = this.P_errorData.accountInfo.passwordError;
    },

    methods: {
      checkPassword() {
        if ((this.DV_password <= 6) || (!/[A-Z]/.test(this.DV_password)) || (!/[-!"$%^@]/.test(this.DV_password))) {
          this.DV_passwordRulesFulfilled =  false;
          return;
        }
        this.DV_passwordRulesFulfilled = true;
        return;
      },

      createLocation() {
        const CI = this;

        let fail_flag = false;
        let call_result  = {};

        call_result = dataValidator.is_string(CI.DV_firstName, 3, 200);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_firstNameError = "First name is required.";
          fail_flag = true;
        }

        call_result = dataValidator.is_string(CI.DV_lastName, 3, 200);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_lastNameError = "Last name is required.";
          fail_flag = true;
        }

        call_result = dataValidator.is_string(CI.DV_businessName, 3, 100);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_businessNameError = "Business name is required.";
          fail_flag = true;
        }

        //add URL prefixes user may not add
        if (CI.DV_storeLocationWebsite && CI.DV_storeLocationWebsite.indexOf("www.") === 0 && CI.DV_storeLocationWebsite.indexOf("https://") === -1) {
          CI.DV_storeLocationWebsite = "https://" +  CI.DV_storeLocationWebsite;
        }

        //add URL prefixes user may not add
        if (CI.DV_storeLocationWebsite && CI.DV_storeLocationWebsite.indexOf("https://www.") === -1) {
          CI.DV_storeLocationWebsite = "https://www." +  CI.DV_storeLocationWebsite;
        }

        call_result = dataValidator.is_valid_url(CI.DV_storeLocationWebsite);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_storeLocationWebsiteError = "Business website is required";
          fail_flag = true;
        }

        call_result = dataValidator.is_string(CI.DV_storeLocationPhoneNumber, 9, 10);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_storeLocationPhoneNumberError = "Business Phone number must 10 digits";
          fail_flag = true;
        }

        call_result = dataValidator.is_string(CI.DV_storeLocationAddress, 3, 100);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_storeLocationAddressError = "Business address is required.";
          fail_flag = true;
        }

        call_result = dataValidator.is_string(CI.DV_storeLocationCity, 3, 100);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_storeLocationCityError = "Business city is required.";
          fail_flag = true;
        }

        call_result = dataValidator.is_string(CI.DV_storeLocationState, 3, 100);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_storeLocationStateError = "Business state is required.";
          fail_flag = true;
        }

        call_result = dataValidator.is_string(CI.DV_storeLocationPostalCode, 4, 5);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_storeLocationPostalCodeError = "Business zip code is required.";
          fail_flag = true;
        }

        call_result = dataValidator.is_email_address(CI.DV_loginEmail);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_loginEmailError = "Valid Email address is required.";
          fail_flag = true;
        }

        if (this.P_loggedInFlag === false) {
          CI.checkPassword()

          if (!CI.DV_password.trim() || !CI.DV_passwordRulesFulfilled) {
            fail_flag = true;
          }
        }

        call_result = dataValidator.is_us_phone_number(CI.DV_storeLocationPhoneNumber);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_storeLocationPhoneNumberError = "Valid US phone number is required.";
          fail_flag = true;
        } else {
          call_result = dataValidator.is_not_a_toll_free_number(CI.DV_storeLocationPhoneNumber);
          if (call_result[CR.success] !== RC.success) {
            CI.DV_storeLocationPhoneNumberError = "Please use a local area code for your business phone number. Toll-free numbers are not allowed.";
            fail_flag = true;
          }
        }

        this.$emit("event-update-step-form-data", {
          key: "accountInfo",
          value: {
            firstName: CI.DV_firstName,
            lastName: CI.DV_lastName,
            email: CI.DV_loginEmail,
            password: CI.DV_password,
          }
        });

        this.$emit("event-update-step-form-data", {
          key: "locationInfo",
          value: {
            name: CI.DV_businessName,
            phone: CI.DV_storeLocationPhoneNumber,
            email: CI.DV_loginEmail,
            areaCode: CI.DV_storeLocationPhoneNumber ? CI.DV_storeLocationPhoneNumber.substring(0,3) : "",
            address: CI.DV_storeLocationAddress,
            city: CI.DV_storeLocationCity,
            state: CI.DV_storeLocationState,
            postalCode: CI.DV_storeLocationPostalCode,
            website: CI.DV_storeLocationWebsite,
            locationUid: CI.P_formData.locationInfo.locationUid || null
          }
        });

        this.$emit("event-update-step-form-data", {
          key: "businessInfo",
          value: {
            ownerFirstName: CI.DV_firstName,
            ownerLastName: CI.DV_lastName,
            ownerName: CI.DV_firstName + " " + CI.DV_lastName,
            phoneNumber: CI.DV_storeLocationPhoneNumber,
            businessName: CI.DV_businessName,
            businessType: CI.DV_selectedBusinessType,
            businessPhoneNumber: CI.DV_storeLocationPhoneNumber,
            businessUid: CI.P_formData.businessInfo.businessUid || null
          }
        });

        if (fail_flag === true) { return; }
        this.$emit("next");
      },

      alignTextInCenter(e) {
      	this.DV_storeLocationStateError = '';

        // calculating the width of the selected option to set the center the text on iphones
        let element = e.target;
        let computedStyle = window.getComputedStyle(element);
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        context.font = computedStyle.font;
        
        // get available width inside the select element
        const paddingLeft = parseFloat(computedStyle.paddingLeft);
        const paddingRight = parseFloat(computedStyle.paddingRight);
        const availableWidth = element.clientWidth - (paddingLeft + paddingRight);

        const optionWidth = context.measureText(this.DV_storeLocationState).width;
        let indentationWidth = (availableWidth - optionWidth)/2;
        element.setAttribute("style", `text-indent:${indentationWidth}px;`);
      }
    },
  }
</script>