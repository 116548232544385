<template>
  <div id="c1c1" :class="[DV_activeRenderMode > DV_renderModeDesktopNarrow ? 'h-[85%]' : 'h-[65%]']">
    <!-- Desktop view -->
    <div class="md:block hidden h-full">
      <div class="border border-gray-400 rounded-b-xl p-2 h-full relative">
        <div class="flex flex-row items-center justify-start">
          
          <!-- Search for chat -->
          <SearchBar v-if="DV_showUnfinishedFeatures" containerClasses="mt-1 mr-3" inputClasses="h-9" />

           <!-- New tab full screen button -->
           <div class="ml-auto">
            <svg v-show="DV_showFullScreenButton && !P_readOnly" @click="openFullScreen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
              <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"/>
            </svg>
          </div>
        </div>

        <div class="mt-3" :class="C_chatContainerHeight">
          <div id="chat-message-container" 
          class="overflow-y-auto border border-gray-400 rounded-xl p-1 hide-scrollbar"
          :class="{'h-full': isModal, 'mxh-75': P_readOnly, 'h-[90%]' : !isModal, '!h-[85%]': DV_activeRenderMode == DV_renderModeDesktopNarrow}"
          >
            <div v-if="DV_loading" class="mx-auto mt-4">Loading...</div>
            
            <!-- Chat messages list -->
            <div v-else class="w-full px-2 flex flex-col justify-between overflow selectable-text">
              <div 
              v-for="message in DV_activeConversationMessages" 
              :class="{'not-sent-chat-message': message[DV_ccmIndexes.timestamp].nanoseconds == 'not-sent'}" 
              class="flex flex-col mt-2">

                <div :class="[message[DV_ccmIndexes.message_sender] == 1 ? 'flex-row-reverse' : '']" class="flex justify-end mb-2">
                  <div 
                  :class="{'left': message[DV_ccmIndexes.message_sender] === 1,
                  'right': message[DV_ccmIndexes.message_sender] === 2,
                  'center w-full': message[DV_ccmIndexes.message_sender] > 2,
                    }"
                  class="flex flex-col chat-message"
                >

                  <span class="font-bold" v-if="message[DV_ccmIndexes.message_sender] === 3 || message[DV_ccmIndexes.message_sender] === 4 || message[DV_ccmIndexes.message_sender] === 5">
                    Synergy Sent this Text Message to {{ DV_consumerName }} on {{moment.unix(message[DV_ccmIndexes.timestamp].seconds).format('MM-DD-YYYY')}} at {{moment.unix(message[DV_ccmIndexes.timestamp].seconds).format('h:mm A')}}                
                  </span>

                  <!-- show employee above messages from employees-->
                  <span v-else-if="message[DV_ccmIndexes.message_sender] == 2 &&
                    message[DV_ccmIndexes.employee_uid] !== null"
                  >
                    {{ getEmployeeName(message[DV_ccmIndexes.employee_uid])}} |
                    {{moment.unix(message[DV_ccmIndexes.timestamp].seconds).format('MM-DD-YYYY h:mm A')}}<br>
                  </span>
                  <!-- </end> show above messages from employees-->
                  
                  <!-- show consumer name above message-->
                  <span v-else-if="message[DV_ccmIndexes.message_sender] == 1">
                    {{ DV_consumerName}} | {{moment.unix(message[DV_ccmIndexes.timestamp].seconds).format('MM-DD-YYYY h:mm A')}}<br> 
                  </span>
                  <!-- </end> show consumer name above message-->

                  <span :class="[message[DV_ccmIndexes.message_sender] > 2 ? '' : 'self-start text-left']" v-if="(message[DV_ccmIndexes.message_type] == 1)" v-html="message[DV_ccmIndexes.message_content]">
                  </span>
                  
                  <div
                  v-else-if="(message[DV_ccmIndexes.file_type] == 1)" 
                   class="flex flex-col">
                    <img                   
                    style="max-height: 50vh;"
                    :src="message[DV_ccmIndexes.file_url]"
                    >
                    <a 
                    class="text-blue-700 cursor-pointer"
                    @click="downloadMedia(message[DV_ccmIndexes.file_url])"
                    >
                    <b>Download Image</b></a>
                </div>
                  <span 
                  class="text-lg text-left"
                  v-else-if="(message[DV_ccmIndexes.file_type] > 1)">
                  Uploaded File:
                  <a 
                  @click="downloadMedia(message[DV_ccmIndexes.file_url])"
                  class="text-blue-700 break-words cursor-pointer"> {{ message[DV_ccmIndexes.file_name] }} <span v-if="getUploadedFileSize(message)">({{getUploadedFileSize(message)}})</span>
                  
                </a></span>

                  <span v-else class="deleted-message">
                    Deleted message
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Send message section -->
          <div class="flex" v-if="!P_readOnly && DV_activeRecyclerLocation.conversation_starters_enabled == true">
            <button class="mt-3 w-9 h-5 flex items-center justify-center absolute left-1 !p-0" @click="scrollLeft">&#10096;</button>
            <div class="scroll-container mt-2 ml-8 h-12 p-1 overflow-x-hidden overflow-y-hidden whitespace-pre" style="width: 92%" @wheel.prevent="handleConversationStarterWheel">
              <span v-for="[key, value] in Object.entries(DV_conversationStarters)" v-if="value.subject" class="border border-gray-400 rounded-xl mr-2 cursor-pointer" @click="processSendConversationStarter(value.body)">
                <span class="ml-2 mr-2">
                  {{ value.subject }}
                </span>
              </span>
            </div>
            <button class="mt-3 w-9 h-5 flex items-center justify-center absolute right-1 !p-0" @click="scrollRight">&#10097;</button>
          </div>
          <div v-if="!P_readOnly" class="flex mt-1 items-center absolute bottom-0 w-[98%]">
            <!-- Upload file -->
            <button :disabled="DV_uploadingImage" @click.stop="showFileUpload" class="mr-2 -mt-1.5">
              <img class="w-10" :src="require('../../assets/images/upload_file_icon.png')" />
            </button>

            <!-- Message input -->
            <div class="w-full" @dragover.stop.prevent="onDragEnter" @dragleave.stop.prevent="onDragLeave" @drop="handleMessageImageUpload">
              <div v-show="C_isCustomerTyping" style="top: -20px;" class="absolute text-left text-sm">{{C_typingNotificationText}}</div>
              
              <div class="relative bottom-1">
                <textarea 
                  ref="messageTextInput" 
                  :disabled="DV_sendingChatMessage" 
                  :class="{'drop-container': DV_showDropzone}" 
                  v-model="C_computedMessage" 
                  v-on:keydown.enter="sendChatMessage" 
                  id="c7_new_message_text" 
                  rows="1" 
                  class="w-full pr-12 hide-scrollbar h-[8vh]"
                  maxlength="320"
                ></textarea>
                <div class="absolute bottom-1 right-1 text-sm text-gray-500">
                  {{ C_characterCount }}/320
                </div>
              </div>

              <span v-show="DV_showDropzone" class="pointer-events-none absolute left-0 right-0 top-3 text-xl font-bold">Drop File Here</span>
              <input id="image-upload-input" ref="messageImageInput" class="hidden" type="file" @change="handleMessageImageUpload" />
            </div>

            <!-- Message Button -->
            <button :disabled="DV_uploadingImage" @click.stop="sendChatMessage" class="btn-lime mb-1 mt-1 w-9 h-9 flex items-center justify-center absolute right-1 top-0 rounded-full !p-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
                </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile view -->
    <div class="md:hidden block">
      <div id="chat-message-container" class="overflow-scroll border border-gray-400 rounded-xl p-3 hide-scrollbar" :class="[isModal ? 'h-52' : 'h-[28rem]']">
              
        <!-- Chat messages list -->
        <div class="w-full px-5 flex flex-col justify-between">
          <div v-for="message in DV_activeConversationMessages" class="flex flex-col mt-5 text-sm selectable-text">
            <div :class="[message[DV_ccmIndexes.message_sender] == 1 ? 'flex-row-reverse' : '']" class="flex justify-end mb-4">
              <div :class="[message[DV_ccmIndexes.message_sender] == 1 ? 'left' : 'right']" class="chat-message">
                {{message[DV_ccmIndexes.message_content]}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Send message section -->
      <div v-if="!P_readOnly" class="grid grid-cols-6 gap-2 items-center mt-2">
        <textarea v-on:keydown.enter="sendChatMessage" id="message" rows="1"></textarea>
        <div class="col-span-2 flex flex-row items-center justify-end">
          <button @click.stop="sendChatMessage" class="btn btn-lime">Send</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import SearchBar from '../shared/SearchBar.vue';
import moment from 'moment';
import generalFunctions from '../../mixins/generalFunctions.js'
import sendMessageToConsumer from "../../includes/server_calls/p1s5/p1s5e2"
import { fs103Writer } from "../../includes/firestore_writers/fs103_writer.ts";
import m3RenderingSettings from '../../mixins/m3RenderingSettings.js';
import { getDocFrom_fs103ConversationStatuses } from "../../includes/firestore_schemas/fs103ConversationStatuses"
import alertInactiveConsumer from "../../includes/server_calls/p1s3/p1s3e48";
import { fs125Writer } from '../../includes/firestore_writers/fs125_writer.ts'
import { fs112Writer } from '../../includes/firestore_writers/fs112_writer.ts'

export default {
  name: "c7CustomerChat",
  
  props: ["isModal", "P_readOnly", "P_consumerUid", "P_conversationUid", "P_isChatModuleParent"],
  components: { SearchBar },
  mixins: [ generalFunctions, m3RenderingSettings ],
  
  data() {
    return {
      DV_employeesData : window.G_bi2_data.IV_recycler_employees.value,
      DV_ccmIndexes: window.G_bi2_data.IV_al_consumer_conversation_messages,
      DV_employeesConversationsList: {},
      DV_activeConversationsList: {},
      DV_allConversationsList: {},
      DV_conversationsRef: null,
      DV_activeConversationMessages: [],
      DV_chatMessageText: "",
      DV_uploadingImage: false,
      DV_showDropzone: false,
      DV_consumerName: "",
      DV_consumerConversationUuid: "",
      DV_userEmployeeUid: window.G_bi2_data.IV_active_employee_info.employee_uid,
      moment: moment,
      DV_showUnfinishedFeatures: window.G_show_unfinished_features,
      DV_sendingChatMessage: false,
      DV_showFullScreenButton: true,
      DV_loading: false,
      DV_currentTypingStatus: "not-typing",
      DV_lastTypingTime: new Date(0),
      DV_typingDebounceTimeInMillis: 1000,
      DV_consumerIndex: window.G_bi2_data.IV_al_consumer_contacts,
      DV_allNotificationsRef: {},
      DV_unreadMessagesCount: {},
      DV_unreadMessagesCountRef: {},
      DV_conversationStarters: {},
      DV_activeRecyclerLocation: {},
      DV_paymentLinksRaw: {},
      DV_conversationUid: "",
    };
  },
  
  mounted() {
    const CI = this;
    
    this.DV_allNotificationsRef = window.G_bi2_data.IV_user_notifications.value;
    this.DV_unreadMessagesCountRef = window.G_bi2_data.IV_conversations_unread_messages_count.value;
    this.DV_paymentLinksRaw = window.G_bi2_data.IV_consumer_conversation_payment_links;
    
    this.updateLoadedConversation(0, this);
    this.filterActiveConversations();
    setInterval(CI.lazyUpdateTypingStatus, 500);
    this.startConversationStartersListener();

    const recyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;

    // Active business data
    if (recyclerLocationUid in window.G_bi2_data.IV_recyclers.value == false) { 
      if (retry_count >= 20) {
        CI.DV_recyclerLocationNotFound = true;
        CI.DV_loadingPaymentApiKeysInformation = false;
        return;
      }
      setTimeout(function() {
        CI.startPaymentApiKeysListener(++retry_count)
      }, 500);
      return; 
    }

    CI.DV_activeRecyclerLocation = window.G_bi2_data.IV_recyclers.value[recyclerLocationUid];
  },
  
  methods: {
    handleConversationStarterWheel(event) {
      const container = event.currentTarget;
      container.scrollLeft += event.deltaY;
    },

    processSendConversationStarter(body) {
      const CI = this;

      CI.DV_chatMessageText = body;
      CI.$refs.messageTextInput.focus();
    },

    scrollLeft() {
      const container = this.$el.querySelector('.scroll-container');
      container.scrollLeft -= 200; // Scroll by 200 pixels
    },

    scrollRight() {
      const container = this.$el.querySelector('.scroll-container');
      container.scrollLeft += 200; // Scroll by 200 pixels
    },

    startConversationStartersListener(retry_count=0) {
      const CI = this;
      
      const recyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;

      if (recyclerLocationUid in window.G_bi2_data.IV_recyclers.value == false) {
        if (retry_count >= 20) { return; }

        setTimeout(function() {
          CI.startConversationStartersListener(++retry_count)
        }, 500);
        return;
      }
     
      window.G_bi2_data.startRecyclerConversationStartersListener(
        window.G_bi2_data,
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
      );
    
      CI.DV_conversationStarters = window.G_bi2_data.IV_recycler_conversation_starters.value;
    },

    getRelatedConversationNotification(conversation_uid) {
      const CI = this;

      let formatted_conv_uid = `${conversation_uid}_1`;

      // See if we get related notification to this conversation.
      if (formatted_conv_uid in CI.DV_allNotificationsRef) {
        return CI.DV_allNotificationsRef[formatted_conv_uid];
      }

      return null;
    },

    markConversationNotificationsAsRead(conv_uid, notification=null) {
      const CI = this;

      if (conv_uid === null) { return; }

      let [consumer_uid, conversation_uid] = conv_uid.split("-");

      if (!notification) { notification = CI.getRelatedConversationNotification(); }

      // Write to fs125 using fs125_writer
      fs125Writer(
        "n",
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        window.G_bi2_data.IV_active_employee_info.employee_uid,
        conv_uid,
        {
          consumer_contact_uid: consumer_uid,
          conversation_uid: conversation_uid,
          read_message_time: new Date(),
          read_notification_time: (notification ? notification.timestamp : new Date())
        }
      )

      // Mark the force unread to false
      if (notification) {
        fs112Writer(
          "e", 
          window.G_bi2_data.IV_active_employee_info.business_uid, 
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid, 
          notification.uid, 
          {force_mark_unread: false}
        );
      }

      // Mark any payment notification force unread to false
      let conversation_payment_link_uids = [];

      if (conv_uid in window.G_bi2_data.IV_consumer_conversation_payment_links.value === true) {
        for (let payment_link_uid in window.G_bi2_data.IV_consumer_conversation_payment_links.value[conv_uid]) {
          conversation_payment_link_uids.push(payment_link_uid);
        }
      }

      // See if any notification related to this payment is available and marked as force unread
      for (let payment_link_uid of conversation_payment_link_uids) {
        let payment_notification = _.find(CI.DV_notificationsList, (notif) => {
          return notif.payment_link_uid === payment_link_uid;
        });

        if (payment_notification && payment_notification.force_mark_unread === true) {
          fs112Writer(
            "e", 
            window.G_bi2_data.IV_active_employee_info.business_uid, 
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid, 
            payment_notification.uid, 
            {force_mark_unread: false}
          );
        }
      }
    },

    filterActiveConversations() {
      const CI = this;

      this.DV_activeConversationsList = _.filter(window.G_bi2_data.IV_consumer_conversations.value, (conv, uid) => {
        if (uid in window.G_bi2_data.IV_employees_active_conversations.value === false) { return false; }

        if (CI.P_searchText) {
          if (conv.consumer_name && conv.consumer_name.toLowerCase().includes(CI.P_searchText)) { return true; }
          if (conv.phone_number && conv.phone_number.toLowerCase().includes(CI.P_searchText)) { return true; }
          if (conv.business_name && conv.business_name.toLowerCase().includes(CI.P_searchText)) { return true; }
        } else {
          return true;
        }

        return false;        
      });
    },

    updateLoadedConversation(retry_count = 0, class_instance) {
      const CI = class_instance;
      CI.DV_loading = true;

      if(!CI.C_consumerUid || !CI.C_conversationUid ) { return;}
      CI.DV_consumerConversationUuid = CI.C_consumerUid + "-" + CI.C_conversationUid;
      
      // wait for loading account info
      if(window.G_bi2_data.IV_active_employee_info.business_uid == "" ||
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid == ""
      ) {
        setTimeout(function() {CI.updateLoadedConversation(retry_count, class_instance)}, 200);
        return;
      }

      if (retry_count == 0) {
        window.G_bi2_data.startConsumerConversationMessagesListener(
          window.G_bi2_data,
          window.G_bi2_data.IV_active_employee_info.business_uid,
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          CI.C_consumerUid,
          CI.C_conversationUid
        );
      }

      if (CI.C_consumerUid in window.G_bi2_data.IV_consumer_contacts.value == false) {
        setTimeout(function() {CI.updateLoadedConversation(++retry_count, class_instance)}, 200);
        return;
      }

      let consumer_data = window.G_bi2_data.IV_consumer_contacts.value[CI.C_consumerUid]
      CI.DV_consumerName = consumer_data[window.G_bi2_data.IV_al_consumer_contacts.first_name] + " " + consumer_data[window.G_bi2_data.IV_al_consumer_contacts.last_name];
      
      CI.scrollChatToBottom()
      CI.DV_loading = false
      CI.DV_conversationsRef = window.G_bi2_data.IV_consumer_conversation_messages.value[CI.DV_consumerConversationUuid];
      CI.updateMessagesList();
    },

    getEmployeeName(employee_uid) {
      if(employee_uid in this.DV_employeesData === false )  {
        return "";
      }

    return this.DV_employeesData[employee_uid].first_name;
    },

    scrollChatToBottom() {
      const objDiv = document.getElementById("chat-message-container");
      if (!objDiv) {return;}
      objDiv.scrollTop = objDiv.scrollHeight;
    },

    updateMessagesList() {
      const CI = this;

      if (!CI.DV_consumerConversationUuid) {
        CI.DV_activeConversationMessages = []
        return;
      }
      if (CI.DV_consumerConversationUuid in window.G_bi2_data.IV_consumer_conversation_messages.value === false) {
        CI.DV_activeConversationMessages = []
        return;
      }

      CI.DV_activeConversationMessages = window.G_bi2_data.IV_consumer_conversation_messages.value[CI.DV_consumerConversationUuid];
      CI.DV_activeConversationMessages = _.sortBy(CI.DV_activeConversationMessages, (item) => item[4].seconds);
      CI.$forceUpdate()
      setTimeout(CI.scrollChatToBottom, 1000);
    },

    async sendChatMessage(e) {
      const CI = this;

      if (e.keyCode == 13) { e.preventDefault(); }

      if (!CI.DV_chatMessageText) { return; }
      CI.DV_sendingChatMessage = true;

      var unSendMessageUid = new Date().getTime().toString(); // fake uid of message

      // Show the message immidiately in unsent form
      let unSendMessage = [
        2, // employe send message
        1, // text message
        unSendMessageUid, // fake uid of message
        window.G_bi2_data.IV_active_employee_info.employee_uid, // self employ uid,
        {
          nanoseconds: "not-sent", // fake nanoseconds value to show un-sent status in chat
          seconds: Math.round(new Date().getTime()/1000)
        },
        CI.DV_chatMessageText
      ]

      CI.DV_activeConversationMessages.push(unSendMessage);
      CI.DV_activeConversationMessages = _.sortBy(CI.DV_activeConversationMessages, (item) => item[4].seconds);
      CI.$forceUpdate()
      setTimeout(CI.scrollChatToBottom, 50)
      // Show the message immediately in unsent form

      CI.markConversationNotificationsAsRead(CI.C_consumerUid + "-" + CI.C_conversationUid);
      
      let params = {
        recycler_uid: window.G_bi2_data.IV_active_employee_info.business_uid,
        recycler_location_uid: window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        employee_uid: window.G_bi2_data.IV_active_employee_info.employee_uid,
        consumer_uid: CI.C_consumerUid,
        conversation_uid: CI.C_conversationUid,
        message_type: 1,
        content: CI.DV_chatMessageText,
        file_name: ""
      }

      sendMessageToConsumer(params).then((resp) => {
        setTimeout(CI.scrollChatToBottom, 50)
      }).catch((error) => {
        // Remove the unsent message from the list. 
        let index = this.DV_activeConversationMessages.findIndex(item => item[2] == unSendMessageUid)
        if (index > 0) {this.DV_activeConversationMessages.splice(index, 1)}
        
        this.$awn.alert(error.error_title)
      });
      this.DV_chatMessageText = "";
      this.DV_sendingChatMessage = false;

      // if the consumer is inactive in the conversation. send them a text message
      let result = await getDocFrom_fs103ConversationStatuses(
          window.G_bi2_data.IV_active_employee_info.business_uid,
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          window.G_bi2_data.IV_active_employee_info.employee_uid,
          CI.C_consumerUid,
          CI.C_conversationUid,
      );

      const consumerObj = CI.DV_activeConversationsList.find(key => key['consumer_uid'] === CI.C_consumerUid)
      let consumer_status = consumerObj['activity_status']
      let consumer_data = window.G_bi2_data.IV_consumer_contacts.value[CI.C_consumerUid]

      if (consumer_data[CI.DV_consumerIndex.sms_only_messaging] == false && (consumer_status === "offline" || consumer_status === "inactive")) {
        alertInactiveConsumer(
            window.G_bi2_data.IV_active_employee_info.business_uid,
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            CI.C_consumerUid,
            CI.C_conversationUid,
        );
      };
    },

    showFileUpload() {
      this.$refs.messageImageInput.click();
    },

    onDragEnter() {
      this.DV_chatMessageText = "";
      this.DV_showDropzone = true;
    },

    onDragLeave() {
      this.DV_chatMessageText = "";
      this.DV_showDropzone = false;
    },

    handleMessageImageUpload(e) {
      const CI = this;
      CI.DV_showDropzone = false;
      CI.DV_chatMessageText = "";

      e.stopPropagation();
      e.preventDefault();

      var files = []

      if (e.target.tagName == 'TEXTAREA') {
        files = e.dataTransfer.files;
      } else if (e.target.tagName == 'INPUT') {
        files = e.target.files;
      }

      if (!files || !files[0]) { return; } 

      if (files.length > 1) {
        this.$awn.alert("Please choose only one file per message.");
        return;
      }

      // validate size
      var file = files[0];
      if (file.size > (30*(1024*1024))) {
        this.$awn.alert("File size must be less than 30 megabytes.");
        return;
      }

      CI.DV_uploadingImage = true;

      // set data
      var reader = new FileReader();
      reader.onload = (obj) => {
        var unSendMessageUid = new Date().getTime().toString(); // fake uid of message

        // Show the message immidiately in unsent form
        let unSendMessage = [
          2, // employe send message
          1, // text message
          unSendMessageUid, // fake uid of message
          window.G_bi2_data.IV_active_employee_info.employee_uid, // self employ uid,
          {
            nanoseconds: "not-sent", // fake nanoseconds value to show un-sent status in chat
            seconds: Math.round(new Date().getTime()/1000)
          },
          `Uploading ${CI.getUploadingFileType(file)}`
        ]

        CI.DV_activeConversationMessages.push(unSendMessage);
        CI.DV_activeConversationMessages = _.sortBy(CI.DV_activeConversationMessages, (item) => item[4].seconds);
        CI.$forceUpdate()
        setTimeout(CI.scrollChatToBottom, 50)
        // Show the message immediately in unsent form

        let params = {
          recycler_uid: window.G_bi2_data.IV_active_employee_info.business_uid,
          recycler_location_uid: window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          employee_uid: window.G_bi2_data.IV_active_employee_info.employee_uid,
          consumer_uid: CI.C_consumerUid,
          conversation_uid: CI.C_conversationUid,
          message_type: (CI.getUploadingFileType(file) === "Image" ? 2 : 3),
          content: obj.srcElement.result,
          file_name: `${CI.C_conversationUid}-${new Date().getTime()}-${file.name}`
        }

        sendMessageToConsumer(params).then((resp) => {
          CI.DV_uploadingImage = false;
          setTimeout(CI.scrollChatToBottom, 50)
        }).catch((error) => {
          // Remove the unsent message from the list. 
          let index = CI.DV_activeConversationMessages.findIndex(item => item[2] == unSendMessageUid)
          if (index > 0) {CI.DV_activeConversationMessages.splice(index, 1)}
          CI.DV_uploadingImage = false;          
          CI.$awn.alert(error.error_title)
        });
      }

      reader.readAsDataURL(file)
    },

    getUploadingFileType(file) {
      const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const acceptedImageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
      const acceptedDocExtensions = ['.doc', '.docx', '.pdf'];
      const acceptedDocTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-word.document.macroEnabled.12', 'application/pdf'];
      const acceptedVideoTypes = [ 'video/mp4', 'video/mpeg', 'video/quicktime', 'video/x-msvideo', 'video/x-flv', 'video/x-matroska', 'video/webm', 'video/x-ms-wmv'];
      const acceptedVideoExtensions = ['.mp4', '.mpeg', '.mov', '.avi', '.flv', '.mkv', '.webm', '.wmv'];

      const fileType = file.type.toLowerCase();;
      const fileName = file.name;
      const fileExtension = fileName.substr(fileName.lastIndexOf('.')).toLowerCase();

      if (acceptedImageTypes.includes(fileType) || acceptedImageExtensions.includes(fileExtension)) {
        return "Image";
      }

      if (acceptedDocTypes.includes(fileType) || acceptedDocExtensions.includes(fileExtension)) {
        return "File";
      }

      if (acceptedVideoTypes.includes(fileType) || acceptedVideoExtensions.includes(fileExtension)) {
        return "Video";
      }

      return "File";
    },

    openFullScreen() {
      let routeData = this.$router.resolve({ path: `/customerChat?conversationUId=${this.C_conversationUid}&consumerUid=${this.C_consumerUid}`});
      window.open(routeData.href, "_blank");
    },

    lazyUpdateTypingStatus() {
      const CI = this;
      var textarea = CI.$refs.messageTextInput;
      let typingStatus = "not-typing";

      if (!textarea) { return; }

      if (document.activeElement != textarea || textarea.value == '' || (new Date().getTime() - CI.DV_lastTypingTime.getTime()) > CI.DV_typingDebounceTimeInMillis) {
        typingStatus = "not-typing";
      } else {
        typingStatus = "typing";
      }

      if (CI.DV_currentTypingStatus == typingStatus) { return; }
      CI.DV_currentTypingStatus = typingStatus;

      fs103Writer(
        "e",
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        window.G_bi2_data.IV_active_employee_info.employee_uid,
        CI.C_consumerUid,
        CI.C_conversationUid,
        {
          employee_typing_status: typingStatus
        }
      );
    },

    getUploadedFileSize(message) {
      const CI = this;

      let file_size_in_bytes = message[CI.DV_ccmIndexes.file_size]

      if (!file_size_in_bytes) { return null; }

      if (file_size_in_bytes < 1024) {
        return `${file_size_in_bytes} bytes`
      }

      let file_size_in_kbs = (file_size_in_bytes / 1024).toFixed(2);
      if (file_size_in_kbs < 1024) {
        return `${file_size_in_kbs} KB`
      }

      let file_size_in_mbs = (file_size_in_kbs / 1024).toFixed(2);
      return `${file_size_in_mbs} MB`
    },

    updateConversationStarters() {
        const CI = this;

        CI.DV_conversationStarters = window.G_bi2_data.IV_recycler_conversation_starters.value;

        let consumerConversationUid = CI.C_consumerUid + "-" + CI.C_conversationUid;
        if (CI.DV_paymentLinksRaw[consumerConversationUid] && Object.keys(CI.DV_paymentLinksRaw[consumerConversationUid]).length === 0) {
            let newConversationStarters = {};

            for (let entry in CI.DV_conversationStarters) {
                let conversationStarter = CI.DV_conversationStarters[entry];
                if (!conversationStarter.body.includes("[Payment Link]")) {
                    newConversationStarters[entry] = conversationStarter;
                }
            }

            CI.DV_conversationStarters = newConversationStarters;
        } 
    },
  },
  
  watch: {
 
    DV_conversationUid: {
      handler() {
        this.updateConversationStarters();
      },
      deep: true
    },

    DV_unreadMessagesCountRef: {
      handler() {
        const CI = this;
        CI.DV_unreadMessagesCount = window.G_bi2_data.IV_conversations_unread_messages_count.value;
      },
      deep: true
    },

    DV_employeesConversationsList: {
      handler() {
        this.filterActiveConversations();
      },
      deep: true
    },
    
    DV_allConversationsList: {
      handler() {
        this.filterActiveConversations();
      },
      deep: true
    },

    P_searchText() {
      this.filterActiveConversations();
    },
 
    DV_conversationsRef: {
      handler() {
        this.updateMessagesList()
      },
      deep: true
    },

    P_consumerUid() {
      this.updateLoadedConversation(0, this);
    },

    P_conversationUid() {
      this.updateLoadedConversation(0, this);
    },

    DV_chatMessageText() {
      this.DV_lastTypingTime = new Date();
    }
  },

  computed: {
    C_computedMessage: {
      get() {
        return this.DV_chatMessageText;
      },
      set(value) {
        this.DV_chatMessageText = value.length > 320 ? value.substring(0, 320) : value;
      }
    },
    
    C_characterCount() {
      return this.DV_chatMessageText.length;
    },
    
    C_showFullPage() {
      return this.$route.name == "customerChat"
    },

    C_conversationUid() {
      let conversationUId = this.P_conversationUid;

      if (this.C_showFullPage && this.$route.query["conversationUId"]) {
        conversationUId = this.$route.query["conversationUId"];
        this.DV_showFullScreenButton = false;
      }

      this.DV_conversationUid = conversationUId
      return conversationUId
    },

    C_consumerUid() {
      let consumerUid = this.P_consumerUid;

      if (this.C_showFullPage && this.$route.query["consumerUid"]) {
        consumerUid = this.$route.query["consumerUid"];
        this.DV_showFullScreenButton = false;
      }

      return consumerUid;
    },

    C_isCustomerTyping() {
      const CI = this;

      if (!CI.DV_consumerConversationUuid) { return false; }

      if (CI.DV_consumerConversationUuid in window.G_bi2_data.IV_consumer_conversations.value === false) {
        return false;
      }

      const consumerConversation = window.G_bi2_data.IV_consumer_conversations.value[CI.DV_consumerConversationUuid];

      if (consumerConversation.consumer_typing_status && consumerConversation.consumer_typing_status === "typing") {
        return true;
      }

      return false;
    },

    C_consumerName() {
      return this.DV_consumerName.trim() || "Customer";
    },

    C_typingNotificationText() {
      const CI = this;
      const bothTypingFlag = (CI.DV_currentTypingStatus == 'typing' && CI.C_isCustomerTyping);

      return `${bothTypingFlag ? 'You and ' : ''} ${CI.C_consumerName} ${bothTypingFlag ? 'are' : 'is'} typing...`;
    },

    C_chatContainerHeight() {
      if (this.C_showFullPage) {
        return 'h-[85vh]';
      }

      if (this.P_isChatModuleParent) {
        return 'h-[65vh]';
      }

      if (this.isModal) {
        return 'h-[45vh]';
      }

      return 'h-[85%]';
    }
  }
    
};
</script>

<style>
.drop-container {
  position: relative;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

#image-upload-input {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>