<template>
  <div>
    <!-- Loading -->
    <div v-if="DV_businessesLoading" class="mb-4">Loading...</div>
    
    <!-- Business not found -->
    <div v-if="!DV_businessesLoading && DV_businessNotFound" class="mb-4">There is an issue fetching the business details, please reload page or contact support.</div>

    <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
      <span class="w-full">Yard Contact Name:</span>
      <div class="w-full">
        <input autocomplete="off" v-model="DV_ownerName" @input="DV_ownerNameError = ''" type="text">
        <div class="text-left w-full">
          <span v-if="this.DV_ownerNameError !== ''" class="text-sm text-red-400">{{ this.DV_ownerNameError }}</span>
        </div>
      </div>
    </div>

    <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
        <span class="w-full">Yard Contact Number:</span>
      <div class="w-full">
        <input autocomplete="off" v-model="DV_phoneNumber" @input="DV_phoneNumberError = ''" type="number" size="10" maxlength="10">
        <div class="text-left w-full">
          <span v-if="this.DV_phoneNumberError !== ''" class="text-sm text-red-400">{{ this.DV_phoneNumberError }}</span>
        </div>
      </div>
    </div>

    <div class="mt-4" v-if="DV_businessesLoading == false && DV_locationsLoading == true">
      Loading Locations data...
    </div>

    <div v-for="(location, index) in DV_locations">
      <h4 v-if="index > 0" class="mt-4 text-left font-bold text-xl">Recycler Location #{{index+1}}</h4>
      
      <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
        <span class="w-1/2 flex items-center justify-center">Business Logo:</span>
        <label :data-id="index" class="drop-zone w-1/2" :class="{'drop-zone--over': DV_showDropzone}" @dragover.stop.prevent="DV_showDropzone = true" @dragleave.stop.prevent="DV_showDropzone = false" @drop="handleFileChange">
          <span v-show="!location.logo" class="drop-zone__prompt">Drop file here or click to upload</span>
          <input autocomplete="off" id="image-upload-input" class="drop-zone__input hidden" ref="logoImageInput" type="file" @change="handleFileChange" />
          <div v-show="location.logo" class="drop-zone__thumb" data-label="logo" v-bind:style="{'background-image': `url(${location.logo})`}"></div>
        </label>
      </div>

      <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
        <span class="w-full">Business Name:</span>
        <div class="w-full">
          <input autocomplete="off" v-model="location.businessName" @input="location.businessNameError = ''" type="text">
          <div class="text-left w-full">
            <span v-if="location.businessNameError !== ''" class="text-sm text-red-400">{{ location.businessNameError }}</span>
          </div>
        </div>
      </div>

      <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
        <span class="w-full">Business Type:</span>
        <div class="w-full">
          <input disabled autocomplete="off" v-model="DV_businessType" type="text">
        </div>
      </div>

      <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
        <span class="w-full">Business Phone Number:</span>
        <div class="w-full">
          <input autocomplete="off" v-model="location.businessPhoneNumber" @input="location.businessPhoneNumberError = ''" type="number" size="10" maxlength="10">
          <div class="text-left w-full">
            <span v-if="location.businessPhoneNumberError !== ''" class="text-sm text-red-400">{{ location.businessPhoneNumberError }}</span>
          </div>
        </div>
      </div>

      <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
        <span class="w-full">Business Texting Number:</span>
        <div class="w-full">
          <input disabled autocomplete="off" v-model="location.businessTextNumber" type="text" size="10">
        </div>
      </div>

      <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
        <span class="w-full">Business Email Address:</span>
        <div class="w-full">
          <input autocomplete="off" v-model="location.email" @input="location.emailError = ''" type="text">
          <div class="text-left w-full">
            <span v-if="location.emailError !== ''" class="text-sm text-red-400">{{ location.emailError }}</span>
          </div>
        </div>
      </div>

      <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
        <span class="w-full">Business Website URL:</span>
        <div class="w-full">
          <input autocomplete="off" v-model="location.websiteUrl" @input="location.websiteUrlError = ''" type="text">
          <div class="text-left w-full">
            <span v-if="location.websiteUrlError !== ''" class="text-sm text-red-400">{{ location.websiteUrlError }}</span>
          </div>
        </div>
      </div>

      <!-- Store Address -->

      <h1 class="mt-8 mb-2 text-left text-xl font-bold">Store Location</h1>
      <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
        <span class="w-full">Street Address:</span>
        <div class="w-full">
          <input autocomplete="off" v-model="location.streetLine1" @input="location.streetLine1Error = ''" type="text">
          <div class="text-left w-full">
            <span v-if="location.streetLine1Error !== ''" class="text-sm text-red-400">{{ location.streetLine1Error }}</span>
          </div>
        </div>
      </div>

      <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
        <span class="w-full">City:</span>
        <div class="w-full">
          <input autocomplete="off" v-model="location.city" @input="location.cityError == ''" type="text">
          <div class="text-left w-full">
            <span v-if="location.cityError !== ''" class="text-sm text-red-400">{{ location.cityError }}</span>
          </div>
        </div>
      </div>

      <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
        <span class="w-full">State:</span>
        <div class="w-full">
          <select  class="w-full" v-model="location.state">
            <option v-for="state in DV_stateList" @change="location.stateError = ''" :value="state">{{ state }}</option>
            <div class="text-left w-full">
              <span v-if="location.stateError !== ''" class="text-sm text-red-400">{{ location.stateError }}</span>
            </div>
          </select>
        </div>
      </div>

      <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
        <span class="w-full">Zip Code:</span>
        <div class="w-full">
          <input autocomplete="off" v-model="location.postalCode" @input="location.postalCodeError = ''" type="number" size="5" maxlength="5">
          <div class="text-left w-full">
              <span v-if="location.postalCodeError !== ''" class="text-sm text-red-400">{{ location.postalCodeError }}</span>
            </div>
        </div>
      </div>
      
      <!-- Business Hours -->

      <h1 class="mt-8 mb-2 text-left text-xl font-bold">Business Hours</h1>

      <div class="flex w-full grow mt-2 text-center border-2 border-slate-300 rounded-xl p-2">
        <span class="w-full">Timezone:</span>
        <div class="w-full">
          <select v-model="DV_selectedTimezone">
            <option v-for="timezone in DV_timeZones" :value="timezone.name">
              {{ timezone.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="mt-4">
        <div v-for="(day, index) in DV_daysOfWeek" :key="index" class="flex w-full grow mt-2 border-2 border-slate-300 rounded-xl p-2">
          <label class="text-left w-1/4 text-lg">
            <input type="checkbox" v-model="DV_selectedDays" :value="day">
            {{ day }}
          </label>
          
          <!-- Time Slots, Conditionally Rendered -->
          <div class="w-full text-right ml-6 mt-2" v-if="isDaySelected(day)">
            <div v-for="(slot, slotIndex) in DV_openHours[day]" :key="slotIndex" class="mb-2">
              <input 
                v-model="slot.open" 
                type="time" 
                :id="'open-time-' + day + '-' + slotIndex" 
                class="mr-4"
                @change="handleSlotChange(day)"
              >
              <span class="mx-2 mr-6">To</span>

              <input 
                v-model="slot.close" 
                type="time" 
                :id="'close-time-' + day + '-' + slotIndex" 
                class="mr-4"
                @change="handleSlotChange(day)"
              >
              <!-- Remove Slot Button -->
              <button @click="removeTimeSlot(day, slotIndex)" class="ml-2 text-red-600">Remove</button>
            </div>

            <!-- Add Slot Button -->
            <button @click="addTimeSlot(day)" class="text-blue-600">Add Time Slot</button>
          </div> 
        </div>
        
        <h1 class="mt-8 mb-2 text-left text-xl font-bold">
          After Hours Message
        
          <span class="tooltip font-normal">
              ?
              <span class="tooltiptext whitespace-pre-line text-lg" style="width: 650px">
                Text Formatting:<br>
                [Business Name] - {{ location.businessName }}<br>
                [Next Open Date] - 10/27/2024<br>
                [Next Open Time] - 8:00AM CST
              </span>
          </span>
        </h1>
      
        <div
          class="relative"
        >
          <textarea 
            rows="4" 
            class="w-full mt-2 border-2 border-slate-300" 
            v-model="C_computedBody"
            maxlength="320"
          ></textarea>
          <div class="absolute bottom-1 right-1 text-sm text-gray-500">
            {{ C_bodyCharacterCount }}/320
          </div>
        </div>
      </div>

      <hr v-if="DV_locations.length > 1" class="mb-8">
    </div>

    <div v-if="DV_showUnfinishedFeatures && C_canAddNewLocation && !DV_savingInformation" class="w-full mt-8">
      <button :disabled="DV_savingInformation" class="btn btn-lime" @click.stop="confirmAndAddLocation">Add New Location</button>
    </div>

    <div class="w-full mt-12 flex items-center justify-between">
      <button :disabled="DV_savingInformation" @click.stop="discardChanges" class="btn btn-white text-sm mr-3">Discard Changes</button>
      <button :disabled="DV_savingInformation" @click.stop="saveChanges" class="btn btn-blue text-sm mr-3">{{DV_savingInformation ? "Saving Changes..." : "Save Changes"}}</button>
    </div>

    <PopupModal ref="logoUploadModal" :P_showClose="true" :P_mediumModal="true" @close-modal="closeUploadModal">
      <template slot="body">
        <div id="dashboard"></div>
      </template>
    </PopupModal>
  </div>
</template>

<script>
import AWN from "awesome-notifications";
import createRecyclerLocation from "../../includes/server_calls/p1s3/p1s3e12"
import updateRecyclerLocationLogo from "../../includes/server_calls/p1s3/p1s3e23"
import dataValidator from "../../includes/bi1Datavalidation"
import {CR,RC} from "../../includes/bi2SuccessCodes"
import { fs1Writer } from '../../includes/firestore_writers/fs1_writer.ts'
import { fs2Writer } from '../../includes/firestore_writers/fs2_writer.ts'
import m3RenderingSettings from "../../mixins/m3RenderingSettings"
import m2MiscDataFetchers from "../../mixins/m2MiscDataFetchers";
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import ImageEditor from '@uppy/image-editor';
import PopupModal from "../shared/PopupModal.vue"
import moment, { utc } from "moment";

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/image-editor/dist/style.min.css';

export default {
  name: "p4s2BusinessInformation",

  mixins: [m3RenderingSettings, m2MiscDataFetchers],
  components: { Dashboard, PopupModal },
  props: [],

  data() {
    return {
      DV_activeBusinessUid: null,
      DV_ownerName: "",
      DV_businessType: 1,
      DV_ownerNameError: "",
      DV_phoneNumber: "",
      DV_phoneNumberError: "",
      DV_showDropzone: false,
      DV_savingInformation: false,
      DV_businessNotFound: false,
      DV_businessesLoading: true,
      DV_locationsLoading: true,
      DV_emptyLocation: {
        uid: null,
        businessName: "",
        businessPhoneNumber: "",
        businessTextNumber: "",
        email: "",
        websiteUrl: "",
        streetLine1: "",
        streetLine2: "",
        city: "",
        state: "Alabama",
        postalCode: "",
      },
      DV_locations: [],
      DV_stateList: ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"],
      DV_timeZones: [
        {
          "name": "Hawaii-Aleutian Standard Time (HST) - GMT: -10:00 - Honolulu",
          "zipCodes": [
            "96801", "96802", "96803", "96804", "96805", "96806", "96807", "96808", "96809", 
            "96810", "96811", "96812", "96813", "96814", "96815", "96816", "96817", "96818", 
            "96819", "96820", "96821", "96822", "96823", "96824", "96825", "96826", "96827", 
            "96828", "96830", "96835", "96836", "96837", "96838", "96839", "96840", "96841", 
            "96842", "96843", "96844", "96846", "96847", "96848", "96849", "96850", // Honolulu, Oahu
            "96720", "96721", // Hilo, Big Island
            "96740", "96741", "96742", "96743", "96744", "96745", // Kailua-Kona, Big Island
            "96732", "96733", // Kahului, Maui
            "96766", // Lihue, Kauai
            "96744", // Kaneohe, Oahu
          ]
        },
        {
          "name": "Alaska Standard Time (AKST) - GMT: -9:00 - Anchorage",
          "zipCodes": [
            "99501", "99502", "99503", "99504", "99505", "99506", "99507", "99508", "99509", 
            "99510", "99511", "99513", "99514", "99515", "99516", "99517", "99518", "99519", 
            "99520", "99521", "99522", "99523", "99524", "99529", "99530", // Anchorage
            "99645", // Palmer
            "99701", "99702", "99703", "99704", "99705", "99706", "99707", "99708", "99709", 
            "99710", "99711", "99712", "99714", "99716", "99720", "99721", "99722", "99723", 
            "99724", "99725", "99726", "99727", "99729", "99730", "99731", "99732", "99733", 
            "99734", "99736", "99737", "99738", "99739", "99740", "99741", "99742", "99743", 
            "99744", "99745", "99746", "99747", "99748", "99749", "99750", "99751", "99752", 
            "99753", "99754", "99755", "99756", "99757", "99758", "99759", "99760", "99761", 
            "99762", "99763", "99764", "99765", "99766", "99767", "99768", "99769", "99770", 
            "99771", "99772", "99773", "99774", "99775", "99776", "99777", "99778", "99779", 
            "99780", "99781", "99782", "99783", "99784", "99785", "99786", "99788", "99789", 
            "99791", // Various locations across Alaska
            "99801", "99802", "99803", "99811", "99812", "99820", "99821", "99824", "99825", 
            "99826", "99827", "99829", "99830", "99832", "99833", "99835", "99836", "99840", 
            "99841", // Juneau and surrounding areas
            "99901", "99903", "99918", "99919", "99921", "99922", "99923", "99925", "99926", 
            "99927", "99928", "99929", "99950" // Ketchikan and surrounding areas
          ]
        },
        {
          "name": "Pacific Standard Time (PST) - GMT: -8:00 - Los Angeles",
          "zipCodes": [
            "90001", "90002", "90003", "90004", "90005", "90006", "90007", "90008", "90010", 
            "90011", "90012", "90013", "90014", "90015", "90016", "90017", "90018", "90019", 
            "90020", "90021", "90022", "90023", "90024", "90025", "90026", "90027", "90028", 
            "90029", "90031", "90032", "90033", "90034", "90035", "90036", "90037", "90038", 
            "90039", "90040", "90041", "90042", "90043", "90044", "90045", "90046", "90047", 
            "90048", "90049", "90056", "90057", "90058", "90059", "90061", "90062", "90063", 
            "90064", "90065", "90066", "90067", "90068", "90069", "90071", "90073", "90077", 
            "90079", "90089", "90094", "90095", "90230", "90232", "90291", "90292", "90293", 
            "90401", "90402", "90403", "90404", "90405" // Los Angeles and surrounding areas
          ]
        },
        {
          "name": "Central Standard Time (CST) - GMT: -6:00 - Chicago",
          "zipCodes": [
            "60601", "60602", "60603", "60604", "60605", "60606", "60607", "60608", "60609", 
            "60610", "60611", "60612", "60613", "60614", "60615", "60616", "60617", "60618", 
            "60619", "60620", "60621", "60622", "60623", "60624", "60625", "60626", "60628", 
            "60629", "60630", "60631", "60632", "60633", "60634", "60636", "60637", "60638", 
            "60639", "60640", "60641", "60642", "60643", "60644", "60645", "60646", "60647", 
            "60649", "60651", "60652", "60653", "60654", "60655", "60656", "60657", "60659", 
            "60660", "60661", "60664", "60666", "60668", "60669", "60670", "60673", "60674", 
            "60675", "60677", "60678", "60680", "60681", "60682", "60684", "60685", "60686", 
            "60687", "60688", "60689", "60690", "60691", "60693", "60694", "60695", "60696", 
            "60697", "60699", "60701", "88220" // Chicago and surrounding areas
          ]
        },
        {
          "name": "Eastern Standard Time (EST) - GMT: -5:00 - New York",
          "zipCodes": [
            "10001", "10002", "10003", "10004", "10005", "10006", "10007", "10009", "10010", 
            "10011", "10012", "10013", "10014", "10016", "10017", "10018", "10019", "10020", 
            "10021", "10022", "10023", "10024", "10025", "10026", "10027", "10028", "10029", 
            "10030", "10031", "10032", "10033", "10034", "10035", "10036", "10037", "10038", 
            "10039", "10040", "10044", "10048", "10055", "10060", "10065", "10069", "10075", 
            "10080", "10081", "10087", "10103", "10110", "10111", "10112", "10115", "10119", 
            "10120", "10121", "10122", "10123", "10128", "10152", "10153", "10154", "10158", 
            "10162", "10165", "10167", "10169", "10170", "10171", "10172", "10173", "10174", 
            "10177", "10199", "10203", "10211", "10212", "10213", "10242", "10249", "10256", 
            "10257", "10258", "10259", "10260", "10261", "10265", "10268", "10269", "10270", 
            "10271", "10272", "10273", "10274", "10275", "10276", "10277", "10278", "10279", 
            "10280", "10281", "10282", "10285", "10286", "10292" // New York City and surrounding areas
          ],
        },
        {
          "name": "Atlantic Standard Time (AST) - GMT: -4:00 - San Juan",
          "zipCodes": [
            "00901", "00902", "00907", "00908", "00909", "00910", "00911", "00912", "00913", 
            "00914", "00915", "00916", "00917", "00918", "00919", "00920", "00921", "00922", 
            "00923", "00924", "00925", "00926", "00927", "00928", "00929", "00930", "00931", 
            "00933", "00934", "00935", "00936", "00937", "00940", "00949", "00950", "00951", 
            "00952", "00953", "00954", "00955", "00956", "00957", "00958", "00959", "00960", 
            "00961", "00962", "00963", "00965", "00966", "00968", "00969", "00970", "00971", 
            "00975", "00976", "00977", "00978", "00979", "00981", "00982", "00983", "00984", 
            "00985", "00986", "00987", "00988", "00989"
          ]
        },
        {
          "name": "Chamorro Standard Time (ChST) - GMT: +10:00 - Hagatna",
          "zipCodes": [
            "96910", // Hagåtña
            "96932", // Agana Heights
            "96919", // Sinajana
            "96929", // Ordot-Chalan Pago
            "96931", // Mangilao
            "96913", // Tamuning
            "96915", // Santa Rita
            "96916", // Umatac
            "96917", // Yona
            "96921", // Barrigada
            "96923", // Dededo
            "96924", // Inarajan
            "96925", // Merizo
            "96926", // Piti
            "96928", // Talofofo
            "96930", // Yigo
          ]
        },
      ],
      DV_daysOfWeek: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ],
      DV_openHours: {
        Sunday: [],
        Monday: [{ open: '08:00', close: '17:00' }],
        Tuesday: [{ open: '08:00', close: '17:00' }],
        Wednesday: [{ open: '08:00', close: '17:00' }],
        Thursday: [{ open: '08:00', close: '17:00' }],
        Friday: [{ open: '08:00', close: '17:00' }],
        Saturday: [],
      },
      DV_selectedTimezone: "",
      DV_selectedDays: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
      ],
      DV_afterHoursMessage: "",
      DV_uppy: null,
      DV_dropZoneElement: null,
      moment: moment,
    }
  },

  mounted() {
    const CI = this;

    CI.populateDefaultBusinessData(); // Populate existing business and locations. 
    CI.initUppy(); // Init uppy
  },

  computed: {
    C_computedBody: {
      get() {
        return this.DV_afterHoursMessage;
      },
      set(value) {
        this.DV_afterHoursMessage = value.length > 320 ? value.substring(0, 320) : value;
      }
    },
    
    C_bodyCharacterCount() {
      return this.DV_afterHoursMessage.length;
    },
    
    C_isEmployeeOwner() {
      return window.G_bi2_data.IV_active_employee_info.is_business_owner;
    },

    C_canAddNewLocation() {
      // Owner
      if (this.C_isEmployeeOwner) { return true; }

      // Admin
      const employeeInfo = window.G_bi2_data.IV_active_employee_info;
      const permissions = employeeInfo.permissions;
      
      if (employeeInfo && permissions && permissions.admin === true) {
        return true;
      }

      return false;
    }
  },

  methods: {
    async saveChanges() {
      const CI = this;
      CI.DV_savingInformation = true;

      let fail_flag = false;

      let call_result = dataValidator.is_string(CI.DV_ownerName, 3, 200);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_ownerNameError = "Business owner name cannot be empty or less than 3 letters.";
        fail_flag = true;
      }
      
      call_result = dataValidator.is_string(CI.DV_phoneNumber, 9, 10);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_phoneNumberError = "Phone number should be 10 digits";
        fail_flag = true;
      }

      for (let location of CI.DV_locations) {
        if (this.validateLocationInfo(location) === false) {
          fail_flag = true;
        }
      }

      // Validation failed
      if (fail_flag === true) { 
        console.log("test22")
        this.$awn.alert("Please fill in the required business information.")
        CI.DV_savingInformation = false;
        return; 
      }

      var failed_save_business = false;
      var failed_save_location = false;
      var failed_save_new_location = false;
      
      try {
        let result = await fs1Writer(
          "e", 
          CI.DV_activeBusinessUid,
          {
            business_uid: CI.DV_activeBusinessUid,
            business_name: CI.DV_ownerName,
            is_archived: null, // not changing
            phone: CI.DV_phoneNumber,
            owner_email: null, // not changing
            owner_firebase_uid: null, // not changing
            billing_method_uid: null // not changing
          }
        )

        if (result.success === false) { failed_save_business = true; }
      } catch (e) { failed_save_business = true;}

      if (failed_save_business === true) {
        this.$awn.alert(`Unable to save business information. Please reload page and try again.`);
        CI.DV_savingInformation = false;
        return;
      }

      /// Update existing location(s) and their logos if location.isDirtyLogo
      for (let location of CI.DV_locations) {
        if (location.isNewLocation) { continue; }

        let params = {
          recycler_location_uid: location.uid,
          recycler_name: location.businessName,
          phone: location.businessPhoneNumber,
          //text_number: location.businessTextNumber,
          email: location.email,
          city: location.city,
          state: location.state,
          postal_code: location.postalCode,
          address_line_1: location.streetLine1,
          address_line_2: location.streetLine2,
          website: location.websiteUrl,
          billing_document_id: "N/A",
          area_code: location.businessPhoneNumber.substring(0,3),
          timezone: this.DV_selectedTimezone,
          days_open: this.DV_selectedDays,
          business_hours: this.DV_openHours,
          after_hours_message: this.DV_afterHoursMessage,
        }

        try {
          let result = await fs2Writer("e", CI.DV_activeBusinessUid, location.uid, params)
          if (result.success === false) { failed_save_location = true; }
        } catch (e) { failed_save_location = true; }

        if (failed_save_location === true) {
          this.$awn.alert(`Unable to update location data for "${location.businessName}". Please reload page and try again.`);
          CI.DV_savingInformation = false;
          return;
        }

        if (location.isLogoDirty) {
          let failed_logo_upload = false;
          try {
            let result1 = await updateRecyclerLocationLogo(
              CI.DV_activeBusinessUid,
              location.uid,
              location.logo
            )
            if (result1.success === false) { failed_logo_upload = true; }
          } catch(e) { failed_logo_upload = true; }

          if (failed_logo_upload === true) {
            this.$awn.alert(`Unable to upload logo for new location ${location.businessName}.`);
            continue;
          }
        }
      }
      /// <END> Update existing location(s) and their logos if location.isDirtyLogo

      /// Create new location(s) and their logos if available
      for (let location of CI.DV_locations) {
        if (!location.isNewLocation) { continue; }

        let params = {
          recycler_uid: CI.DV_activeBusinessUid,
          name: location.businessName,
          phone: location.businessPhoneNumber,
          city: location.city,
          state: location.state,
          postal_code: location.postalCode,
          address_line_1: location.streetLine1,
          address_line_2: location.streetLine2,
          website: location.websiteUrl,
          billing_document_id: "N/A",
          area_code: location.businessPhoneNumber.substring(0,3)
        }
        return;

        let new_location_uid = null
        try {
          let result = await createRecyclerLocation(params)
          if (result.success === false) { failed_save_new_location = true; }
          new_location_uid = result.response.recycler_location_uid;
        } catch (e) { failed_save_new_location = true; }
        
        if (failed_save_new_location === true) {
          this.$awn.alert(`Unable to create new location ${location.businessName}. Please reload page and try again.`);
          CI.DV_savingInformation = false;
          return;
        }

        if (location.isLogoDirty && new_location_uid) {
          let failed_logo_upload = false;
          try {
            let result1 = await updateRecyclerLocationLogo(
              CI.DV_activeBusinessUid,
              new_location_uid,
              location.logo
            )

            if (result1.success === false) { failed_logo_upload = true; }
          } catch(e) { failed_logo_upload = true; }

          if (failed_logo_upload == true) {
            this.$awn.alert(`Unable to upload logo for new location ${location.businessName}.`);
            continue;
          }
        }
      }
      /// <END> Create new location(s) and their logos if available
      this.$awn.success("Data updated successfully.")
      CI.DV_savingInformation = false;
    },
    
    isDaySelected(day) {
      return this.DV_selectedDays.includes(day);
    },

    addTimeSlot(day) {
      this.DV_openHours[day].push({ open: '', close: '' });
    },

    removeTimeSlot(day, index) {
      this.DV_openHours[day].splice(index, 1);
    },
   
    validateNoOverlap(day) {
      const slots = this.DV_openHours[day];
      for (let i = 0; i < slots.length; i++) {
        for (let j = i + 1; j < slots.length; j++) {
          if (
            (slots[i].open < slots[j].close && slots[i].close > slots[j].open) ||
            (slots[j].open < slots[i].close && slots[j].close > slots[i].open)
          ) {
            return false; // Overlap detected
          }
        }
      }
      return true; // No overlap
    },
    
    handleSlotChange(day) {
      if (!this.validateNoOverlap(day)) {
        this.$awn.alert('The time slots for this day overlap. Please adjust the times to avoid overlapping.');
      }
    }, 

     validateLocationInfo(location) {
      var valid_flag = true;

      var call_result = dataValidator.is_string(location.businessName, 3, 200);
      if (call_result[CR.success] !== RC.success) {
        location.businessNameError = "Business name cannot be empty or less than 3 letters."
        valid_flag = false;
      }

      call_result = dataValidator.is_string(location.businessPhoneNumber, 9, 10);
      if (call_result[CR.success] !== RC.success) {
        location.businessPhoneNumberError = "Phone number should be 10 digits."
        valid_flag = false;
      }

      call_result = dataValidator.is_us_phone_number(location.businessPhoneNumber);
      if (call_result[CR.success] !== RC.success) {
        location.businessPhoneNumberError = "Valid US phone number is required.";
        valid_flag = true;
      }

      call_result = dataValidator.is_string(location.businessTextNumber, 9, 10);
      if (call_result[CR.success] !== RC.success) {
        location.businessTextNumberError = "Text number should be 10 digits."
        valid_flag = false;
      }

      call_result = dataValidator.is_us_phone_number(location.businessTextNumber);
      if (call_result[CR.success] !== RC.success) {
        location.businessTextNumberError = "Valid US text number is required.";
        valid_flag = true;
      }

      call_result = dataValidator.is_email_address(location.email);
      if (call_result[CR.success] !== RC.success) {
        location.emailError = "Email address is invalid";
        valid_flag = false;
      }

      call_result = dataValidator.is_string(location.streetLine1, 3, 200);
      if (call_result[CR.success] !== RC.success) {
        location.streetLine1Error = "Location address line 1 should be between 3 and 200 characters";
        valid_flag = false;
      }

      call_result = dataValidator.is_string(location.city, 3, 200);
      if (call_result[CR.success] !== RC.success) {
        location.cityError = "City should be between 3 and 100 characters";
        valid_flag = false;
      }

      call_result = dataValidator.is_string(location.state, 1, 50);
      if (call_result[CR.success] !== RC.success) {
        location.stateError = "Please select a State";
        valid_flag = false;
      }

      call_result = dataValidator.is_number(+location.postalCode, 4, 5);
      if (call_result[CR.success] !== RC.success) {
        location.postalCodeError = "Postal code should be 5 digits";
        valid_flag = false;
      }

      call_result = dataValidator.is_string(location.websiteUrl, 5, 200);
      if (call_result[CR.success] !== RC.success) {
        location.websiteUrlError = "Website URL should be between 3 and 200 characters";
        valid_flag = false;
      }

      return valid_flag;
    },

    discardChanges() {
      this.populateDefaultBusinessData();
    },

    confirmAndAddLocation() {
      const CI = this;

      let notifier = new AWN();
      
      notifier.confirm(
        
        // Label
        "Adding another location is an additional $70/month plus usage. Do you agree to this increase?",
        
        // On OK
        function() {
          let newLocation = Object.assign({}, CI.DV_emptyLocation);
          newLocation.isNewLocation = true;
          CI.DV_locations.push(newLocation);
        },

        // On cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Add New Location',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },

    updateThumbnail(dropZoneElement, file) {
      let thumbnailElement = dropZoneElement.querySelector(".drop-zone__thumb");

      // First time - remove the prompt
      if (dropZoneElement.querySelector(".drop-zone__prompt")) {
        dropZoneElement.querySelector(".drop-zone__prompt").remove();
      }

      // First time - there is no thumbnail element, so let's create it
      if (!thumbnailElement) {
        thumbnailElement = document.createElement("div");
        thumbnailElement.classList.add("drop-zone__thumb");
        dropZoneElement.appendChild(thumbnailElement);
      }

      // Show existing logo
      if (typeof file === "string" && file.includes("https://")) {
        thumbnailElement.dataset.label = "logo";
        thumbnailElement.style.backgroundImage = `url('${file}')`;
        thumbnailElement.style.backgroundPosition = "center";
        thumbnailElement.style.backgroundRepeat = "no-repeat";
        thumbnailElement.style.backgroundSize = "contain";
        return;
      }

      // Show new logo
      thumbnailElement.dataset.label = file.name;

      // Show thumbnail for image files
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
          thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
        };
      } else {
        thumbnailElement.style.backgroundImage = null;
      }
    },

    handleFileChange(e) {
      const CI = this;
      const inputElement = e.target;
      CI.DV_dropZoneElement = inputElement.closest(".drop-zone");

      var files = [];

      if (e.target.tagName == 'LABEL') {
        files = e.dataTransfer.files;
      } else if (e.target.tagName == 'INPUT') {
        files = e.target.files;
      }

      if (!files || !files[0]) { return; } 

      if (files.length > 1) {
        this.$awn.alert("Please choose only one file per message.");
        return;
      }

      // validate size
      var file = files[0];
      if (file.size > (10*1024*1024)) {
        this.$awn.alert("Logo size must be less than 10 megabytes.");
        return;
      }

      // Before opening modal remove the previous file
      let prevFile = this.DV_uppy.getFiles()[0]
      if (prevFile) {
        this.DV_uppy.removeFile(prevFile.id);
      }

      // Add file and open modal
      this.DV_uppy.addFile(file);
      this.showUploadModal();
      return;
    },

    populateDefaultBusinessData(retry_count=0) {
      const CI = this;

      CI.DV_activeBusinessUid = window.G_bi2_data.IV_loggedin_user_business_ids.value[0];
      
      // If not business owner check for admin employee
      if (!CI.DV_activeBusinessUid) {
        const employeeInfo = window.G_bi2_data.IV_active_employee_info;
        const employeeBusinessUid = employeeInfo.business_uid;
        const permissions = employeeInfo.permissions;
        
        if (employeeBusinessUid && permissions && (permissions.admin === true || permissions.manager === true)) {
          CI.DV_activeBusinessUid = employeeInfo.business_uid  
        }
      }
      const activeBusiness = window.G_bi2_data.IV_businesses['value'][CI.DV_activeBusinessUid];

      // Active business data
      if (!activeBusiness) { 
        if (retry_count >= 10) { 
          CI.DV_businessNotFound = true;
          CI.DV_businessesLoading = false;
          return; 
        }
        setTimeout(function() {
          CI.populateDefaultBusinessData(++retry_count)
        }, 500);
        return; 
      }

      CI.DV_ownerName = activeBusiness.name;
      CI.DV_phoneNumber = activeBusiness.phone_number;
      CI.DV_businessesLoading = false;
      
      if (activeBusiness.business_type === 1) {
        CI.DV_businessType = "Full-Service";
      } else if (activeBusiness.business_type === 2) {
        CI.DV_businessType = "Self-Service";
      }

      CI.populateDefaultLocationsData();
    },

    populateDefaultLocationsData(retry_count=0) {
      const CI = this;

      CI.DV_locations = [];
      const allLocations = window.G_bi2_data.IV_business_recycler_joins.value[CI.DV_activeBusinessUid];

      let isLoaded = CI.isLocationsDataLoaded();
      if (isLoaded === false) {
        if (retry_count >= 10) { 
          CI.DV_locationsLoading = false;
          return; 
        }
        setTimeout(function() { CI.populateDefaultLocationsData(++retry_count) }, 500);
        return; 
      }

      // All available locations
      for (let location_uid in allLocations) {
        if (location_uid in window.G_bi2_data.IV_recyclers.value === false) { continue; }

        const location_data = window.G_bi2_data.IV_recyclers.value[location_uid];
        let logo = ""
        
        if (`${CI.DV_activeBusinessUid}-${location_uid}` in window.G_bi2_data.IV_recyclers_logo_urls == true) {
          logo = window.G_bi2_data.IV_recyclers_logo_urls[`${CI.DV_activeBusinessUid}-${location_uid}`];
        }

        let texting_number = "1-" + this.formatPhoneNumber(location_data.text_number);
        
        CI.DV_locations.push({
          uid: location_uid,
          businessName: location_data.recycler_location_name,
          businessPhoneNumber: location_data.phone_number,
          businessTextNumber: texting_number,
          email: location_data.email,
          websiteUrl: location_data.website,
          streetLine1: location_data.address_line_1,
          streetLine2: location_data.address_line_2,
          city: location_data.city,
          state: location_data.state,
          postalCode: location_data.postal_code,
          logo: logo
        });

        if (location_data.timezone) {
          CI.DV_selectedTimezone = location_data.timezone;
        } else {
          for (let timezone in CI.DV_timeZones) {
            let entry = CI.DV_timeZones[timezone];
            
            if (entry.zipCodes.includes(location_data.postal_code.toString())) {
              CI.DV_selectedTimezone = entry.name;
         
              this.saveChanges();      
            }
          }
        }

        if (location_data.after_hours_message) {
          CI.DV_afterHoursMessage = location_data.after_hours_message;
        }

        if (location_data.days_open) {
          CI.DV_selectedDays = location_data.days_open;
          CI.DV_openHours = location_data.business_hours;
        }

      }

      CI.DV_locationsLoading = false;

      // comment logic as we are not dealing with multiple locations
      // setTimeout(function() {CI.populateLocationsLogos()}, 500);
    },

    populateLocationsLogos() {
      const CI = this;
      var index = 0;

      for (let location of CI.DV_locations) {

        if (!location.logo) { 
          index += 1;
          continue; 
        }

        const dropZoneElement = document.querySelectorAll(`[data-id='${index}'].drop-zone`)
        if (dropZoneElement[0]) {
          CI.updateThumbnail(dropZoneElement[0], location.logo)
        }

        index += 1;
      }
    },

    isLocationsDataLoaded() {
      const CI = this;
      const allLocations = window.G_bi2_data.IV_business_recycler_joins.value[CI.DV_activeBusinessUid];

      if (!allLocations) { return false; }

      if (Object.keys(allLocations).length === 0) { return false; }

      // Check if any location available
      for (let location_uid in allLocations) {
        if (location_uid in window.G_bi2_data.IV_recyclers.value === true) { 
          return true;
        }
      }

      return false;
    },

    initUppy() {
      const CI = this;

      CI.DV_uppy = new Uppy()
      CI.DV_uppy.use(Dashboard, { inline: true, target: '#dashboard', proudlyDisplayPoweredByUppy: false })
      CI.DV_uppy.use(ImageEditor, { target: Dashboard })
      CI.DV_uppy.setOptions({restrictions: {maxNumberOfFiles: 1, maxFileSize: 10*1024*1024, allowedFileTypes: ['image/*']}})

      CI.DV_uppy.on('upload', () => {
        let file = CI.DV_uppy.getFiles()[0];
        CI.setLogo(file.data);
        
      });
    },

    setLogo(file) {
      const CI = this;

      if (CI.DV_locations[+CI.DV_dropZoneElement.dataset.id]) {
        var reader = new FileReader();
        reader.onload = (obj) => {

          CI.DV_locations[+CI.DV_dropZoneElement.dataset.id].logo = obj.srcElement.result;
          CI.DV_locations[+CI.DV_dropZoneElement.dataset.id].isLogoDirty = true;
          CI.closeUploadModal();
        }

        reader.readAsDataURL(file)
      }

      // comment logic as we are not dealing with multiple locations
      // CI.updateThumbnail(CI.DV_dropZoneElement, file);
    },
 
    showUploadModal() {
      this.$refs.logoUploadModal.show()
    },
    
    closeUploadModal() {
      this.$refs.logoUploadModal.hide()
    }
  },

  watch: {}
}
</script>

<style>
.drop-zone {
  max-width: 200px;
  height: 200px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  color: #cccccc;
  border: 4px dashed #009578;
  border-radius: 10px;
}

.drop-zone--over {
  border-style: solid;
}

.drop-zone__input {
  display: none;
}

.drop-zone__thumb {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #cccccc;
  background-size: cover;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.drop-zone__thumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  text-align: center;
}
</style>