import { FirestoreDB } from "./include/creds";
import {
  DocPath,
  DataProblem,
  GetDocResult,
  RemoveDocResult,
} from "./include/misc";

import {
  collection,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

export type CssFiles = {
  file_name?: string;
};

export type fs2RecyclerLocation = {
  recycler_location_uid: string;
  recycler_name: string;
  phone: string;
  text_number_sid?: string;
  text_number: string;
  email: string;
  city: string;
  state: string;
  postal_code: number;
  address_line_1: string;
  address_line_2?: string;
  facebook_url?: string;
  google_maps_url?: string;
  review_link_url?: string;
  website: string;
  css_files?: CssFiles;
  payment_api_keys_lookup_uid?: string;
  review_provider_account_uid?: string;
  assigned_payment_method?: string;
  sms_only_messaging?: boolean;
  connect_account_id?: string;
  conversation_starters_enabled?: boolean;
  timezone?: string;
  days_open?: string[];
  business_hours?: string[];
  autosend_review_link_url?: boolean;
  after_hours_message?: string;
  quick_queue_entry?: boolean;
};

export function getDataProblems_fs2RecyclerLocation(
  data: fs2RecyclerLocation
): DataProblem[] {
  const dataproblems: DataProblem[] = [];
  return dataproblems;
}

// We assume that this function is fast and will not block.
export function isValidData(data: fs2RecyclerLocation): boolean {
  return true;
}

export async function getDocFrom_fs2RecyclerLocation(
  fs1_id: string,
  fs2_id: string
): Promise<GetDocResult> {
  const result: GetDocResult = {
    debug_data: [],
    return_msg: "fs2RecyclerLocation:getDocFrom_fs2RecyclerLocation",
    success: true,
    doc_data: {},
  };

  if (!fs1_id || !fs2_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation"
    ),
    fs2_id
  );

  const doc_data = (await getDoc(doc_ref)).data();
  if (doc_data === undefined) {
    result.success = false;
    result.return_msg +=
      "Could not find a document with the passed-in path arguments.";
    return await Promise.resolve(result);
  }

  result.doc_data = doc_data;
  return await Promise.resolve(result);
}

export async function addDocIn_fs2RecyclerLocation(
  fs1_id: string,
  data: fs2RecyclerLocation
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation"
    )
  );

  data.recycler_location_uid = doc_ref.id;
  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;

  return await Promise.resolve(result);
}

export async function setDocIn_fs2RecyclerLocation(
  fs1_id: string,
  fs2_id: string,
  data: fs2RecyclerLocation
): Promise<DocPath> {
  const result: DocPath = {
    id: "",
    path: "",
  };
  if (!isValidData(data)) {
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation"
    ),
    fs2_id
  );

  await setDoc(doc_ref, data);

  result.id = doc_ref.id;
  result.path = doc_ref.path;

  return await Promise.resolve(result);
}

export async function removeDocFrom_fs2RecyclerLocation(
  fs1_id: string,
  fs2_id: string
): Promise<RemoveDocResult> {
  const result: RemoveDocResult = {
    debug_data: [],
    return_msg: "fs2RecyclerLocation:getDocFrom_fs2RecyclerLocation",
    success: true,
  };

  if (!fs1_id || !fs2_id) {
    result.success = false;
    result.return_msg += "Invalid function arguments.";
    return await Promise.resolve(result);
  }

  const doc_ref = doc(
    collection(
      FirestoreDB,
      "fs1RecyclerBusiness",
      fs1_id,
      "fs2RecyclerLocation",
      fs2_id
    )
  );

  await deleteDoc(doc_ref);
  return await Promise.resolve(result);
}
