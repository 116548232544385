/* eslint-disable */
import { ajax } from "noquery-ajax";
import Vue from "vue";
import { httpsLogger } from "../p1s7/p1s7e1";

function createRecyclerBusiness(
  business_name: string,
  business_type: number,
  phone_number: string,
  first_name: string,
  last_name: string) {
  return new Promise(function (resolve, reject) {
    let params = {
      p1s3e20_business_name: business_name,
      p1s3e20_business_type: business_type,
      p1s3e20_owner_first_name: first_name,
      p1s3e20_owner_last_name: last_name,
      p1s3e20_owner_email: window.G_firebase_auth.IV_email_address,
      p1s3e20_owner_firebase_uid: window.G_firebase_auth.IV_uid,
      p1s3e20_phone: phone_number,
      p1s3e20_billing_document_id: "not_set",
      firebase_email: window.G_firebase_auth.IV_email_address,
      firebase_token: window.G_firebase_auth.IV_id_token,
      firebase_uid: window.G_firebase_auth.IV_uid,
    };

    ajax({
      url: window.G_cloud_p1s3_base_url + "p1s3e20CreateRecyclerBusiness",
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      dataType: "json",
      data: params,

      success: function (result) {
        let response_data = {
          success: true,
          response: result,
          request_params: params
        }
        resolve(response_data);
      },

      error: function (error) {
        let error_data = {
          success: false,
          request_object: error,
          request_params: params,
          file_name: "p1s3e20.ts",
          class_name: "p1s3e20",
          method_name: "createRecyclerBusiness",
          error_title: "Failed to create new Recycler Business",
          error_description:
            "The server responded with an error when trying to create a new Recycler Business",
        };

        httpsLogger(error_data);
        reject(error_data);
      }
    });
  });
}

export default createRecyclerBusiness;
