<template>
  <div 
  :class="{'account-setup-main-container' : DV_activeRenderMode > DV_renderModePhone,
        'h-[100vh]': DV_activeRenderMode < DV_renderModeTablet}"
  class="overflow-hidden">
    <!-- Header -->
    <c1Header :P_showLogoutButton="DV_loggedInFlag === true"></c1Header>
    

    <!-- Dynamic components for step form -->
    <span class="md:pb-5" v-if="DV_loggedInFlag === true"> Hello 
        <strong>{{ DV_stepFormData.accountInfo.firstName }} 
        {{ DV_stepFormData.accountInfo.lastName }}</strong>, you are currently logged in<br> using your 
      <span class="font-bold"> {{  DV_stepFormData.accountInfo.email }}</span> 
      email address
    </span>

    <div v-if="DV_isCheckingAuthentication === false"
      class="bg-white my-auto mx-auto border pb-4 border-gray-900 rounded"
      style="max-height: 80vh; overflow-y: auto; overflow-x: hidden;"
      :class="{'w-3/4' : DV_activeRenderMode > DV_renderModePhone && C_showNarrowContainer === false,
        'w-full': DV_activeRenderMode < DV_renderModeTablet,
        'w-fit': C_showNarrowContainer}"
    >

      <p3s2AccountSetupPaymentDetails     
        v-show="C_activeStep === 'p3s2AccountSetupPaymentDetails'"
        ref="accountSetupPaymentDetailsComponent"
        :P_formData="DV_stepFormData"
        :P_errorData="DV_stepErrorData"
        :P_loggedInFlag="DV_loggedInFlag"
        :P_creatingBusinessStatus="DV_creatingBusinessStatus"
        :P_creatingLocationStatus="DV_creatingLocationStatus"
        :P_creatingUserAccount="DV_creatingUserAccountStatus"
        @next="nextStep"
        @back="previousStep"
        @payment-success="paymentFinished"
        @payment-failed="paymentFailed"
        @event-update-step-form-data="updateStepFormData"
      ></p3s2AccountSetupPaymentDetails>

      <p3s4AccountSetupCreateLocation     
        v-show="C_activeStep === 'p3s4AccountSetupCreateLocation'"
        :P_formData="DV_stepFormData"
        :P_errorData="DV_stepErrorData"
        :P_loggedInFlag="DV_loggedInFlag"
        :P_creatingBusinessStatus="DV_creatingBusinessStatus"
        :P_creatingLocationStatus="DV_creatingLocationStatus"
        :P_creatingUserAccount="DV_creatingUserAccountStatus"
        @next="nextStep"
        @back="previousStep"
        @event-update-step-form-data="updateStepFormData"
      ></p3s4AccountSetupCreateLocation>

      <p3s6AccountSetupTermsOfService     
        v-show="C_activeStep === 'p3s6AccountSetupTermsOfService'"
        :P_formData="DV_stepFormData"
        :P_errorData="DV_stepErrorData"
        :P_loggedInFlag="DV_loggedInFlag"
        :P_creatingBusinessStatus="DV_creatingBusinessStatus"
        :P_creatingLocationStatus="DV_creatingLocationStatus"
        :P_creatingUserAccount="DV_creatingUserAccountStatus"
        @next="nextStep"
        @back="previousStep"
        @event-update-step-form-data="updateStepFormData"
      ></p3s6AccountSetupTermsOfService>

      <p3s8AccountSetupFinalProgress     
        v-show="C_activeStep === 'p3s8AccountSetupFinalProgress'"
        :P_formData="DV_stepFormData"
        :P_errorData="DV_stepErrorData"
        :P_loggedInFlag="DV_loggedInFlag"
        :P_creatingBusinessStatus="DV_creatingBusinessStatus"
        :P_creatingLocationStatus="DV_creatingLocationStatus"
        :P_creatingUserAccount="DV_creatingUserAccountStatus"
        :P_chargingCardStatus="DV_chargingCardStatus"
        @next="nextStep"
        @back="previousStep"
        @event-update-step-form-data="updateStepFormData"
      ></p3s8AccountSetupFinalProgress>
    </div>
  </div>
</template>

<script>
import AWN from "awesome-notifications";
import createRecyclerLocation from "../includes/server_calls/p1s3/p1s3e12"
import dataValidator from "../includes/bi1Datavalidation"
import { fs128Writer } from '../includes/firestore_writers/fs128_writer.ts'
import { fs2Writer } from '../includes/firestore_writers/fs2_writer.ts'
import createTollFreePhoneNumber from "../includes/server_calls/p1s3/p1s3e19";
import createRecyclerBusiness from "../includes/server_calls/p1s3/p1s3e20";
import p3s2AccountSetupPaymentDetails from "../components/p3/p3s2AccountSetupPaymentDetails"
import p3s4AccountSetupCreateLocation from "../components/p3/p3s4AccountSetupCreateLocation"
import p3s6AccountSetupTermsOfService from "../components/p3/p3s6AccountSetupTermsOfService"
import p3s8AccountSetupFinalProgress from "../components/p3/p3s8AccountSetupFinalProgress"
import m3RenderingSettings from "../mixins/m3RenderingSettings";
import _ from "lodash"
import { removeDocFrom_fs45RecentRemovedQueueEntries } from "@/includes/firestore_schemas/fs45RecentRemovedQueueEntries";
import c1Header from "../components/c1Header"
import Vue from "vue"

  export default {
    name: "p3AccountSetup",

    mixins: [ m3RenderingSettings ],

    components: {
      p3s2AccountSetupPaymentDetails,
      p3s4AccountSetupCreateLocation,
      p3s6AccountSetupTermsOfService,
      p3s8AccountSetupFinalProgress,
      c1Header
    },

    data() {
      return {
        DV_loggedInFlag: false,
        DV_activeStepNumber: 1,
        DV_totalSteps: 4,
        DV_stepNumberMapping: {
          locationStep: 1,
          termsStep: 2,
          paymentStep: 3,
          finalStep: 4,
        },        
        DV_isCheckingAuthentication: true,
        DV_creatingBusinessStatus: "",
        DV_creatingLocationStatus: "",
        DV_creatingUserAccountStatus: "",
        DV_chargingCardStatus: "",
        DV_stepErrorData: {
          accountInfo: {
            emailError: "",
            passwordError: ""
          },
          creationServerCalls: {
            firebaseAccountError:"",
            p1s3e10Error: "",
            p1s3e20Error: "",
            p1s3e19Error: "",
            p1s3e12Error: "",
            p1s7e3Error: "",
          },
        },
        DV_stepFormData: {
          tosAgreeClicked: false,
          accountInfo: {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          },
          businessInfo: {
            businessName: "",
            phoneNumber: "",
            businessUid: null,
          },
          locationInfo: {
            phoneNumber: "",
            Locationname: "",
            locationUid: null
          }
        },
        DV_emailsAlreadyInvited: {},
        DV_createBusinessOnSignIn: false,
        DV_chargePaymentOnSignIn: false
      }
    },

    mounted() {
      const CI = this;

      window.document.title = "Synergy Auto Solutions - Account Setup"
      CI.authenticationCheck(0);
      let saved_form_data = localStorage.getItem("p3_DV_stepFormData");
     
      // clear business info if user wants to sign up with a trial token
      let queryParams = new URLSearchParams(window.location.search);
      let trialToken = queryParams.get("trial_token");

      localStorage.removeItem("p3_DV_stepFormData");

      if (saved_form_data !== undefined && saved_form_data !== null ) {
        try {
          let decoded_saved_form_data = JSON.parse(saved_form_data);
          CI.DV_stepFormData = decoded_saved_form_data;
        } catch {}
      }

      CI.$nextTick(() => {
        window.G_firebase_auth.clearLoginPageCallbacks();
        window.G_firebase_auth.setLoginPageCallbacks(
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          CI.createUserFailed,
        );

        //if the user was signing out reset the signout requested flag
        if (window.G_firebase_auth.IV_signout_requested_flag === true) {
          window.G_firebase_auth.IV_signout_requested_flag = false;
        }
      });
    },

    computed: {
      C_activeStep() {
        const CI = this;

        switch(CI.DV_activeStepNumber) {
          case 1:
            return "p3s4AccountSetupCreateLocation";
            break;
          case 2:
            return "p3s6AccountSetupTermsOfService";
            break;
          case 3:
            return "p3s2AccountSetupPaymentDetails";
            break;          
          case 4:
            return "p3s8AccountSetupFinalProgress";
            break;
          default:
            return "p3s4AccountSetupCreateLocation";
        }
      },

      C_showNarrowContainer() {
        const CI = this;

        return (CI.C_activeStep == 'p3s8AccountSetupFinalProgress')
      }
    },

    methods: {
      authenticationCheck(retry_count) {
        const CI = this;

        if (retry_count >= 40) {
          CI.DV_isCheckingAuthentication = false;
          return;
        }

        if (window.G_firebase_auth.IV_is_user_logged_in !== true) {
          setTimeout( function() {CI.authenticationCheck(++retry_count)}, 40);
          return;
        };

        CI.DV_stepFormData.accountInfo.firstName = window.G_firebase_auth.IV_first_name;
        CI.DV_stepFormData.accountInfo.lastName = window.G_firebase_auth.IV_last_name;
        CI.DV_stepFormData.accountInfo.email = window.G_firebase_auth.IV_email_address;
        
        CI.DV_loggedInFlag = true;
        CI.DV_isCheckingAuthentication = false;
      },

      nextStep() {
        const CI = this;

        if (CI.DV_activeStepNumber === CI.DV_stepNumberMapping.termsStep) {
          CI.DV_stepFormData.tosAgreeClicked = true;
        }
        CI.storeFormDataInLocalStorage();
        
        if (CI.DV_activeStepNumber === CI.DV_totalSteps) { return; }

        // skip payment step when signup with trial token
        if ((CI.DV_activeStepNumber === CI.DV_stepNumberMapping.termsStep) && (window.G_trial_token.length > 0)) {
          CI.DV_activeStepNumber += 2;
        } else {
          CI.DV_activeStepNumber += 1;
        }

        if (CI.DV_activeStepNumber  === CI.DV_totalSteps)  {
          if (CI.DV_loggedInFlag === false) {
            CI.DV_chargePaymentOnSignIn = true;
          }
          CI.startAccountCreatingProcess();
        }
      },

      previousStep() {
        const CI = this;

        if (CI.DV_activeStepNumber == 1) {
          return;
        }

        CI.DV_activeStepNumber -= 1;
      },

      storeFormDataInLocalStorage() {
        localStorage.setItem("p3_DV_stepFormData", JSON.stringify(this.DV_stepFormData));
      },
      
      updateStepFormData(keyValueData) {
        const CI = this;

        _.set(CI.DV_stepFormData, keyValueData.key, keyValueData.value);
        CI.storeFormDataInLocalStorage();
      },

      async startAccountCreatingProcess() {
        const CI = this;

        CI.DV_activeStepNumber = CI.DV_stepNumberMapping.finalStep;
        CI.DV_creatingBusinessStatus = "";
        CI.DV_creatingLocationStatus = "";
        CI.DV_creatingUserAccountStatus = "";

        if (window.G_firebase_auth.IV_is_user_logged_in === false) { 
          CI.DV_creatingUserAccountStatus = "creating"; 
        }

        if (window.G_trial_token.length > 0) {
          if (!dataValidator.is_string(window.G_trial_token, 0, 20)) {
            this.$awn.alert("Please sign up with a valid trial token");
            CI.DV_creatingUserAccountStatus = "error";
            CI.DV_activeStepNumber = CI.DV_stepNumberMapping.locationStep
            return;
          }

          try {
            let result = await fs128Writer(
              'e',
              window.G_trial_token,
              {
                trial_account_token: window.G_trial_token,
                token_used: false,
                business_uid: "",
                email_address: ""
              }
            )

            if (result.success === false) { this.$awn.alert(result.error_next_steps) }
          } catch (e) {
            this.$awn.alert("Please try again later.")
            CI.DV_creatingUserAccountStatus = "error";
            CI.DV_activeStepNumber = CI.DV_stepNumberMapping.locationStep
            return;
          }
        }

        CI.waitForStaffAccount(0, CI);
        
        if (window.G_firebase_auth.IV_is_user_logged_in === false && CI.DV_creatingUserAccountStatus !== "error") {
          CI.createUserAccount();
          //the signed in callback will start the process of creating the business
          CI.DV_createBusinessOnSignIn = true;
        }
      },

      paymentFailed(server_response) {
        const CI = this;

        CI.DV_chargingCardStatus = "error";
        CI.DV_stepErrorData.creationServerCalls.p1s7e3Error = JSON.stringify(server_response);
        CI.DV_activeStepNumber = CI.DV_stepNumberMapping.paymentStep;
      },

      paymentFinished(move_next) {
        const CI = this;

        CI.DV_chargingCardStatus = "finished";
        CI.createLocation(CI.DV_activeStepNumber === CI.DV_stepNumberMapping.paymentStep);
      },

      createBusiness() {
        const CI = this;
        
        if (CI.DV_creatingBusinessStatus === "creating") { return; }
        
        CI.DV_creatingBusinessStatus = "creating";

        // don't create a business if one was already created
        if (CI.DV_stepFormData.businessInfo.businessUid !== undefined && CI.DV_stepFormData.businessInfo.businessUid !== null) {
          CI.DV_creatingBusinessStatus = "finished";
          CI.callForPaymentVerification();
          return;
        }

        createRecyclerBusiness(
          CI.DV_stepFormData.businessInfo.businessName,
          CI.DV_stepFormData.businessInfo.businessType,
          CI.DV_stepFormData.businessInfo.phoneNumber,
          CI.DV_stepFormData.businessInfo.ownerFirstName,
          CI.DV_stepFormData.businessInfo.ownerLastName
        ).then((resp) => {
          CI.DV_stepFormData.businessInfo.businessUid = resp.response.recycler_uid;
          CI.DV_creatingBusinessStatus = "finished";
          CI.storeFormDataInLocalStorage();
          CI.callForPaymentVerification();
        }).catch((error) => {
          CI.DV_stepErrorData.creationServerCalls.p1s3e20Error = JSON.stringify(error);
          CI.$awn.alert(error.error_title)
          CI.DV_creatingBusinessStatus = "error";
        });
      },

      createLocation(move_next=false) {
        const CI = this
        let form_data = CI.DV_stepFormData

        if (move_next) {
          CI.DV_activeStepNumber = CI.DV_stepNumberMapping.finalStep;
        }

        if (CI.DV_creatingLocationStatus === "creating") { return; }

        CI.DV_creatingLocationStatus = "creating";

        // If location already created don't create again;
        if (CI.DV_stepFormData.locationInfo.locationUid !== undefined && CI.DV_stepFormData.locationInfo.locationUid !== null) {
          CI.DV_creatingLocationStatus = "finished";
          return;
        }

        let params = {
          recycler_uid: form_data.businessInfo.businessUid,
          first_name: CI.DV_stepFormData.businessInfo.ownerFirstName,
          last_name: CI.DV_stepFormData.businessInfo.ownerLastName,
          name: form_data.locationInfo.name,
          phone: form_data.locationInfo.phone,
          billing_document_id: "N/A",
          area_code: form_data.locationInfo.phone.substring(0,3),
          address_line_1: form_data.locationInfo.address,
          postal_code: form_data.locationInfo.postalCode,
          state: form_data.locationInfo.state,
          city: form_data.locationInfo.city,
          website: form_data.locationInfo.website
        }

        const skipEmails = ["matthew@sofsafe.cloud", "parker@texnrewards.com"]
        // don't create a recycler location if one was already created
        if (CI.DV_stepFormData.locationInfo.locationUid !== undefined && CI.DV_stepFormData.locationInfo.locationUid !== null && !skipEmails.includes(CI.DV_stepFormData.accountInfo.email)) {
          CI.createPhoneNumber(CI);
          return;
        }

        createRecyclerLocation(params).then((resp) => {
          CI.DV_stepFormData.locationInfo.locationUid = resp.response.recycler_location_uid;

          // Update the assigned_payment_method if set via URL
          if (window.location.pathname.includes('axe') || window.location.pathname.includes('slimcd')) {
            let assigned_payment_method = null
            if (window.location.pathname.includes('axe')) {
              assigned_payment_method = "axe";
            } else if (window.location.pathname.includes('slimcd')) {
              assigned_payment_method = "slimcd";
            }

            fs2Writer(
              "e", 
              form_data.businessInfo.businessUid, 
              CI.DV_stepFormData.locationInfo.locationUid, 
              {
                assigned_payment_method: assigned_payment_method
              }
            )
          }
          
          if (skipEmails.includes(CI.DV_stepFormData.accountInfo.email)) {
            CI.DV_creatingLocationStatus = "finished";
          } else {
            CI.createPhoneNumber(CI);
          }
        }).catch((error) => {
          this.$awn.alert(error.error_title)
          CI.DV_stepErrorData.creationServerCalls.p1s3e12Error = JSON.stringify(error);
          CI.DV_creatingLocationStatus = "error";
        });
      },

      createPhoneNumber(class_instance) {
        const CI = class_instance;

        createTollFreePhoneNumber( 
        CI.DV_stepFormData.businessInfo.businessUid,
        CI.DV_stepFormData.locationInfo.locationUid).then((resp) => {
          CI.DV_creatingLocationStatus = "finished";
          CI.storeFormDataInLocalStorage();
          localStorage.removeItem("p3_DV_stepFormData");
        }).catch((error) => {
          this.$awn.alert(error.error_title)
          CI.DV_stepErrorData.creationServerCalls.p1s3e19Error = JSON.stringify(error);
          CI.DV_creatingLocationStatus = "error";
        });
      },

      waitForStaffAccount(retry_count, class_instance) {
        const CI = class_instance;

        if (retry_count > 100) {
          CI.DV_creatingUserAccountStatus = "error";
          class_instance.DV_stepErrorData.creationServerCalls.p1s3e10Error = "We apologize for the inconvience, our server failed to create your Synergy owner account, please contact us at support@synergy-auto-solutions.com"
          return;
        }
        retry_count += 1;
        if (window.G_firebase_auth.IV_staff_account_created === true && window.G_bi2_data.IV_loggedin_user_business_ids_fetched === true ) {
          //delayed call to let back-end see database entry in time
          setTimeout( function() {
            CI.DV_creatingUserAccountStatus = "finished";
            CI.DV_loggedInFlag = true;
            class_instance.createBusiness();
          }.bind(class_instance),15000);
          return;
        } else {
          setTimeout( function() {class_instance.waitForStaffAccount(retry_count,class_instance)}.bind(class_instance), 100);
          return;
        };
        ;
      },

      callForPaymentVerification() {
        const CI = this;

        CI.DV_chargingCardStatus="processing";
        CI.authenticationCheck(0);

        setTimeout( function() {
          CI.$refs.accountSetupPaymentDetailsComponent.verifyCard();
        }.bind(CI), 2000);
      },

      createUserAccount() {
        const CI = this;

        window.G_firebase_auth.createUserAccount(
          CI.DV_stepFormData.accountInfo.email.toLowerCase(), 
          CI.DV_stepFormData.accountInfo.password,
          CI.DV_stepFormData.accountInfo.firstName,
          CI.DV_stepFormData.accountInfo.lastName
        )
      },

      createUserFailed(error) {
        const CI = this;

        CI.DV_creatingUserAccountStatus = "error";
        let message = "";
        
        if (error[0].code === 'auth/email-already-in-use') {
          message += "Email address already in use."; 
        }

        if (error[0].code === 'auth/auth/invalid-email') {
          message += "Invalid Email address"; 
        }

        CI.DV_stepErrorData.accountInfo.emailError = message;
        CI.DV_activeStepNumber = CI.DV_stepNumberMapping.locationStep;
      },

      logoutUser() {
        const CI = this;

        CI.DV_isOptionsExpanded = false;
        CI.DV_loggedInFlag = false;
        window.G_firebase_auth.signOut();

      },

      finalStepCreatingSimulator() {
        const CI = this;

        CI.DV_creatingBusinessStatus = "creating";
        CI.DV_creatingLocationStatus = "creating";
        CI.DV_creatingUserAccountStatus= "creating";
        
        let success_message = "Account successfully created! Please take a moment to read the instructions on this page for a smooth experience. Once ready, proceed to your Dashboard."

        if (DV_activeRenderMode < DV_renderModeDesktopNarrow) {
          success_message = "The Synergy sales dashboard is currently only compatible with desktops and laptops. Please login on a different device in order to access your dashboard."
        }

        CI.$awn.success(success_message);        
      }
    }
  }
</script>
