<template>
  <div id="p1s4c1">
    <div class="block">
      <div class="shadow-sm rounded-xl">
        <div class="">

          <p1s4c3PaymentLinkFilters
            :paymentStatusList="C_paymentStatusList"
            @event-search-filter-selected="setSearchFilter"
            @event-payment-status-filter-selected="setPaymentStatusFilter"
            @event-entries-per-page-filter-selected="setEntriesPerPageFilter"
            @event-start-date-filter-selected="setStartDateFilter"
            @event-end-date-filter-selected="setEndDateFilter"
            @event-quick-date-filter-selected="setQuickDateFilter"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import p1s4c3PaymentLinkFilters from "./p1s4c3PaymentLinkFilters.vue";

export default {
  name: "p1s4c1Filters",

  components: { p1s4c3PaymentLinkFilters },

  props: ["paymentLinks"],

  data() {
    return {};
  },

  mounted() {},

  computed: {
    C_paymentStatusList() {
      let statusList = ["All"]

      for (let paymentLink in this.paymentLinks) {
        let doc_data = this.paymentLinks[paymentLink]

        if (doc_data.payment_status && doc_data.payment_status.trim()) { statusList.push(doc_data.payment_status) }
      }

      return _.uniq(statusList)
    },
  },

  watch: {},

  methods: {
    setTransactionTypeFilter(filter) {
      this.$emit( "event-transaction-type-filter-selected", filter );
    },

    setSearchFilter(filter) {
      this.$emit( "event-search-filter-selected", filter );
    },
    
    setEntriesPerPageFilter(filter) {
      this.$emit( "event-entries-per-page-filter-selected", filter );
    },
    
    setPaymentStatusFilter(filter) {
      this.$emit( "event-payment-status-filter-selected", filter );
    },
    
    setCardTypeFilter(filter) {
      this.$emit( "event-card-type-filter-selected", filter );
    },

    setStartDateFilter(filter) {
      this.$emit( "event-start-date-filter-selected", filter);
    },
    
    setEndDateFilter(filter) {
      this.$emit( "event-end-date-filter-selected", filter);
    },
    
    setQuickDateFilter(filter) {
      this.$emit( "event-quick-date-filter-selected", filter)
    },
  }
};
</script>
