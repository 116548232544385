<template>
  <div id="c2" class="border border-gray-400 rounded-b-xl p-2 h-4/5" :class="{'h-[65%]': DV_activeRenderMode == DV_renderModeDesktopNarrow}">
    <div v-show="DV_loading" class="my-32">
      <h2 class="text-xl text-gray-400">Please wait. Connecting...</h2>
      <svg class="h-12 mt-2 text-gray-400 spin-anticlock mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
      </svg>
    </div>

    <div v-show="!DV_loading" class="h-full">
      <!-- New tab full screen button -->
      <div v-if="DV_showViewType == 'profile'" class="flex justify-end">
        <svg @click="openFullScreen" v-show="DV_fullScreenButton" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"/>
        </svg>
      </div>
  
      <!-- Heading -->
      <h1 v-if="DV_showViewType == 'profile'" class="font-bold text-center pb-5 text-xl">
        Customer Profile
      </h1>
  
      <!-- Body -->
      <div class="relative h-full">
  
        <!-- Tab buttons -->
        <ul v-if="DV_showViewType == 'profile'" class="flex flex-wrap hidden">
          <li class="">
            <a @click.stop="DV_customerProfileView = 'vehicles'" :class="{ active: DV_customerProfileView == 'vehicles' }" class="filter-button rounded-t-lg">
              Vehicles
            </a>
          </li>
          <li class="">
            <a @click.stop="DV_customerProfileView = 'conversation-history'" :class="{ active: DV_customerProfileView == 'conversation-history' }" class="filter-button rounded-t-lg">
              Conversation History
            </a>
          </li>
          <li class="">
            <a @click.stop="DV_customerProfileView = 'information'" :class="{ active: DV_customerProfileView == 'information' }" class="filter-button rounded-t-lg">
              Customer Information
            </a>
          </li>
        </ul>
  
        <!-- Success message on save -->
        <div :id="C_consumerUid +'-success-message'" class="flex hidden absolute top-[-32px]">
          <span class="text-green-700 ml-2">Information Saved</span>
        </div>
  
        <!-- Customer profile -->
        <div v-if=" DV_showViewType == 'profile' || DV_showViewType == 'editView' || DV_showViewType == 'newCustomer'" class="border border-gray-400 rounded-b-xl p-2 h-full overflow-scroll hide-scrollbar">

          <!-- Personal details -->
          <div class="grid lg:grid-cols-4 md:grid-cols-5 grid-cols-4 gap-2">

            <!-- Customer Info -->
            <div class="lg:col-span-1 md:col-span-2 col-span-4">

              <!-- Customer Verification -->
              <span v-show="DV_loadedFrom != 'chat'" class="block mb-2 text-left">
                <img v-show="C_identifyVerificationLogo != ''" :src="C_identifyVerificationLogo" class="w-10 inline"/>
                <span v-show="C_statusText != ''" class="ml-2 font-bold">{{ C_statusText }}</span>
                <button 
                  v-show="DV_viewVerificationImages == false && C_identifyVerificationLogo != ''" 
                  @click="DV_viewVerificationImages = true" 
                  :disabled="DV_verificationFileLinks.length === 0"
                  class="ml-2 btn btn-lime w-auto chat-action-button"
                >
                  Verification Documents
                </button>
              </span>

              <div class="w-full mx-auto items-center md:justify-end justify-start mb-2">
                <label class="text-gray-700 block font-medium text-left ml-1 text-xs md:pr-1 pr-2" for="first_name">
                  First Name
                </label>
                <input autocomplete="off" id="first_name" type="text" name="first_name" v-model="DV_unsavedCustomerProfile.firstName" class="profile-textarea focus:shadow-outline" @input="DV_firstNameError = ''"
                />
                <span v-if="DV_firstNameError !== ''" class="text-left text-sm text-red-400">{{ DV_firstNameError }}</span>
              </div>
              <div class="w-full mx-auto items-center md:justify-end justify-start mb-2">
                <label class="text-gray-700 font-medium text-left ml-1 block text-xs md:pr-1 pr-2" for="last_name">
                  Last Name
                </label>
                <input autocomplete="off" id="last_name" type="text" name="last_name" v-model="DV_unsavedCustomerProfile.lastName" class="profile-textarea focus:shadow-outline"/>
              </div>
              <div class="w-full mx-auto items-center md:justify-end justify-start mb-2">
                <label class="text-gray-700 font-medium text-left ml-1 block text-xs md:pr-1 pr-9" for="email">
                  Email
                </label>
                <input autocomplete="off" id="email" type="email" name="email" v-model="DV_unsavedCustomerProfile.email" class="profile-textarea focus:shadow-outline w-full"/>
              </div>
              <div class="w-full mx-auto items-center md:justify-end justify-start mb-2">
                <label class="text-gray-700 font-medium text-left ml-1 block text-xs md:pr-1 pr-5" for="business">
                  Business
                </label>
                
                <div class="italic text-left text-sm font-bold">{{ DV_lodash.map(this.DV_customerProfile.associatedBusinesses,'name').join(",") || "No existing business affiliations"}}</div>
                <button @click.stop="showAddBusinessModal" class="w-full btn btn-lime text-sm px-2">Manage Customer's<br> Business Associations</button>
              </div>
            </div>
  
            <!-- Contact details -->
            <div class="md:col-span-3 col-span-4 border border-gray-400 rounded-xl p-2">
              <div class="grid md:grid-cols-5 grid-cols-4 items-center">
                <h1 class="md:col-span-3 col-span-2 font-medium text-lg md:text-right text-left">
                  Phone Numbers
                </h1>
                <div v-if="DV_unsavedLandlineNumbers.length < 2" class="col-span-2 text-right">
                  <button @click="addNewNumber" class="btn btn-lime text-sm px-2 mb-2">
                    Add Phone Number
                  </button>
                </div>
              </div>
              
              <!-- Phone numbers list -->
              <div class="flex justify-start mr-2 mt-2 flex-wrap">
                
                <!-- Primary Phone number -->
                <div style="width: 30%;" class="mr-2 mt-2 col-span-2 border border-gray-400 rounded-xl p-1 relative">
                  <div class="absolute right-0 mr-1 mt-1">
                    <!-- Required -->
                    <span class="text-2xl">*</span>
                  </div>
                  <div class="flex flex-col">
                      <div class="w-full mt-2 mb-1 text-left mr-2">
                        <label class="text-gray-700 font-medium text-left text-xs mb-2 w-full" for="number">
                          Texting Phone Number *
                        </label>
                        <input autocomplete="off" v-model="DV_unsavedPrimaryPhoneNumber.phone_number" id="number" type="number" name="number" class="profile-textarea focus:shadow-outline" @input="DV_primaryPhoneNumberError = ''"/>
                        <span v-if="DV_primaryPhoneNumberError !== ''" class="text-sm text-red-400">{{ DV_primaryPhoneNumberError }}</span>
                      </div>
                      <div class="w-full mt-2 mb-2 text-left">
                        <label class="text-gray-700 font-medium text-left text-xs mb-2 w-full" for="description">
                          Description
                        </label>
                        <input autocomplete="off" v-model="DV_unsavedPrimaryPhoneNumber.description" id="description" type="text" name="description" class="w-full profile-textarea focus:shadow-outline"/>
                      </div>
                    </div>
                </div>

                <!-- Landline phone numbers -->
                <div style="width: 50%;" :style="{'margin-left': loop1 === 0 ? '' : 'calc(15% + 0.5rem)'}" v-for="(number, loop1) in DV_unsavedLandlineNumbers" :key="loop1" class="mr-2 mt-2 col-span-2 border border-gray-400 rounded-xl p-1 relative">
                  <div class="absolute right-0 mr-1 mt-1">
                    <svg v-if="DV_unsavedLandlineNumbers[0].index != loop1" @click="removeNumber(loop1)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"/>
                    </svg>
                    <span v-else class="text-2xl">*</span>
                  </div>
                  <div class="flex flex-col">
                    <div class="flex flex-row items-center justify-between">
                      <div class="w-full mt-2 mb-1 text-left mr-2">
                        <label class="text-gray-700 font-medium text-left text-xs mb-2 w-full" for="number">
                          {{loop1 === 0 ? 'Secondary' : 'Third'}} Phone Number
                        </label>
                        <input autocomplete="off" v-model="number.phone_number" id="number" type="number" name="number" class="profile-textarea focus:shadow-outline"
                        @input="number.phone_number_error = ''"
                        />
                        <span v-if="number.phone_number_error !== ''" class="text-sm text-red-400">{{ number.phone_number_error }}</span>
                      </div>
                      <div class="w-full mt-2 mb-1 text-left">
                        <label class="text-gray-700 font-medium text-left text-xs mb-2 w-full" for="number">
                          Type
                        </label>
                        <select v-model="number.phone_type" id="state" name="state" class="w-full rounded border shadow-sm py-1 pl-2 pr-7 focus:border-gray-400 focus:outline-none sm:text-sm enabled:hover:border-gray-400">
                          <option value="1">Cell Phone</option>
                          <option value="2">Land Line</option>
                        </select>
                      </div>
                    </div>
                    <div class="flex flex-row items-center justify-between">
                      <div class="w-full mt-2 mb-1 mr-2 text-left">
                        <label class="text-gray-700 font-medium text-left text-xs mb-2 w-full" for="number">
                          Extension
                        </label>
                        <input autocomplete="off" v-model="number.extension" id="number" type="number" name="number" class="profile-textarea focus:shadow-outline"/>
                      </div>
                      <div class="w-full mt-2 mb-2 text-left">
                        <label class="text-gray-700 font-medium text-left text-xs mb-2 w-full" for="description">
                          Description
                        </label>
                        <input autocomplete="off" v-model="number.description" id="description" type="text" name="description" class="w-full profile-textarea focus:shadow-outline"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Customer type options -->
          <div class="flex my-4 items-center">
            <h1 class="text-gray-700 font-medium text-left text-xs">Customer Type</h1>
            <span class="flex justify-between ml-4 text-xs w-4/5">
              <span class="flex items-center ml-2">
                <input autocomplete="off" type="checkbox" id="body-shop-type" value="Body Shop" v-model="DV_unsavedCustomerTypes" class="cursor-pointer">
                <label for="body-shop-type" class="ml-2 cursor-pointer">Body Shop</label>
              </span>

              <span class="flex items-center ml-3">
                <input autocomplete="off" type="checkbox" id="exporter-type" value="Exporter" v-model="DV_unsavedCustomerTypes" class="cursor-pointer">
                <label for="exporter-type" class="ml-2 cursor-pointer">Exporter</label>
              </span>

              <span class="flex items-center ml-3">
                <input autocomplete="off" type="checkbox" id="insurance-adjuster-type" value="Insurance Adjuster" name="insurance-adjuster" v-model="DV_unsavedCustomerTypes" class="cursor-pointer">
                <label for="insurance-adjuster-type" class="ml-2 cursor-pointer">Insurance Adjuster</label>
              </span>

              <span class="flex items-center ml-3">
                <input autocomplete="off" type="checkbox" id="mechanic-type" value="Mechanic" v-model="DV_unsavedCustomerTypes" class="cursor-pointer">
                <label for="mechanic-type" class="ml-2 cursor-pointer">Mechanic</label>
              </span>

              <span class="flex items-center ml-3">
                <input autocomplete="off" type="checkbox" id="reseller-type" value="Reseller" v-model="DV_unsavedCustomerTypes" class="cursor-pointer">
                <label for="reseller-type" class="ml-2 cursor-pointer">Reseller</label>
              </span>

              <span class="flex items-center ml-3">
                <input autocomplete="off" type="checkbox" id="retail-type" value="Retail" v-model="DV_unsavedCustomerTypes" class="cursor-pointer">
                <label for="retail-type" class="ml-2 cursor-pointer">Retail</label>
              </span>
            </span>
          </div>
  
          <!-- Social Media Info
          <div class="w-full mx-auto items-center justify-start my-3">
            <label class="text-gray-700 font-medium block ml-1 text-left text-xs md:pr-1 pr-2" for="facebook_url">
              Facebook URL
            </label>
            <input autocomplete="off" v-model="DV_unsavedCustomerProfile.facebookProfileUrl" id="facebook_url" type="text" name="facebook_url" class="md:w-6/12 w-8/12 profile-textarea focus:shadow-outline text-left"/>
          </div>
          -->
          
          <!-- Billing/Customer Address details -->
          <div>
            <span v-if="C_businessType == 1" class="flex text-xl font-bold">Billing Address:</span>
            <span v-else-if="C_businessType == 2" class="flex text-xl font-bold">Customer Address 1:</span>

            <div class="flex w-full mt-2">
              <div class="w-1/3 mx-auto items-center justify-start mb-2">
                <label class="text-gray-700 font-medium block ml-1 text-left text-xs md:pr-0.5 pr-1.5" for="address_line1">
                  Address Line 1
                </label>
                <input autocomplete="off" v-model="DV_unsavedCustomerProfile.billingAddressLine1" id="address_line1" type="text" name="address_line1" class="profile-textarea focus:shadow-outline"/>
              </div>

              <div class="w-1/3 mx-auto items-center md:justify-end justify-start mb-2 mx-2">
                <label class="text-gray-700 font-medium block ml-1 text-left text-xs md:px-1 pr-16" for="city">
                  City
                </label>
                <input autocomplete="off" v-model="DV_unsavedCustomerProfile.billingCity" id="c2_city" type="text" name="c2_city" class="profile-textarea focus:shadow-outline"/>
              </div>

              <div class="w-1/3 mx-auto items-center md:justify-end justify-start mb-2">
                <label class="text-gray-700 font-medium block ml-1 text-left text-xs md:pr-1 pr-14" for="state">
                  State
                </label>
                <select v-model="DV_unsavedCustomerProfile.billingState" id="state" name="state" class="w-full rounded border shadow-sm py-1 pl-2 pr-7 focus:border-gray-400 focus:outline-none sm:text-sm enabled:hover:border-gray-400">
                  <option v-for="state in DV_statesList">
                    {{ state }}
                  </option>
                </select>
              </div>
            </div>

            <div class="flex w-2/3">
              <div class="w-1/2 items-center justify-start">
                <label class="text-gray-700 font-medium block ml-1 text-left text-xs md:pr-0.5 pr-1.5" for="address_line2">
                  Address Line 2
                </label>
                <input autocomplete="off" v-model="DV_unsavedCustomerProfile.billingAddressLine2" id="c2_address_line2" type="text" name="address_line2" class="profile-textarea focus:shadow-outline"/>
              </div>
              
              <div class="w-1/2 ml-2 items-center md:justify-end justify-start mb-2">
                <label class="text-gray-700 font-medium block ml-1 text-left text-xs md:pr-1 pr-8" for="zip_code">
                  Zip Code
                </label>
                <input autocomplete="off" id="zip_code" type="text" name="zip_code" v-model="DV_unsavedCustomerProfile.billingZipCode" class="profile-textarea focus:shadow-outline"/>
              </div>
            </div>
          </div>

          <!-- Shipping Address details -->
          <div>
            <span v-if="C_businessType == 1" class="flex text-xl font-bold">Shipping Address:</span>
            <span v-if="C_businessType == 2" class="flex text-xl font-bold">Customer Address 2:</span>
            
            <div class="flex w-full mt-2">
              <div class="w-1/3 mx-auto items-center justify-start mb-2">
                <label class="text-gray-700 font-medium block ml-1 text-left text-xs md:pr-0.5 pr-1.5" for="address_line1">
                  Address Line 1
                </label>
                <input autocomplete="off" v-model="DV_unsavedCustomerProfile.shippingAddressLine1" id="address_line1" type="text" name="address_line1" class="profile-textarea focus:shadow-outline"/>
              </div>

              <div class="w-1/3 mx-auto items-center md:justify-end justify-start mb-2 mx-2">
                <label class="text-gray-700 font-medium block ml-1 text-left text-xs md:px-1 pr-16" for="city">
                  City
                </label>
                <input autocomplete="off" v-model="DV_unsavedCustomerProfile.shippingCity" id="c2_city" type="text" name="c2_city" class="profile-textarea focus:shadow-outline"/>
              </div>

              <div class="w-1/3 mx-auto items-center md:justify-end justify-start mb-2">
                <label class="text-gray-700 font-medium block ml-1 text-left text-xs md:pr-1 pr-14" for="state">
                  State
                </label>
                <select v-model="DV_unsavedCustomerProfile.shippingState" id="state" name="state" class="w-full rounded border shadow-sm py-1 pl-2 pr-7 focus:border-gray-400 focus:outline-none sm:text-sm enabled:hover:border-gray-400">
                  <option v-for="state in DV_statesList">
                    {{ state }}
                  </option>
                </select>
              </div>
            </div>

            <div class="flex w-2/3">
              <div class="w-1/2 items-center justify-start">
                <label class="text-gray-700 font-medium block ml-1 text-left text-xs md:pr-0.5 pr-1.5" for="address_line2">
                  Address Line 2
                </label>
                <input autocomplete="off" v-model="DV_unsavedCustomerProfile.shippingAddressLine2" id="c2_address_line2" type="text" name="address_line2" class="profile-textarea focus:shadow-outline"/>
              </div>
              
              <div class="w-1/2 ml-2 items-center md:justify-end justify-start mb-2">
                <label class="text-gray-700 font-medium block ml-1 text-left text-xs md:pr-1 pr-8" for="zip_code">
                  Zip Code
                </label>
                <input autocomplete="off" id="zip_code" type="text" name="zip_code" v-model="DV_unsavedCustomerProfile.shippingZipCode" class="profile-textarea focus:shadow-outline"/>
              </div>
            </div>
          </div>

          <div v-show="C_isManagerOrAdmin" class="text-left mt-2">
            Assign Yard Staff Member
            <span v-show="C_assignedEmployeeName.length > 0" class="block ml-2 my-1 italic text-left text-sm font-bold w-72 truncate">
              {{ C_assignedEmployeeName }}
              <a class="cursor-pointer text-red-900 ml-4" @click="DV_customerProfile.assigned_employee=''">Remove Staff Member</a>
            </span>
            <select class="cursor-pointer w-72" v-model="DV_customerProfile.assigned_employee">
              <option value="" disabled selected hidden>Select an Employee to Assign</option>
              <option v-for="[key, value] in Object.entries(C_employeesList)" :value="value.employee_uid" v-show="!value.is_deactivated">{{ value.first_name}} {{value.last_name}}</option>
             </select>
          </div> 
          
          <!-- Buttons -->
          <span class="flex justify-end items-center">
            <span v-if="DV_saveSuccessFeedback !== ''"  class="lg:pr-10 lg:mr-10 md:pr-2 text-left font-bold text-green-400 mr-5"> {{ DV_saveSuccessFeedback }}</span>
            <span v-if="DV_saveFailureFeedback !== ''" class=" lg:pr-5 md:pr-2 text-left font-bold text-red-400 mr-5"> {{ DV_saveFailureFeedback }}</span>
            <span class="flex flex-row">
              <button 
                v-if="DV_showViewType === 'editView'" 
                :disabled="DV_creatingNewCustomer"
                @click.stop="saveCustomerProfile" 
                class="btn btn-lime text-sm mr-4">{{DV_creatingNewCustomer ? 'Saving...' : 'Save Changes' }}
              </button>

              <button 
                v-if="DV_showViewType === 'newCustomer'" 
                :disabled="DV_creatingNewCustomer"
                @click.stop="createCustomerProfile" 
                class="btn btn-lime text-sm mr-4">{{DV_creatingNewCustomer ? 'Creating...' : 'Create New Customer'}}
              </button>

              <button @click.stop="discardCustomerProfile" class="btn btn-lime text-sm">Discard Changes</button>
            </span>
          </span>
  
        </div>
      </div>
  
      <c11ImageViewer 
        v-if="DV_viewVerificationImages == true"
        :P_imageLinks="DV_verificationFileLinks"
        @close="DV_viewVerificationImages = false"
      />
      <PopupModal ref="addBusinessModal" :P_showClose="true" @close-modal="closeAddBusinessModal" :P_largeModal="true" :P_modalClasses="P_isModal ? 'absolute left-[12.5%] h-full -top-[30px] !w-[80%]' : ''">
        <div slot="title"> 
          <h3 class="font-bold mb-8">Customer-To-Business Association Selection</h3> 
        </div>
  
        <template slot="body">
          <!-- Associated Businesses -->
          <h2 v-if="DV_customerProfile.associatedBusinesses.length > 0" class="font-bold text-left mb-2">Associated Businesses</h2> 
          <div v-if="DV_customerProfile.associatedBusinesses.length > 0" class="block mb-8 px-5">
            <div v-for="buss in DV_customerProfile.associatedBusinesses" class="flex w-full flex items-center justify-between">
              <div class="w-1/2 truncate">{{buss.name}}</div>
              <div class="w-1/2 text-left text-red-900">
                <button class="h-6 cursor-pointer" @click.stop="removeBusinessAssociation(buss)">Remove Association</button>
              </div>
            </div>
          </div>

          <!-- Choose an Existing business -->
          <h2 class="font-bold text-center mb-2">Choose an Existing Business</h2> 
          <div class="block mb-8">
            <div class="flex w-full flex-row items-center justify-between">
              <div class="flex w-full px-5">
                <select
                  id="business"
                  name="business"
                  class="dropdown-select w-3/5 mx-auto"
                  v-model="DV_selectedConsumerBusinessUid"
                >
                  <option v-for="buss in C_businessesList" :value="buss.id" :key="buss.id">
                    {{ buss.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="flex items-center justify-center mt-4 w-full">
              <button :disabled="DV_addingBusinessToProfile" @click.stop="addBusinessToProfile(null, true)" class="btn btn-lime text-sm mr-3">{{DV_addingBusinessToProfile ? 'Associating...' : 'Associate Customer With Existing Business' }}</button>
              <button :disabled="DV_addingBusinessToProfile" @click.stop="closeAddBusinessModal" class="w-24 btn btn-lime text-sm">Cancel</button>
            </div>
          </div>          

          <!-- Create a New Business -->
          <h2 class="font-bold text-center mb-2">Or Create a New Business</h2> 
          <div class="flex flex-col items-center justify-center w-full">
            <c9EditNewBusinessForm
              class="w-3/5 mx-auto"
              :P_businessFormType="'newBusiness'"
              :P_showHeader="false"
              :P_consumerUid="C_consumerUid"
              :P_newBusinessButtonTitle="'Associate Customer With New Business'"
              :P_isModal="true"
              @new-business-created="newBusinessCreated"
              @event-back="closeAddBusinessModal(false)"
            />
          </div>
        </template>
      </PopupModal>
    </div>
  </div>
</template>

<script>
import AWN from "awesome-notifications";
import dataValidator from "../../includes/bi1Datavalidation"
import { CR, RC } from "../../includes/bi2SuccessCodes"
import PopupModal from "../shared/PopupModal"
import createConsumer from "../../includes/server_calls/p1s3/p1s3e26";
import retrieveIdentityVerificationDocuments from "../../includes/server_calls/p1s7/p1s7e6";
import c9EditNewBusinessForm from "../c1/c9EditNewBusinessForm.vue";
import { fs4Writer } from "../../includes/firestore_writers/fs4_writer.ts"
import { fs37Writer } from "../../includes/firestore_writers/fs37_writer"
import _ from "lodash"
import m2MiscDataFetchers from "../../mixins/m2MiscDataFetchers"
import m3RenderingSettings from '../../mixins/m3RenderingSettings.js';
import c11ImageViewer from "./c11ImageViewer.vue";
import moment from "moment";

export default {
  name: "c2CustomerProfile",

  props: ["P_consumerUid", "P_viewType", "P_isModal", "P_loadedFrom"],

  components: { PopupModal, c9EditNewBusinessForm, c11ImageViewer },

  mixins: [ m2MiscDataFetchers, m3RenderingSettings ],

  data() {
    return {
      DV_lodash: _,
      DV_loading: false,
      DV_saveSuccessFeedback: "",
      DV_saveFailureFeedback: "",
      DV_customerData: {},
      DV_customerDataIndex: {},
      DV_showViewType: "profile",
      DV_fullScreenButton: true,
      DV_customerProfileView: "information",
      DV_firstNameError:  "",
      DV_lastNameError:  "",
      DV_primaryPhoneNumberError: "",
      DV_customerProfile: {
        firstName: "",
        lastName: "",
        email: "",
        billingCity: "",
        billingZipCode: "",
        billingAddressLine1: "",
        billingAddressLine2: "",
        billingState: "",
        shippingCity: "",
        shippingZipCode: "",
        shippingAddressLine1: "",
        shippingAddressLine2: "",
        shippingState: "",
        facebookProfileUrl: "",
        customerTypes: [],
        associatedBusinesses: [],
        assigned_employee: ''
      },
      DV_unsavedCustomerProfile: {
        firstName: "",
        lastName: "",
        email: "",
        billingCity: "",
        billingZipCode: "",
        billingAddressLine1: "",
        billingAddressLine2: "",
        billingState: "",
        shippingCity: "",
        shippingZipCode: "",
        shippingAddressLine1: "",
        shippingAddressLine2: "",
        shippingState: "",
        facebookProfileUrl: "",
        customerTypes: [],
        associatedBusinesses: [],
      },
      DV_primaryPhoneNumber: {
        phone_type: "1",
        phone_number: "",
        extension: "",
        description: "",
      },
      DV_unsavedPrimaryPhoneNumber: {
        phone_type: "1",
        phone_number: "",
        extension: "",
        description: "",
      },
      DV_landLinePhoneNumbers: [],
      DV_unsavedLandlineNumbers: [],
      DV_unsavedCustomerTypes: [],
      DV_statesList: ["Alabama","Alaska","Arizona","Arkansas","California","Colorado","Connecticut","Delaware","Florida","Georgia","Hawaii","Idaho","Illinois","Indiana","Iowa","Kansas","Kentucky","Louisiana","Maine","Maryland","Massachusetts","Michigan","Minnesota","Mississippi","Missouri","Montana","Nebraska","Nevada","New Hampshire","New Jersey","New Mexico","New York","North Carolina","North Dakota","Ohio","Oklahoma","Oregon","Pennsylvania","Rhode Island","South Carolina","South Dakota","Tennessee","Texas","Utah","Vermont","Virginia","Washington","West Virginia","Wisconsin","Wyoming"],
      DV_selectedConsumerBusinessUid: -1,
      DV_newBusinessCreatedFlag: null,
      DV_addingBusinessToProfile: false,
      DV_creatingNewCustomer: false,
      DV_consumerBusinesses: {},
      DV_consumerBusinessJoins: {},
      DV_consumerContacts: window.G_bi2_data.IV_consumer_contacts.value,
      DV_loadedFrom: "",
      DV_verificationFileLinks: [],
      DV_previousConsumerUID: "",
      DV_viewVerificationImages: false,
      DV_businesses: window.G_bi2_data.IV_businesses,
    };
  },

  mounted() {
    if (window.location.pathname == "/customerProfile") {
      this.DV_fullScreenButton = false;
    }

    this.DV_showViewType = this.P_viewType || "profile";
    this.DV_loadedFrom = this.P_loadedFrom || "";
    this.DV_customerDataIndex = window.G_bi2_data.IV_al_consumer_contacts;
    this.DV_consumerBusinesses = window.G_bi2_data.IV_consumer_businesses.value;
    this.DV_consumerBusinessJoins = window.G_bi2_data.IV_consumer_business_joins.value;

    if (this.P_viewType === "newCustomer") { return; }

    if (this.C_consumerUid !== undefined && this.C_consumerUid !== null ) {
      this.loadCustomerData(0, this);
    } else {
      console.log("c2CustomerProfile prop input data error")
      //TODO add call to p1s7e1
    }
  },

  computed: {
    C_businessType() {
      if (Object.entries(this.DV_businesses).length == 0) { return 0; }
      
      return this.DV_businesses[window.G_bi2_data.IV_active_employee_info.business_uid].business_type;
    },

    C_consumerUid() {
      let consumerUid = this.P_consumerUid || "";

      if (this.$route.name == "customerProfile" && this.$route.query["consumerUid"]) {
        this.DV_showViewType = "editView";
        consumerUid = this.$route.query["consumerUid"];
      }

      return consumerUid;
    },

    C_businessesList() {
      let businesses = [{name: "Choose Business", id: -1}];

      for (let consumer_business_uid in this.DV_consumerBusinesses) {
        let consumer_business = this.DV_consumerBusinesses[consumer_business_uid]
        businesses.push({
          name: consumer_business.name,
          id: consumer_business_uid
        })
      }

      return businesses;
    },

    C_identifyVerificationLogo() {
      let logo = ""

      if (this.C_consumerUid in this.DV_consumerContacts) {
        let details = this.DV_consumerContacts[this.C_consumerUid][window.G_bi2_data.IV_al_consumer_contacts.verified_outputs]

        if (Object.keys(details).length == 0 || details.matched_profile == undefined) {return logo}

        if (details.matched_profile === 2) {
          logo = require('../../assets/images/conversation_status_icons/identity_verified.png')
        } else if (details.matched_profile === 1) {
          logo = require('../../assets/images/conversation_status_icons/identity_failed.png')
        }
      }

      return logo
    },

    C_statusText() {
      let statusText = ''
      this.DV_viewVerificationImages = false;

      if (this.C_consumerUid in this.DV_consumerContacts) {
        let details = this.DV_consumerContacts[this.C_consumerUid][window.G_bi2_data.IV_al_consumer_contacts.verified_outputs]
        
        if (Object.keys(details).length == 0 || details.matched_profile == undefined || details.time_verified == undefined) {return statusText}

        if (details.matched_profile === 2) {
          this.retrieveVerificationDocuments();
          const currentDate = moment();
          const anotherDate = moment.unix(details.time_verified.seconds);
          statusText = currentDate.diff(anotherDate, 'days');

          if (statusText == 0) {
            statusText = `Verified on ${anotherDate.format('MM-DD-YYYY')}`
          } else {
            statusText = `Verified ${ statusText } ${ statusText > 1 ? 'Days' : 'Day' } Ago`
          }
        } else if (details.matched_profile === 1) {
          this.retrieveVerificationDocuments();
          statusText = 'Verification Failed'
        }
      }

      return statusText
    },

    C_assignedEmployeeName() {
      let assignedEmployeesName = "";

      if (Object.keys(window.G_bi2_data.IV_recycler_employees.value).includes(this.DV_customerProfile.assigned_employee)) {
        let employeeData = window.G_bi2_data.IV_recycler_employees.value[this.DV_customerProfile.assigned_employee]
        assignedEmployeesName += employeeData.first_name + " " + employeeData.last_name
      }

      return assignedEmployeesName;
    },

    C_isManagerOrAdmin() {
      return  window.G_bi2_data.IV_active_employee_info.permissions.manager || window.G_bi2_data.IV_active_employee_info.permissions.admin
    },

    C_employeesList() {
      return window.G_bi2_data.IV_recycler_employees.value;
    }
  },

  methods: {
    loadCustomerData(retry_count = 0, class_instance) {
      const CI = class_instance;
      CI.DV_loading = true;

      if (retry_count === 0) {
        window.G_bi2_data.startConsumerContactListener(
          window.G_bi2_data,
          window.G_bi2_data.IV_active_employee_info.business_uid,
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          CI.C_consumerUid
        );
      }
      
      if (retry_count >= 25) {
        console.error("c2CustomerProfile:loadCustomerData loading data error")
      }
      
      if (CI.C_consumerUid in window.G_bi2_data.IV_consumer_contacts.value === false) {
        setTimeout(function() {CI.loadCustomerData(++retry_count,class_instance)}, 200);
        return;
      }
      
      CI.DV_showViewType = "editView";
      CI.DV_loading = false;
      CI.DV_customerData = window.G_bi2_data.IV_consumer_contacts.value[CI.C_consumerUid];
      const index = CI.DV_customerDataIndex;

      // Setting profile data
      CI.DV_customerProfile.firstName = CI.DV_customerData[index.first_name];
      CI.DV_customerProfile.lastName = CI.DV_customerData[index.last_name];
      CI.DV_customerProfile.email = CI.DV_customerData[index.email];
      CI.DV_customerProfile.allBusinessesList = CI.businessesList
      CI.DV_customerProfile.billingCity = CI.DV_customerData[index.address_info_city_2d[0]][index.address_info_city_2d[1]];
      CI.DV_customerProfile.billingZipCode = CI.DV_customerData[index.address_info_postal_code_2d[0]][index.address_info_postal_code_2d[1]];
      CI.DV_customerProfile.billingState = CI.DV_customerData[index.address_info_state_2d[0]][index.address_info_state_2d[1]];
      CI.DV_customerProfile.billingAddressLine1 = CI.DV_customerData[index.address_info_address_line_1_2d[0]][index.address_info_address_line_1_2d[1]];
      CI.DV_customerProfile.billingAddressLine2 = CI.DV_customerData[index.address_info_address_line_2_2d[0]][index.address_info_address_line_2_2d[1]];
      CI.DV_customerProfile.facebookProfileUrl = CI.DV_customerData[index.facebook_profile_url];
      CI.DV_customerProfile.customerTypes = CI.DV_customerData[index.customer_types] || [];
      CI.DV_customerProfile.assigned_employee = CI.DV_customerData[index.assigned_employees][0] || "";
      CI.DV_customerProfile.shippingCity = CI.DV_customerData[index.shipping_address_info_city_2d[0]][index.shipping_address_info_city_2d[1]];
      CI.DV_customerProfile.shippingZipCode = CI.DV_customerData[index.shipping_address_info_postal_code_2d[0]][index.shipping_address_info_postal_code_2d[1]];
      CI.DV_customerProfile.shippingState = CI.DV_customerData[index.shipping_address_info_state_2d[0]][index.shipping_address_info_state_2d[1]];
      CI.DV_customerProfile.shippingAddressLine1 = CI.DV_customerData[index.shipping_address_info_address_line_1_2d[0]][index.shipping_address_info_address_line_1_2d[1]];
      CI.DV_customerProfile.shippingAddressLine2 = CI.DV_customerData[index.shipping_address_info_address_line_2_2d[0]][index.shipping_address_info_address_line_2_2d[1]];

      let associatedBusinesses = []
        
      // Setting associated businesses
      if(CI.C_consumerUid in CI.DV_consumerBusinessJoins) {
        for (let array_index in CI.DV_consumerBusinessJoins[CI.C_consumerUid]) {
          let business_uid = CI.DV_consumerBusinessJoins[CI.C_consumerUid][array_index];
          const businessInfo = CI.DV_consumerBusinesses[business_uid];
          if (!businessInfo.name) { continue; };
            associatedBusinesses.push({id: business_uid, name: businessInfo.name});          
        }
        CI.DV_customerProfile.associatedBusinesses = associatedBusinesses;
      }

      // Setting primary phone
      CI.DV_primaryPhoneNumber = {
        phone_type: CI.DV_customerData[index.primary_phone_number].type || "1",
        phone_number: CI.DV_customerData[index.primary_phone_number].phone_number || "",
        extension: CI.DV_customerData[index.primary_phone_number].extension || "",
        description: CI.DV_customerData[index.primary_phone_number].description || ""
      };

      // Setting landline numbers
      CI.DV_landLinePhoneNumbers = [];
      if (CI.DV_customerData[index.landline_numbers] && Object.keys(CI.DV_customerData[index.landline_numbers]).length > 0) {
        for (let number in CI.DV_customerData[index.landline_numbers]) {
          const numberInfo = CI.DV_customerData[index.landline_numbers][number];

          CI.DV_landLinePhoneNumbers.push({
            uuid: CI.uuidv4(),
            phone_type: numberInfo.type || "2",
            phone_number: numberInfo.phone_number || "",
            extension: numberInfo.extension || "",
            description: numberInfo.description || ""
          })
        }
      }

      // Setting unsaved customer types
      CI.DV_unsavedCustomerTypes = Object.assign([], CI.DV_customerProfile.customerTypes)
      CI.DV_unsavedPrimaryPhoneNumber = Object.assign({}, CI.DV_primaryPhoneNumber)
      CI.DV_unsavedCustomerProfile = Object.assign({}, CI.DV_customerProfile)
      CI.DV_unsavedLandlineNumbers = Object.assign([], CI.DV_landLinePhoneNumbers)

      return;
    },

    addNewNumber() {
      const CI = this;

      if (CI.DV_unsavedLandlineNumbers.length >= 2) { return; }

      CI.DV_unsavedLandlineNumbers.push({
        uuid: CI.uuidv4(),
        phone_type: "2",
        phone_number: "",
        extension: "",
        description: "",
        phone_number_error: "",
      });
    },

    removeNumber(uuid) {
      let itemIndex = this.DV_unsavedLandlineNumbers.findIndex(
        (number) => number.uuid == uuid
      );
      this.DV_unsavedLandlineNumbers.splice(itemIndex, 1);
      this.$forceUpdate();
    },

    openFullScreen() {
      let routeData = this.$router.resolve({ path: `/customerProfile?consumerUid=${this.C_consumerUid}` });
      window.open(routeData.href, "_blank");
    },

    showAddBusinessModal() {
      this.$refs.addBusinessModal.show();
    },

    closeAddBusinessModal(reset_selection=true) {
      if (reset_selection) { 
        this.DV_selectedConsumerBusinessUid = -1; 
        this.DV_newBusinessCreatedFlag = null;
      }
      this.$refs.addBusinessModal.hide();
    },

    newBusinessCreated(new_fs37_document_id) {
      const CI = this;

      CI.DV_selectedConsumerBusinessUid = new_fs37_document_id;
      CI.DV_newBusinessCreatedFlag = true;
      CI.addBusinessToProfile();
    },

    async addBusinessToProfile(new_consumer_uid=null, show_warning= true) {
      const CI = this;
      const consumer_uid = new_consumer_uid || CI.C_consumerUid;

      CI.DV_addingBusinessToProfile = true;

      if (CI.DV_selectedConsumerBusinessUid == -1) {
        if (show_warning) {
          CI.$awn.warning("No business is selected to associate with customer.");
        }
        CI.DV_addingBusinessToProfile = true;
        return;
      }

      if (consumer_uid) {
        let newBusinessData = {
          consumerContactsUidsList: [consumer_uid]
        }

        // Make this user as primary consumer if it is a new business
        if (CI.DV_newBusinessCreatedFlag == true) {
          newBusinessData["primaryConsumerContactUid"] = consumer_uid
        }

        let response = await fs37Writer(
          "e",
          window.G_bi2_data.IV_active_employee_info.business_uid,
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          CI.DV_selectedConsumerBusinessUid,
          newBusinessData
        )

        if (response.success == false) {
          this.$awn.alert("There was an issue while associating customer with business. Please reload page and try again.");
        } else {
          this.$awn.success("Successfully associated customer with the business.");
          
          // Creating for new customer, go back to list.
          if (new_consumer_uid) {
            CI.$emit("event-back");
          }
          CI.closeAddBusinessModal();
        }
      } else {
        // Business will be associated after the profile creation.
        CI.closeAddBusinessModal(false);
      }

      CI.DV_addingBusinessToProfile = false;
    },

    discardCustomerProfile() {
      const CI = this;
      const index = CI.DV_customerDataIndex;

      CI.DV_unsavedCustomerProfile = {};
      
      if (CI.DV_showViewType == "editView") {

        // Reset profile
        CI.DV_unsavedCustomerProfile = (CI.DV_showViewType == "editView") ? Object.assign({}, CI.DV_customerProfile) : {}

        // Reset primary number
        CI.DV_unsavedPrimaryPhoneNumber = Object.assign({}, CI.DV_primaryPhoneNumber)
        
        // Setting unsaved customer types
        CI.DV_unsavedCustomerTypes = Object.assign([], CI.DV_customerProfile.customerTypes)

        // Resetting landline numbers
        CI.DV_unsavedLandlineNumbers = Object.assign([], CI.DV_landLinePhoneNumbers)
      }
      
      CI.$forceUpdate();
      CI.$emit("event-back");
    },

    resetPhoneNumbers() {

    },

    formValidationCheck() {
      const CI = this;
      let fail_flag = false;
      let call_result  = {};

      // Profile validation
      call_result = dataValidator.is_string(CI.DV_unsavedCustomerProfile.firstName, 0, 30);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_firstNameError = "First name should be less than 30 characters";
        fail_flag = true;
      }

      call_result = dataValidator.is_string(CI.DV_unsavedCustomerProfile.lastName, 0, 30);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_lastNameError = "Last name should be less than 30 characters";
        fail_flag = true;
      }

      if (CI.DV_unsavedCustomerProfile.email !== undefined && CI.DV_unsavedCustomerProfile.email !== null && CI.DV_unsavedCustomerProfile.email !== '') {
        call_result = dataValidator.is_email_address(CI.DV_unsavedCustomerProfile.email);
        if (call_result[CR.success] !== RC.success) {
          CI.DV_EmailError = "Email address is invalid";
          fail_flag = true;
        }
      }

      // Landline numbers validation
      for (let phone_entry_index in CI.DV_unsavedLandlineNumbers)  {
        let entry = CI.DV_unsavedLandlineNumbers[phone_entry_index];
        call_result = dataValidator.is_string(entry.phone_number, 10, 10);
        
        if (call_result[CR.success] !== RC.success) {
          entry.phone_number_error = "Phone number should be 10 digits";
          fail_flag = true;
        }

        call_result = dataValidator.is_us_phone_number(entry.phone_number);
        if (call_result[CR.success] !== RC.success) {
          entry.phone_number_error = "Valid US phone number is required.";
          fail_flag = true;
        }
        // We don't have to check if a landline is already in use because those are not gauranteed unqieu
    
      }

      // Primary number validation
      call_result = dataValidator.is_string(CI.DV_unsavedPrimaryPhoneNumber.phone_type, 1, 1);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_primaryPhoneNumberError = "Primary phone number type is not selected";
        fail_flag = true;
      }

      call_result = dataValidator.is_string(CI.DV_unsavedPrimaryPhoneNumber.phone_number, 10, 10);
      if (call_result[CR.success] !== RC.success) {
        CI.DV_primaryPhoneNumberError = "Primary phone number should be 10 digits";
        fail_flag = true;
      }

      // Check primary number not already in use
      call_result = window.G_bi2_data.findConsumerByPhoneNumber(window.G_bi2_data, CI.DV_unsavedPrimaryPhoneNumber.phone_number)
      if (call_result.consumer_uid && call_result.consumer_uid != CI.C_consumerUid) {
        CI.DV_primaryPhoneNumberError = "Primary phone number is already in use, please choose a different number.";
        fail_flag = true;
      }
    
      if (fail_flag === true) { return false;}

      return true;
    },


    async saveCustomerProfile() {
      const CI = this;
      CI.DV_saveFailureFeedback = "";

      CI.DV_creatingNewCustomer = true;

      if (CI.formValidationCheck() !== true) {
        CI.DV_saveSuccessFeedback = "";
        CI.DV_saveFailureFeedback = "Please correct the required Number field above and try again";
        CI.DV_creatingNewCustomer = false;
        return;
      }

      let new_data = {
        consumer_uid: CI.C_consumerUid,
        first_name: CI.DV_unsavedCustomerProfile.firstName,
        last_name: CI.DV_unsavedCustomerProfile.lastName,
        facebook_profile_url: CI.DV_unsavedCustomerProfile.facebookProfileUrl,
        email: CI.DV_unsavedCustomerProfile.email,
        firebase_account_uid: CI.DV_customerData[CI.DV_customerDataIndex.firestore_uid],
        primary_phone_number: {
          phone_type: CI.DV_unsavedPrimaryPhoneNumber.phone_type || "1",
          phone_number: CI.DV_unsavedPrimaryPhoneNumber.phone_number || "",
          extension: CI.DV_unsavedPrimaryPhoneNumber.extension || "",
          description: CI.DV_unsavedPrimaryPhoneNumber.description || ""
        },
        address_info: {
          city: CI.DV_unsavedCustomerProfile.billingCity || "",
          state: CI.DV_unsavedCustomerProfile.billingState || "",
          postal_code: CI.DV_unsavedCustomerProfile.billingZipCode || "",
          address_line_1: CI.DV_unsavedCustomerProfile.billingAddressLine1 || "",
          address_line_2: CI.DV_unsavedCustomerProfile.billingAddressLine2 || "",
        },
        average_consumer_rating: CI.DV_customerData[CI.DV_customerDataIndex.average_consumer_rating],
        consumer_account_uid: "",
        customer_types: CI.DV_unsavedCustomerTypes,
        average_employee_rating: CI.DV_customerData[CI.DV_customerDataIndex.average_employee_rating],
        landline_phone_numbers: CI.getFormattedLandlineNumbers(),
        assigned_employees: this.DV_customerProfile.assigned_employee.length === 0 ? [] : [this.DV_customerProfile.assigned_employee],
        shipping_address_info: {
          city: CI.DV_unsavedCustomerProfile.shippingCity || "",
          state: CI.DV_unsavedCustomerProfile.shippingState || "",
          postal_code: CI.DV_unsavedCustomerProfile.shippingZipCode || "",
          address_line_1: CI.DV_unsavedCustomerProfile.shippingAddressLine1 || "",
          address_line_2: CI.DV_unsavedCustomerProfile.shippingAddressLine2 || "",
        }
      }

      let resp = await fs4Writer(
        "e",
        window.G_bi2_data.IV_active_employee_info.business_uid,
        window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        this.C_consumerUid,
        new_data
      );
      
      CI.DV_creatingNewCustomer = false;

      if (!resp.success) {
        this.$awn.alert(`${resp.error_title} ${resp.error_next_steps}`);
        CI.DV_creatingNewCustomer = false;
      } else {
        CI.DV_saveSuccessFeedback = "Information Saved!";
        CI.DV_creatingNewCustomer = false;
        
        setTimeout(() => {
          this.DV_saveSuccessFeedback = "";
          this.$emit("event-back");
        }, 1200);
      }
    },

    async createCustomerProfile() {
      const CI = this;
      
      CI.DV_creatingNewCustomer = true;
      CI.DV_saveFailureFeedback = "";

      if (CI.formValidationCheck() !== true) {
        CI.DV_saveSuccessFeedback = "";
        CI.DV_saveFailureFeedback = "Please correct the required Number field above and try again";
        CI.DV_creatingNewCustomer = false;
        return;
      }

      let new_customer_data = {
        recycler_uid: window.G_bi2_data.IV_active_employee_info.business_uid,
        recycler_location_uid: window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
        first_name: CI.DV_unsavedCustomerProfile.firstName,
        last_name: CI.DV_unsavedCustomerProfile.lastName,
        primary_phone_number: CI.DV_unsavedPrimaryPhoneNumber.phone_number,
        landline_numbers: JSON.stringify(CI.getFormattedLandlineNumbers()),
        customer_types: JSON.stringify(CI.DV_unsavedCustomerTypes),
        assigned_employee: this.DV_customerProfile.assigned_employee,
        ...(CI.DV_unsavedCustomerProfile.facebookProfileUrl && { facebook_url: CI.DV_unsavedCustomerProfile.facebookProfileUrl }),
        ...(CI.DV_unsavedCustomerProfile.email && { email: CI.DV_unsavedCustomerProfile.email }),
        ...(CI.DV_unsavedCustomerProfile.city && { city: CI.DV_unsavedCustomerProfile.city }),
        ...(CI.DV_unsavedCustomerProfile.state && { state: CI.DV_unsavedCustomerProfile.state }),
        ...(CI.DV_unsavedCustomerProfile.zipCode && { postal_code: CI.DV_unsavedCustomerProfile.zipCode }),
        ...(CI.DV_unsavedCustomerProfile.addressLine1 && { address_line_1: CI.DV_unsavedCustomerProfile.addressLine1 }),
        ...(CI.DV_unsavedCustomerProfile.addressLine2 && { address_line_2: CI.DV_unsavedCustomerProfile.addressLine2 }),      
      };

      let result = {}
      try {
        result = await createConsumer(new_customer_data);
      } catch(e) {}

      if (result.success === true) {
        CI.DV_saveSuccessFeedback = "New Customer Created!";
        CI.DV_creatingNewCustomer = false;

        // Check and Associate businesss
        if (CI.DV_selectedConsumerBusinessUid == -1) {
          this.$emit("event-back");
        } else {
          CI.addBusinessToProfile(result.response.consumer_uid, false);
        }
        setTimeout(() => {
          this.DV_saveSuccessFeedback = "";
          this.$emit("event-back");
        }, 1200);


      } else {
        CI.DV_saveFailureFeedback = "There was an issue while creating a new consumer. Please reload page and try again.";
        CI.$awn.alert('There was an issue while creating a new consumer. Please reload page and try again.');
        CI.DV_creatingNewCustomer = false;
      }
    },

    removeBusinessAssociation(business) {
      const CI = this;

      let notifier = new AWN();

      notifier.confirm(
        // Label
        "Are you sure you want to remove this business association?",
        
        // On OK
        async function() {
          if (CI.C_consumerUid) {
            let updatedBusinessData = {
              consumerContactsUidsList: [`delete-${CI.C_consumerUid}`]
            }

            let response = await fs37Writer(
              "e",
              window.G_bi2_data.IV_active_employee_info.business_uid,
              window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
              business.id,
              updatedBusinessData
            )

            if (response.success == false) {
              CI.$awn.alert("There was an issue while removing the association of customer with business. Please reload page and try again.");
            } else {
              CI.$awn.success("Successfully removed association of customer with the business.");
            }
          }
        },

        // On cancel
        function() {},

        // Other options
        {
          labels: {
            confirm: 'Remove Association',
            confirmOk: "Confirm",
            confirmCancel: "Cancel"
          }
        }
      );
    },

    getFormattedLandlineNumbers() {
      const CI = this;
      let landline_phone_numbers = {}

      for (let number of CI.DV_unsavedLandlineNumbers) {
        landline_phone_numbers[number.phone_number] = {
          phone_number: number.phone_number,
          extension: number.extension,
          description: number.description,
          phone_type: "2"
        }
      }

      return landline_phone_numbers;
    },

    async retrieveVerificationDocuments() {
      const CI = this;

      if (!CI.C_consumerUid) { return; }

      if (CI.C_consumerUid !== CI.DV_previousConsumerUID) {
        CI.DV_verificationFileLinks = [];
      }

      const consumerIndex = window.G_bi2_data.IV_al_consumer_contacts;
      let consumer_entry = window.G_bi2_data.IV_consumer_contacts.value[CI.C_consumerUid];
      
      CI.DV_previousConsumerUID = CI.C_consumerUid

      if (!consumer_entry[consumerIndex.verified_outputs].verification_documents) {
        let result = await retrieveIdentityVerificationDocuments(
          window.G_bi2_data.IV_active_employee_info.business_uid,
          window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          CI.C_consumerUid
        ); 
        if (result.success === false) { return; }
      
        let file_links = result.response.file_links
        if (file_links.length === 0) { return; }
      
        CI.DV_verificationFileLinks = file_links;
      } else {
        CI.DV_verificationFileLinks = consumer_entry[consumerIndex.verified_outputs].verification_documents;
      }
    }
  },

  watch:{
    P_consumerUid() {
      this.loadCustomerData(0, this);
    },

    DV_consumerBusinesses: {
      handler() {
        if (this.P_viewType === "newCustomer") { return; }
        this.loadCustomerData(0, this);
      },

      deep: true
    }
  }
};
</script>

<style>
.item1 { grid-area: primary; }
.item2 { grid-area: second; }
.item3 { grid-area: third; }

.grid-container {
  display: grid;
  grid-template-areas:
    'primary third third third'
    'third';
  gap: 10px;
}

.loading-cursor {
  cursor: wait;
}
</style>
