<template>
  <div ref="scrollContainer" id="p1s1c2c2" class="border border-gray-400 rounded-xl shadow mt-2 overflow-y-scroll hide-scrollbar h-4/5">
    
    <!-- Desktop - conversations List -->
    <div 
      v-for="(conv, index) in DV_activeConversationsList" 
      :key="`key${(Math.random() * 500)}`"
      v-if="DV_activeRenderMode > DV_renderModePhone"
      class="block" 
    >
      <div 
        @click.stop="openConversation(conv.consumer_uid,conv.conversation_uid)" 
        :class="{'bg-lime-100': DV_openedConversationUuid === conv.uuid}" 
        class="grid grid-cols-8 gap-0 border-b border-gray-400 px-2 items-center cursor-pointer h-full"
      > 
        <!-- Chat status legends -->
        <div class="row-start-1 col-span-2" style="margin-top:5%;margin-bottom:5%;">            
            <!-- Payment/Identity icons -->
            <div class="flex items-center justify-start mt-2">
              <span 
                v-for="icon in showStatusIcons(conv)" 
                class="tooltip-conversation-status-icon ml-1"
              >
                <img :src="icon[0]" class="h-6">
                <span class="text-base tooltiptext-conversation-status-icon"> {{ icon[1] }}</span>
              </span> 
            </div>

            <!-- New Message Count-->
            <span 
                v-if="DV_unreadMessagesCount[conv.uuid] >= 1 && DV_unreadMessagesCount[conv.uuid] <= 99" 
                :class="getBadgeClass(conv.uuid)"
              >
                {{ DV_unreadMessagesCount[conv.uuid]}}
              </span>
              <span 
                v-if="DV_unreadMessagesCount[conv.uuid] >= 100" 
                :class="getBadgeClass(conv.uuid)"
              >99+</span>
        </div>

        <div class="row-start-1 col-start-3 col-span-6 flex flex-row">
          <span class="flex-row flex-wrap w-full">
             
            <div class="flex flex-row">
              <!-- Activity Status Icon -->
              <span class="dot mt-2" :class="conv.activity_status"></span>
              
              <!-- Phone Number and Consumer Name -->
              <span class="whitespace-pre"> {{ getPhoneNumber(conv) }}</span>  
              <span class="whitespace-pre"> {{ getConsumerName(conv)}} </span> 
            </div>
            
            <!-- Associated Business and Invite Info -->
            <span class="text-left block w-full truncate">{{ associatedBusinesses(conv.consumer_uid)}}</span>
            <span class="text-left block w-full truncate">{{inviteInfo(conv)}}</span>
            
            <div v-if="conv.assigned_employee !== DV_currentEmployee.employee_uid && DV_employees[conv.assigned_employee]" class="flex flex-row row-start-4 col-span-8 col-start-3">
              <span
                class="text-left block w-full truncate italic"
              >
                - Designated for {{ DV_employees[conv.assigned_employee].first_name }} {{ DV_employees[conv.assigned_employee].last_name }}
              </span>
            </div>
              
            <!-- Last Message Time -->
            <span
              v-if="conv.uuid in DV_consumerLastMessageText === true"
              class="text-left block w-full truncate"
              v-html="DV_consumerLastMessageText[conv.uuid].timestamp_last_message"
            > 
            </span>
            
            <!-- New message preview -->
            <div class="flex flex-row row-start-4 col-span-8 col-start-3">
              <span
                v-if="conv.uuid in DV_consumerLastMessageText === true && DV_unreadMessagesCount[conv.uuid] === 0"
                class="text-left block w-full truncate text-gray-400 italic"
                v-html="DV_consumerLastMessageText[conv.uuid].last_message_content"
              >
              </span>
              <span
                v-if="conv.uuid in DV_consumerLastMessageText === true && DV_unreadMessagesCount[conv.uuid] >= 1"
                class="text-left block w-full truncate font-bold italic"
                v-html="DV_consumerLastMessageText[conv.uuid].last_message_content"
              >
              </span>
            </div>
          </span>

          <!-- Vehicle and parts info - Expand/Collapse icon and mouse over window-->
          <div 
          v-if="DV_allConversationsDetails[conv.uuid][DV_ccdIndexes.ma2_vehicle_part_summary_data] !== undefined && DV_allConversationsDetails[conv.uuid][DV_ccdIndexes.ma2_vehicle_part_summary_data][DV_ccdIndexes.ma2i_total_count] > 1"
          class="flex flex-row items-center"            
          >
            <div
            class="tooltip-test mt-1"
            ><img class="h-[1vw] w-[1.5vw]" src="../../assets/images/hover_icon.png">
              <div class="tooltiptext-test">
                <!--expanded vehicle / parts info view-->
                <div class="w-11/12">
                
                  <!-- vehicle entries -->
                  <span 
                    v-for="entry in DV_allConversationsDetails[conv.uuid][DV_ccdIndexes.ma1_vehicles_and_parts_array]"
                    class="flex flex-col text-left"
                  >
                    <span 
                      v-if="entry[DV_ccdIndexes.ma1i_type] === 1"
                    >
                      {{ entry[DV_ccdIndexes.ma1i_vehicle_year] + " " + entry[DV_ccdIndexes.ma1i_vehicle_make_name] + " " + entry[DV_ccdIndexes.ma1i_vehicle_model_name] }}
                      <br v-if="entry[DV_ccdIndexes.mali_parts_description]">
                      <span v-if="entry[DV_ccdIndexes.mali_parts_description]" class="ml-2">
                        {{entry[DV_ccdIndexes.mali_parts_description]}}
                      </span>
                    </span>
                  </span>
                  <!-- </end> vehicle entries -->
                  <!-- part number entires -->
                  <span 
                    v-for="entry in DV_allConversationsDetails[conv.uuid][DV_ccdIndexes.ma1_vehicles_and_parts_array]"
                    class="block w-full"
                  >
                    <span v-if="entry[DV_ccdIndexes.ma1i_type] === 2">
                      Part Number: {{ entry[DV_ccdIndexes.ma1i_interchange_part_number] }}
                    </span>
                  </span>
                  <!-- </end> part number entires -->
                </div>
                <!--</end>expanded vehicle / parts info view-->
              </div>
            </div>
            
            <div
              class="pl-2"
              @click.stop="toggleShowingConversationDetails(conv.uuid)"
            >
              <svg v-if="DV_conversationDetailsUUID === conv.uuid" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5"/>
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
              </svg>
            </div>              
          </div>
          <!--</end> Vehicle and parts info - Expand/Collapse icon and mouse over window--> 
        </div>
        
        <!-- vehicle and part details consumer entered on the web widget-->
        <div 
          v-if="DV_allConversationsDetails[conv.uuid][DV_ccdIndexes.ma1_vehicles_and_parts_array] !== undefined"
          class="row-start-3 col-start-3 col-span-8 text-left flex justify-between"
        >

          <!--collasped vehicle / parts info view-->
          <span
            v-if="DV_conversationDetailsUUID !== conv.uuid"
          >
            {{DV_allConversationsDetails[conv.uuid][DV_ccdIndexes.ma2_vehicle_part_summary_data][DV_ccdIndexes.ma2i_last_entry_added_summary]}}
            <span v-if="DV_allConversationsDetails[conv.uuid][DV_ccdIndexes.ma2_vehicle_part_summary_data][DV_ccdIndexes.ma2i_total_count] > 2">
             and {{ DV_allConversationsDetails[conv.uuid][DV_ccdIndexes.ma2_vehicle_part_summary_data][DV_ccdIndexes.ma2i_total_count] -1 }} more</span>
          </span>
          <!--</end> collasped vehicle / parts info view-->

          <!--expanded vehicle / parts info view-->
          <div 
          v-else-if="DV_conversationDetailsUUID === conv.uuid"
          class="w-11/12">
           
            <!-- vehicle entries -->
            <span 
              v-for="entry in DV_allConversationsDetails[conv.uuid][DV_ccdIndexes.ma1_vehicles_and_parts_array]"
              class="flex flex-col text-left"
            >
              <span 
                v-if="entry[DV_ccdIndexes.ma1i_type] === 1"
                :class="{'truncate': (DV_conversationDetailsUUID !== conv.uuid)}" 
              >
                {{ entry[DV_ccdIndexes.ma1i_vehicle_year] + " " + entry[DV_ccdIndexes.ma1i_vehicle_make_name] + " " + entry[DV_ccdIndexes.ma1i_vehicle_model_name] }}
                <br v-if="entry[DV_ccdIndexes.mali_parts_description]">
                <span v-if="entry[DV_ccdIndexes.mali_parts_description]" class="ml-2">
                  {{entry[DV_ccdIndexes.mali_parts_description]}}
                </span>
              </span>
            </span>
            <!-- </end> vehicle entries -->

            <!-- part number entires -->
            <span 
              v-for="entry in DV_allConversationsDetails[conv.uuid][DV_ccdIndexes.ma1_vehicles_and_parts_array]"
              class="block w-full"
              :class="{'truncate': (DV_conversationDetailsUUID !== conv.uuid)}" 
            >
              <span v-if="entry[DV_ccdIndexes.ma1i_type] === 2">
                Part Number: {{ entry[DV_ccdIndexes.ma1i_interchange_part_number] }}
              </span>
            </span>
            <!-- </end> part number entires -->
          </div>
          <!--</end>expanded vehicle / parts info view-->

          <!--</end> vehicle and part details consumer entered on the web widget-->
        </div>
      </div>
    </div>

    <!-- Mobile view -->
    <div
      v-if="DV_activeRenderMode === DV_renderModePhone"
      class="block"
      v-for="(conv, key) in DV_activeConversationsList"
      :key="key"
      @click.stop="openConversation(conv.consumer_uid,conv.conversation_uid)"
    >

      <!-- Conversation's Info  -->
      <div v-if="liveChatAction == false" class="grid grid-cols-8 gap-2 px-2 items-center cursor-pointer">
        <div class="col-span-1 h-full items-start">
          <h1 class="font-bold text-lg text-left"></h1>
        </div>
        <div class="col-span-6">
          <p class="text-left text-sm my-1">
            <span class="block w-full truncate">{{ getPhoneNumber(conv) + " " + getConsumerName(conv)}}</span>
            <span class="block w-full truncate">{{ getVehicleInfo(conv)}}</span>
            <span class="block w-full truncate">{{ associatedBusinesses(conv.consumer_uid)}}</span>
          </p>
        </div>

        <!-- Open/close chat buttons -->
        <div class="col-span-1">
          <div class="flex flex-row justify-around">
            
            <svg v-if="DV_openedConversationUuid === key" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5"/>
            </svg>
            
            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
            </svg>
          </div>
        </div>
      </div>
      
      <!-- Mobile - active chat -->
      <div v-if="liveChatAction == false && DV_openedConversationUuid === key">
        <c1ChatModule @show-image="$emit('show-image', 'liveChat')" :isModal="isModal"/>
        <div class="flex flex-row justify-between items-center border-b p-3">
          <button @click.stop="showLiveChatActions" class="btn btn-lime"> Live Chat Actions</button>
          <button class="btn btn-lime">Upload Image</button>
        </div>
      </div>
    </div>

    <!-- Mobile - live chat actions -->
    <c1c3LiveChatActions
      v-if="liveChatAction == true"
      :conversation_uid = this.DV_selectedConversationUid
      :consumer_uid = this.DV_selectedConsumerUid
      @show-image="$emit('show-image')"
      @show-live-chat="(liveChatAction = false), $emit('show-live-chat')"
    />
  </div>
</template>

<script>
import c1c3LiveChatActions from "../c1/c1c3LiveChatActions.vue";
import c1ChatModule from "../c1/c1ChatModule.vue";
import m2MiscDataFetchers from "../../mixins/m2MiscDataFetchers";
import m3RenderingSettings from "../../mixins/m3RenderingSettings";
import p1s1c2c4ConversationDetails from "./p1s1c2c4ConversationDetails"
import moment from "moment";
import { last } from "lodash";

export default {
  name: "p1s1c2c2ConversationsList",

  components: { c1ChatModule, c1c3LiveChatActions, p1s1c2c4ConversationDetails},

  mixins: [ m2MiscDataFetchers,m3RenderingSettings ],

  props: ["chatAction", "isModal", "P_activeConversationUid", "P_searchText"],

  data() {
    return {
      liveChatAction: false,
      DV_previousActiveConversationsListLength: 0,
      DV_activeConversationsList: {},
      DV_openedConversationUuid: null,
      DV_employeesConversationsList: {},
      DV_currentEmployee: window.G_bi2_data.IV_active_employee_info,
      DV_allConversationsList: {},
      DV_allConversationsDetails: {},
      DV_selectedConversationUid: "",
      DV_selectedConsumerUid: "",
      DV_allPaymentLinks: {},
      DV_ccdIndexes: window.G_bi2_data.IV_al_consumer_conversation_details,
      DV_consumerBusinesses: {},
      DV_consumerBusinessJoins: {},
      DV_consumerLastMessageText: {},
      DV_consumerLastMessageRawData: {},
      DV_conversationDetailsUUID: "",
      DV_consumerContacts: window.G_bi2_data.IV_consumer_contacts.value,
      moment: moment,
      DV_unreadMessagesCount: {},
      DV_unreadMessagesCountRef: {},
      DV_employees: {},
      DV_unreadMessageTimes: {},
    };
  },

  mounted() {
    window.document.title = "Synergy Auto Solutions - Sales Dashboard";
    if (this.chatAction == "liveChatAction") {
      this.liveChatAction = true;
    }

    // Load any saved unread message times from localStorage
    this.loadUnreadMessageTimes();

    this.DV_allPaymentLinks = window.G_bi2_data.IV_consumer_conversation_payment_links.value;
    this.DV_allConversationsDetails = window.G_bi2_data.IV_consumer_conversation_details.value;
    this.DV_allConversationsList = window.G_bi2_data.IV_consumer_conversations.value;
    this.DV_employeesConversationsList = window.G_bi2_data.IV_employees_active_conversations.value;
    this.DV_consumerBusinesses = window.G_bi2_data.IV_consumer_businesses.value;
    this.DV_consumerBusinessJoins = window.G_bi2_data.IV_consumer_business_joins.value;
    this.DV_consumerLastMessageRawData = window.G_bi2_data.IV_consumer_conversation_last_consumer_message.value;
    this.DV_unreadMessagesCountRef = window.G_bi2_data.IV_conversations_unread_messages_count.value;
    this.DV_employees = window.G_bi2_data.IV_recycler_employees.value;
    this.DV_currentEmployee = window.G_bi2_data.IV_active_employee_info;
    this.filterActiveConversations();
    this.updateLastConsumerMessageText();

    let divObject = document.getElementById("p1s1c2c2");
    setTimeout(()=> { divObject.scrollTop = 0; },500);
  },

  methods: {
    saveUnreadMessageTime(uuid) {
      // Save the time in localStorage
      const time = Date.now();
      this.$set(this.DV_unreadMessageTimes, uuid, time);
      window.localStorage.setItem(`p1s1c2c2_DV_unreadMessageTime_${uuid}`, time);
    },

    loadUnreadMessageTimes() {
      // Load the stored times from localStorage on page load
      Object.keys(this.DV_unreadMessagesCount).forEach((uuid) => {
        const storedTime = window.localStorage.getItem(`p1s1c2c2_DV_unreadMessageTime_${uuid}`);
        if (storedTime) {
          this.$set(this.DV_unreadMessageTimes, uuid, parseInt(storedTime));
        }
      });
    },

    getBadgeClass(uuid) {
      const elapsed = (Date.now() - (this.DV_unreadMessageTimes[uuid] || Date.now())) / 1000;
      if (elapsed > 300 && this.DV_unreadMessagesCount[uuid] >= 1) { // 300 seconds = 5 minutes
        return 'badge-critical ml-1';
      }
      return 'badge-normal ml-1';
    },

    openConversation(consumer_uid, conversation_uid) {
      let uuid = consumer_uid + "-" + conversation_uid;

      //user clicked on the already active conversation
      // close currently opened conversation once clicked again 
      if (this.DV_openedConversationUuid === uuid) {

        this.DV_openedConversationUuid = null;
        this.DV_selectedConversationUid = "";
        this.DV_selectedConsumerUid = "";

        this.$emit('event-open-conversation', this.DV_openedConversationUuid);
        return;
      } 

      this.DV_openedConversationUuid = uuid ;
      this.DV_selectedConversationUid = conversation_uid;
      this.DV_selectedConsumerUid = consumer_uid;

      this.$emit('event-open-conversation', this.DV_openedConversationUuid )
    },

    toggleShowingConversationDetails(consumer_conversation_uuid) {
      // Close details
      if (this.DV_conversationDetailsUUID == consumer_conversation_uuid) { 
        this.DV_conversationDetailsUUID = null;
      
      // Open details
      } else { 
        this.DV_conversationDetailsUUID = consumer_conversation_uuid;
      }
    },

    showLiveChatActions() {
      this.liveChatAction = true;
      this.$emit("chat-action", "liveChatAction");
    },

    filterActiveConversations() {
      const CI = this;

      let consumerConversations = window.G_bi2_data.IV_consumer_conversations.value;
      let employeesActiveConversations = window.G_bi2_data.IV_employees_active_conversations.value;
      
      let activeConversationsList = _.filter(consumerConversations, (conv, uid) => {
        
        // Dont show queued/finished conversations
        if (conv.status_codes.includes("z")) { return false; }
        if (conv.status_codes.includes("a")) { return false; }

        // if user is an admin, include all employees customer conversations
        // if user is a sales person, only show their customer conversations
        if (window.G_bi2_data.IV_active_employee_info.permissions.sales_person) {
          if (uid in employeesActiveConversations === false) { return false; }
        }
 
        if (CI.P_searchText) {
          // exact case matching
          if (conv.consumer_name && conv.consumer_name.includes(CI.P_searchText)) { return true; }
          if (conv.business_name && conv.business_name.includes(CI.P_searchText)) { return true; }
          
          // lower case matching
          if (conv.consumer_name && conv.consumer_name.toLowerCase().includes(CI.P_searchText)) { return true; }
          if (conv.phone_number && conv.phone_number.toLowerCase().includes(CI.P_searchText)) { return true; }
          if (conv.business_name && conv.business_name.toLowerCase().includes(CI.P_searchText)) { return true; }
        } else {
          return true;
        }

        return false;
      });
 
      let conversationsWithoutLastMessageTimestamp = [];

      activeConversationsList.filter(conv => {
        if (conv.last_message_timestamp === "") {
          conversationsWithoutLastMessageTimestamp.push(conv)
        }
      });
 
      activeConversationsList = activeConversationsList.filter(conv => !conversationsWithoutLastMessageTimestamp.includes(conv));
      activeConversationsList = activeConversationsList.sort((conv1, conv2) => {
        return new Date(conv2.last_message_timestamp.toDate()) - new Date(conv1.last_message_timestamp.toDate());
      });

      activeConversationsList = activeConversationsList.concat(conversationsWithoutLastMessageTimestamp);

      if (activeConversationsList.length > CI.DV_previousActiveConversationsListLength) {
        let divObject = document.getElementById("p1s1c2c2");
        setTimeout(()=> { divObject.scrollTop = 0; },500);
      }
      
      CI.DV_previousActiveConversationsListLength = activeConversationsList.length
      CI.DV_activeConversationsList = activeConversationsList;    
    },

    getPhoneNumber(conv) {
      let phone_number = ""

      if (conv.phone_number) { 
        phone_number = `${this.formatPhoneNumber(conv.phone_number)}` 
      }

      return phone_number;
    },

    getConsumerName(conv) {
      let name = ""

      if (conv.consumer_name && conv.consumer_name.length > 1 && !conv.consumer_name.toLowerCase().includes("test") && !conv.consumer_name.toLowerCase().includes("unknown")) { 
        name = `${conv.consumer_name}` 
      }

      if (name.length > 15) {
        name = name.slice(0, 15);
        name += "...";
      }

      return name;
    },

    getVehicleInfo(conv) {
      let info = ""
      const consumer_and_conversation_uuid = conv.consumer_uid + "-" + conv.conversation_uid
      
      if (consumer_and_conversation_uuid in window.G_bi2_data.IV_consumer_conversation_details.value) {
        let data = window.G_bi2_data.IV_consumer_conversation_details.value[consumer_and_conversation_uuid]

        if (data[this.DV_ccdIndexes.ma1_vehicles_and_parts_array][this.DV_ccdIndexes.ma1i_vehicle_make_name]) {
          info += `${data[this.DV_ccdIndexes.ma1_vehicles_and_parts_array][this.DV_ccdIndexes.ma1i_vehicle_make_name]}`  
        }

        if (data[this.DV_ccdIndexes.ma1_vehicles_and_parts_array][this.DV_ccdIndexes.ma1i_vehicle_model_name]) {
          info += ` ${data[this.DV_ccdIndexes.ma1_vehicles_and_parts_array][this.DV_ccdIndexes.ma1i_vehicle_model_name]}`
        }

        if (data[this.DV_ccdIndexes.ma1_vehicles_and_parts_array][this.DV_ccdIndexes.ma1i_vehicle_year]) {
          info += ` ${data[this.DV_ccdIndexes.ma1_vehicles_and_parts_array][this.DV_ccdIndexes.ma1i_vehicle_year]}`
        }
      }

      return info;
    },

    openNewlyCreatedConversation() {
      if(window.G_bi2_data.IV_last_new_conversation_phone_number === null || window.G_bi2_data.IV_last_new_conversation_phone_number === "") {return;}
      this.$emit('close-new-conversation');
      setTimeout(()=> { this.scrollListToBottom(); },500);

      for(let entry_key in this.DV_employeesConversationsList) {
        let conv_key = this.DV_employeesConversationsList[entry_key].uuid;
        let enterQueueTime = moment(this.DV_allConversationsList[conv_key].entered_queue_timestamp.toDate())
        let currentTime = moment()
        let duration = moment.duration(currentTime.diff(enterQueueTime))

        if((this.DV_allConversationsList[conv_key].phone_number === window.G_bi2_data.IV_last_new_conversation_phone_number) && 
          (duration.seconds() < 20)) {
         
          if (this.DV_openedConversationUuid === this.DV_employeesConversationsList[entry_key].conversation_uid) {
            continue;
          }
          
          this.openConversation(
            this.DV_employeesConversationsList[entry_key].consumer_uid,
            this.DV_employeesConversationsList[entry_key].conversation_uid
          );
          window.G_bi2_data.IV_last_new_conversation_uid = null;
          window.G_bi2_data.IV_last_new_conversation_phone_number = null;
          return;
        }
      }
    },

    associatedBusinesses(consumer_uid) {
      const CI = this;
        // Setting associated businesses
        if(consumer_uid in CI.DV_consumerBusinessJoins === false) { return ""; }
        let associatedBusinesses = []
          for (let array_index in CI.DV_consumerBusinessJoins[consumer_uid]) {
            let business_uid = CI.DV_consumerBusinessJoins[consumer_uid][array_index];
            const businessInfo = CI.DV_consumerBusinesses[business_uid];
            if (!businessInfo.name) { continue; };
              associatedBusinesses.push(businessInfo.name);          
          }
        

      return associatedBusinesses.join(", ")
    },

    inviteInfo(conv) {
      let invite = window.G_bi2_data.IV_yard_side_chat_invites.value[conv.uuid]
      if (!invite) { return '' }
      let employee = window.G_bi2_data.IV_recycler_employees.value[invite.inviting_employee_uid];
      if (!employee) { return ''}
      return `Invited by ${employee.first_name} ${employee.last_name}`
    },

    showStatusIcons(conv) {
      let status_entries = []

      if (conv.uuid in this.DV_allPaymentLinks && Object.keys(this.DV_allPaymentLinks[conv.uuid]).length > 0) {
        
        // Check consumer's business status
        const consumerBusinessJoins = window.G_bi2_data.IV_consumer_business_joins.value;
        let consumer_uid = conv.uuid.split("-")[0];

        if (consumer_uid in consumerBusinessJoins && consumerBusinessJoins[consumer_uid].length > 0) {
          status_entries.push([require('../../assets/images/conversation_status_icons/consumer_type_business.svg'), "Consumer associated with a business"]);
        }

        // Check identity status
        if (conv.consumer_uid in this.DV_consumerContacts) {
          let details = this.DV_consumerContacts[conv.consumer_uid][window.G_bi2_data.IV_al_consumer_contacts.verified_outputs]

          if (Object.keys(details).length != 0 && details.matched_profile != undefined) {
            if (details.matched_profile === 1) {
              status_entries.push([require('../../assets/images/conversation_status_icons/identity_failed.png'), "Consumer failed to verify the identity"]);
            } else if (details.matched_profile === 2) {
              status_entries.push([require('../../assets/images/conversation_status_icons/identity_verified.png'), "Consumer verified their identity successfully"]);
            } else if (details.matched_profile === 3) {
              status_entries.push([require('../../assets/images/conversation_status_icons/identity_pending.png'), "Consumer identity verification is pending"]);
            }
          }
        }
        
        // Check for paid links
        let paidLinks = Object.values(this.DV_allPaymentLinks[conv.uuid]).filter((link) => {
          return (link["payment_status"] && link["payment_status"] == "paid");
        });
        if (paidLinks.length > 0) { 
          status_entries.push([require('../../assets/images/conversation_status_icons/payment_link_paid.png'), "Payment Link has been paid"]);
        }

        // Check for unpaid links
        let unpaidLinks = Object.values(this.DV_allPaymentLinks[conv.uuid]).filter((link) => {
          return (!link["payment_status"] || (link["payment_status"] == "no_status"  || link["payment_status"] == "in_progress"));
        });
        if (unpaidLinks.length > 0) { 
          status_entries.push([require('../../assets/images/conversation_status_icons/payment_link_pending.png'), "Payment Link has been sent to the customer and has not been paid"]);
        }

        // Check for failed links
        let failedLinks = Object.values(this.DV_allPaymentLinks[conv.uuid]).filter((link) => {
          return (link["payment_status"] && link["payment_status"] == "failed");
        });
        if (failedLinks.length > 0) { 
          status_entries.push([require('../../assets/images/conversation_status_icons/payment_link_failed.png'), "Payment Link has been sent to the customer and their payment failed"]);
        }
      } 

      return status_entries;
    },

    updateLastConsumerMessageText() {
      for( let conversation_uuid in this.DV_consumerLastMessageRawData) {
        let entry = this.DV_consumerLastMessageRawData[conversation_uuid];
        let timestamp_raw = entry[window.G_bi2_data.IV_al_consumer_conversation_messages.timestamp];
        let momentTime = moment(timestamp_raw.toDate());
        let timestamp_string = `${momentTime.format('h:mm A')}`;
        timestamp_string = timestamp_string[0].toUpperCase() + timestamp_string.slice(1);
        let timestamp_last_message = `<b>${timestamp_string}</b>` 
        let last_message_content = entry[window.G_bi2_data.IV_al_consumer_conversation_messages.message_content];
 
        if (last_message_content === null) {
          last_message_content = "";
        }
       
        if (last_message_content.length > 20) {
          last_message_content = last_message_content.slice(0, 20);
          last_message_content += "...";
        }
        this.DV_consumerLastMessageText[conversation_uuid] = {
          "timestamp_last_message": timestamp_last_message,
          "last_message_content": last_message_content,
        };
      }
    },

    scrollListToBottom() {
      let divObject = document.getElementById("p1s1c2c2");
      divObject.scrollTop = divObject.scrollHeight;
    }
  },

  watch: {
    DV_consumerLastMessageRawData: {
      handler() {
        this.updateLastConsumerMessageText();
      },
      deep: true
    },
    DV_employeesConversationsList: {
      handler() {
        this.filterActiveConversations();
        this.openNewlyCreatedConversation();
      },
      deep: true
    },

    DV_allConversationsList: {
      handler() {
        this.filterActiveConversations();
      },
      deep: true
    },

    P_activeConversationUid() {
      const CI = this;
      CI.DV_openedConversationUuid = CI.P_activeConversationUid;
    },

    P_searchText() {
      this.filterActiveConversations();
    },

    DV_unreadMessagesCountRef: {
      handler() {
        const CI = this;
        CI.DV_unreadMessagesCount = window.G_bi2_data.IV_conversations_unread_messages_count.value;

        // Watch for changes in unread message count
        setInterval(() => {
          Object.keys(this.DV_unreadMessagesCount).forEach((uuid) => {
            if (this.DV_unreadMessagesCount[uuid] >= 1 && !this.DV_unreadMessageTimes[uuid]) {
              this.saveUnreadMessageTime(uuid); // Save time when unread message count appears
            } else if (this.DV_unreadMessagesCount[uuid] === 0) {
              window.localStorage.removeItem(`p1s1c2c2_DV_unreadMessageTime_${uuid}`)
              this.DV_unreadMessageTimes = {};
            }
          });
        }, 1000); // Check every second
      },
      deep: true
    }
  }
};
</script>

<style>
.badge-normal {
  position: right;
  font-size: 12px;
  border-radius: 20%;
  background: rgb(30, 58, 138);
  color: white;
  font-weight: 600;
  width: 25px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}

.badge-critical {
  position: right;
  font-size: 12px;
  border-radius: 20%;
  background: red;
  color: white;
  font-weight: 600;
  width: 25px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}
</style>