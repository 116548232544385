<template>
  <div id="p1s1c1c1" class="border border-gray-400 shadow-sm rounded-xl h-[89%]">
    <div class="message-list overflow-y-scroll h-full rounded-xl hide-scrollbar">
      
      <!-- List item -->
      <div
        v-for="conv in C_filteredQueueConversationsList"
        :key="conv[DV_ccdIndexes.conversation_uid]"
        @click.stop="toggleSelectedConversation(conv[DV_ccdIndexes.consumer_and_conversation_uuid])"
        :class="{'bg-lime-100': DV_openedConversationUuid === conv[DV_ccdIndexes.consumer_and_conversation_uuid]}"
        class="message cursor-pointer grid grid-cols-8 gap-2 border-b items-center"
      >
        <!-- List item - Left logo -->
        <h1 class="font-bold text-2xl grid-cols-1"></h1>
        
        <!-- List item - Main content -->
        <div class="col-span-6">
          <div class="flex flex-col my-3">
            <span class="flex flex-row w-full">
              <span 
                class="flex flex-row w-full"
              >
                {{getPersonalInfo(conv)}}
              </span>
            <!-- Vehicle and parts info - Expand/Collapse icon and mouse over window-->
              <div 
              v-if="DV_allConversationsDetails[conv[DV_ccdIndexes.consumer_and_conversation_uuid]][DV_ccdIndexes.ma2_vehicle_part_summary_data] !== undefined && DV_allConversationsDetails[conv[DV_ccdIndexes.consumer_and_conversation_uuid]][DV_ccdIndexes.ma2_vehicle_part_summary_data][DV_ccdIndexes.ma2i_total_count] > 1"
              class="flex flex-row items-center"
              
              >
                <div
                class="tooltip-test"
                ><img class="h-[1vw] w-[1.5vw]" src="../../assets/images/hover_icon.png">
                  <div class="tooltiptext-test">
                    <!--expanded vehicle / parts info view-->
                    <div class="w-11/12">
                    
                      <!-- vehicle entries -->
                      <span 
                        v-for="entry in DV_allConversationsDetails[conv[DV_ccdIndexes.consumer_and_conversation_uuid]][DV_ccdIndexes.ma1_vehicles_and_parts_array]"
                        class="flex flex-col text-left"
                      >
                        <span 
                          v-if="entry[DV_ccdIndexes.ma1i_type] === 1"
                        >
                          {{ entry[DV_ccdIndexes.ma1i_vehicle_year] + " " + entry[DV_ccdIndexes.ma1i_vehicle_make_name] + " " + entry[DV_ccdIndexes.ma1i_vehicle_model_name] }}
                          <br v-if="entry[DV_ccdIndexes.mali_parts_description]">
                          <span v-if="entry[DV_ccdIndexes.mali_parts_description]" class="ml-2">
                            {{entry[DV_ccdIndexes.mali_parts_description]}}
                          </span>
                        </span>
                      </span>
                      <!-- </end> vehicle entries -->

                      <!-- part number entires -->
                      <span 
                        v-for="entry in DV_allConversationsDetails[conv[DV_ccdIndexes.consumer_and_conversation_uuid]][DV_ccdIndexes.ma1_vehicles_and_parts_array]"
                        class="block w-full"
                      >
                        <span v-if="entry[DV_ccdIndexes.ma1i_type] === 2">
                          Part Number: {{ entry[DV_ccdIndexes.ma1i_interchange_part_number] }}
                        </span>
                      </span>
                      <!-- </end> part number entires -->
                    </div>
                    <!--</end>expanded vehicle / parts info view-->
                  </div>
              </div>            
            </div>
            <!--</end> Vehicle and parts info - Expand/Collapse icon and mouse over window-->
            </span>      
            <span class="flex flex-col text-left font-medium">
                    
              <span 
                class="block"
                :class="{'truncate': DV_openedConversationUuid !== conv[DV_ccdIndexes.consumer_and_conversation_uuid]}" 
              >
                <span>{{ associatedBusinesses(conv[DV_ccdIndexes.consumer_uid]) }}</span>
              </span>                                           
            </span>
            <!-- vehicle and part details consumer entered on the web widget-->        
            <div
          v-if="DV_allConversationsDetails[conv[DV_ccdIndexes.consumer_and_conversation_uuid]][DV_ccdIndexes.ma1_vehicles_and_parts_array] !== undefined"
          class="row-start-3 col-start-1 col-span-8 text-left flex justify-between"
        >

          <!--collasped vehicle / parts info view-->
          <span
            v-if="DV_openedConversationUuid !== conv[DV_ccdIndexes.consumer_and_conversation_uuid]"
          >
            {{DV_allConversationsDetails[conv[DV_ccdIndexes.consumer_and_conversation_uuid]][DV_ccdIndexes.ma2_vehicle_part_summary_data][DV_ccdIndexes.ma2i_last_entry_added_summary]}}
            <span v-if="DV_allConversationsDetails[conv[DV_ccdIndexes.consumer_and_conversation_uuid]][DV_ccdIndexes.ma2_vehicle_part_summary_data][DV_ccdIndexes.ma2i_total_count] > 2">
             and {{ DV_allConversationsDetails[conv[DV_ccdIndexes.consumer_and_conversation_uuid]][DV_ccdIndexes.ma2_vehicle_part_summary_data][DV_ccdIndexes.ma2i_total_count] -1 }} more</span>
          </span>
          <!--</end> collasped vehicle / parts info view-->

          <!--expanded vehicle / parts info view-->
          <div
          v-else-if="DV_openedConversationUuid === conv[DV_ccdIndexes.consumer_and_conversation_uuid]"
          class="w-full">
           
            <!-- vehicle entries -->
            <span 
              v-for="entry in DV_allConversationsDetails[conv[DV_ccdIndexes.consumer_and_conversation_uuid]][DV_ccdIndexes.ma1_vehicles_and_parts_array]"
              class="flex flex-col text-left"
            >
              <span 
                v-if="entry[DV_ccdIndexes.ma1i_type] === 1"
              >
                {{ entry[DV_ccdIndexes.ma1i_vehicle_year] + " " + entry[DV_ccdIndexes.ma1i_vehicle_make_name] + " " + entry[DV_ccdIndexes.ma1i_vehicle_model_name] }}
                <br v-if="entry[DV_ccdIndexes.mali_parts_description]">
                <span v-if="entry[DV_ccdIndexes.mali_parts_description]" class="ml-2">
                  {{entry[DV_ccdIndexes.mali_parts_description]}}
                </span>
              </span>
            </span>
            <!-- </end> vehicle entries -->

            <!-- part number entires -->
            <span 
              v-for="entry in DV_allConversationsDetails[conv[DV_ccdIndexes.consumer_and_conversation_uuid]][DV_ccdIndexes.ma1_vehicles_and_parts_array]"
              class="block w-full"
            >
              <span v-if="entry[DV_ccdIndexes.ma1i_type] === 2">
                Part Number: {{ entry[DV_ccdIndexes.ma1i_interchange_part_number] }}
              </span>
            </span>
            <!-- </end> part number entires -->
          </div>
          <!--</end>expanded vehicle / parts info view-->

          </div>
          <!--</end> vehicle and part details consumer entered on the web widget-->
          <span class="font-medium text-left mr-2">Entered Queue {{moment(conv[DV_ccdIndexes.entered_queue_timestamp].seconds * 1000).fromNow()}}
            <span v-html="getMessageContent(DV_lastConsumerConversationMessages[conv[DV_ccdIndexes.consumer_and_conversation_uuid]])"></span>
          </span>

            <div v-if="DV_openedConversationUuid === conv[DV_ccdIndexes.consumer_and_conversation_uuid]" class=" flex flex-row flex-wrap justify-between my-2 pr-2">
              <div class="flex flex-wrap justify-between w-full">
                <button @click.stop="chatNow(conv)" class="btn btn-lime px-2 w-[49%] mb-2">Chat Now</button>

                <button @click.stop="$emit('event-open-profile-modal', conv[DV_ccdIndexes.consumer_uid])" class="btn btn-lime px-2 w-[49%] mb-2">
                  Profile
                </button>
              </div>

              <div class="flex flex-wrap justify-between w-full">
                <button @click.stop="$emit('event-open-consumer-feedback-log-modal', conv[DV_ccdIndexes.consumer_uid])" class="btn btn-lime px-1 w-[49%] mb-2">
                  Notes
                </button>
  
                <button @click.stop="$emit('event-open-chat-log-modal', conv[DV_ccdIndexes.consumer_uid])" class="btn btn-lime px-2 w-[49%] mb-2">
                  Chat History
                </button>
              </div>
            </div>

            <!-- Mobile view only -->
            <div class="md:hidden block" v-show="DV_openedConversationUuid === conv[DV_ccdIndexes.consumer_and_conversation_uuid]">
              <p class="text-left text-xs">
                <span class="text-sm font-medium">{{moment().subtract(Math.floor(Math.random() * 200), 'minutes').fromNow()}}: </span> 
                <span>{{conv[DV_ccdIndexes.message_uid]}}</span>
              </p>
              <div class="flex flex-row items-center justify-around my-2 pr-2">
                <!-- <button @click.stop="" class="btn btn-lime text-sm px-2">Chat Later</button> -->
                <button @click.stop="$emit('set-mobile-view')" class="btn btn-lime text-sm px-2">Chat</button>
              </div>
              <button @click.stop="$emit('event-open-profile-modal', conv[DV_ccdIndexes.consumer_uid])" class="btn btn-lime text-sm px-2 flex mx-auto">
                Open Customer Profile
              </button>
            </div>
          </div>
        </div>
        
        <!-- List item - Expand/Collapse icon -->
        <div class="grid-cols-1 pr-2">
          <svg v-if="DV_openedConversationUuid === conv[DV_ccdIndexes.consumer_and_conversation_uuid]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5"/>
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from 'moment'
import { fs43Writer } from '../../includes/firestore_writers/fs43_writer.ts'
import { fs45Writer } from '../../includes/firestore_writers/fs45_writer.ts'
import { fs46Writer } from '../../includes/firestore_writers/fs46_writer.ts'
import { fs6Writer } from '../../includes/firestore_writers/fs6_writer.ts'
import { fs93Writer } from '../../includes/firestore_writers/fs93_writer.ts'
import sendMessageToConsumer from "../../includes/server_calls/p1s5/p1s5e2"
import activeConversationTextMessageNotification from "../../includes/server_calls/p1s3/p1s3e47"
import m2MiscDataFetchers from "../../mixins/m2MiscDataFetchers"
import AWN from "awesome-notifications";

export default {
  name: "p1s1c2c3CustomersList",

  props: ['P_customersSortFilter'],

  mixins: [ m2MiscDataFetchers ],

  data() {
    return {
      DV_queueConversationsList: {},
      DV_consumerContactsList: {},
      DV_ccdIndexes: window.G_bi2_data.IV_al_consumer_conversation_details,
      DV_openedConversationUuid: null,
      DV_conversationMessages: {},
      DV_lastConsumerConversationMessages: {},
      DV_allConversationsDetails: {},
      DV_recyclerLocation: window.G_bi2_data.IV_recyclers[window.G_bi2_data.IV_active_employee_info.recycler_location_uid],
      moment: moment
    };
  },

  mounted() {
    this.DV_queueConversationsList = window.G_bi2_data.IV_conversation_queue_entries.value;
    this.DV_consumerContactsList = window.G_bi2_data.IV_consumer_contacts.value;
    this.DV_conversationMessages = window.G_bi2_data.IV_consumer_conversation_messages.value;
    this.DV_allConversationsDetails = window.G_bi2_data.IV_consumer_conversation_details.value;
    this.DV_lastConsumerConversationMessages = window.G_bi2_data.IV_consumer_conversation_last_consumer_message.value;
  },

  computed: {
    C_activeQueueConversationsList() {
      return _.filter(window.G_bi2_data.IV_consumer_conversation_details.value, (conv, uuid) => {
        return uuid in window.G_bi2_data.IV_conversation_queue_entries.value;
      });

    },
  
    C_filteredQueueConversationsList() {
      const CI = this;

      if (CI.P_customersSortFilter === "time") {
        return _.sortBy(CI.C_activeQueueConversationsList, (item) => moment(item[1])).reverse();
      }

      if (CI.P_customersSortFilter === "rating") {
        return _.sortBy(CI.C_activeQueueConversationsList, (item) => CI.DV_consumerContactsList[item[0]][7])
      }
    }
  },

  methods: {
    startActiveConversationsListener(retry_count=1) {
      const CI = this;
      if (retry_count >= 10) { return; }

      if (!window.G_bi2_data.IV_active_employee_info.business_uid || !window.G_bi2_data.IV_active_employee_info.recycler_location_uid) {
        setTimeout( function() {CI.startActiveConversationsListener(++retry_count)}, 1000);
        return
      };
      window.G_bi2_data.startEmployeeActiveConsumerConversationJoinsListener(
      window.G_bi2_data,
      window.G_bi2_data.IV_active_employee_info.business_uid,
      window.G_bi2_data.IV_active_employee_info.recycler_location_uid,      
      window.G_bi2_data.IV_active_employee_info.employee_uid
    );
    },

    toggleSelectedConversation(uuid) {
      if (this.DV_openedConversationUuid == uuid) { this.DV_openedConversationUuid = null }
      else { this.DV_openedConversationUuid = uuid }
    },

    async chatNow(conv) {
      const CI = this;

      let conversation_uid = conv[CI.DV_ccdIndexes.conversation_uid]
      let consumer_uid = conv[CI.DV_ccdIndexes.consumer_uid]
      let conv_uuid = consumer_uid + "-" + conversation_uid

      let currentBusinessUid = window.G_bi2_data.IV_active_employee_info.business_uid;
      let currentRecyclerLocationUid = window.G_bi2_data.IV_active_employee_info.recycler_location_uid;

      // Update chat status to fs6
      await fs6Writer(
        "e", 
        currentBusinessUid, 
        currentRecyclerLocationUid, 
        consumer_uid, 
        conversation_uid,
        {
          conversation_status_codes: "b", 
          assigned_employee_uid: window.G_bi2_data.IV_active_employee_info.employee_uid,
          chat_start_timestamp: new Date()
        }
      );

      // adds the queue entry UID to fs46
      await fs46Writer(
        "n",
        currentBusinessUid, 
        currentRecyclerLocationUid, 
        window.G_bi2_data.IV_active_employee_info.employee_uid,
        consumer_uid,
        conversation_uid,
        {conversation_uid: conversation_uid, consumer_uid: consumer_uid}
      )

      // adds the queue entry UID to fs45
      await fs45Writer(
        "n",
        currentBusinessUid, 
        currentRecyclerLocationUid,
        consumer_uid,
        conversation_uid,
        {conversation_uid: conversation_uid, consumer_uid: consumer_uid}
      );
      
      //put a message in chat letting the consumer know a chat has started
      const employee_uid = window.G_bi2_data.IV_active_employee_info.employee_uid;
      const first_name = window.G_bi2_data.IV_recycler_employees.value[employee_uid]["first_name"];
      const last_name = window.G_bi2_data.IV_recycler_employees.value[employee_uid]["last_name"];
      let join_message = "[FORCE_SENDER_TYPE_4]" + first_name + " has joined the conversation"
      let params = {
          recycler_uid: window.G_bi2_data.IV_active_employee_info.business_uid,
          recycler_location_uid: window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
          employee_uid: employee_uid,
          consumer_uid: consumer_uid,
          conversation_uid: conversation_uid,
          message_type: 1,
          content: join_message ,
          file_name: ""
        }

      sendMessageToConsumer(params);
      CI.DV_openedConversationUuid = null;
      CI.$emit("event-open-conversation", conv_uuid);

      // send a text message to the consumer to let them know that a yard staff is ready to assist them
      if (CI.DV_recyclerLocation.sms_only_messaging === false) {
        activeConversationTextMessageNotification(
            window.G_bi2_data.IV_active_employee_info.business_uid,
            window.G_bi2_data.IV_active_employee_info.recycler_location_uid,
            conv[CI.DV_ccdIndexes.consumer_uid],
            conv[CI.DV_ccdIndexes.conversation_uid]
        );
      }
    },

    getPersonalInfo(conv) {
      let title = ""

      if (conv[this.DV_ccdIndexes.phone_number]) { 
        title += `${this.formatPhoneNumber(conv[this.DV_ccdIndexes.phone_number])}` 
      }

      if (conv[this.DV_ccdIndexes.consumer_name] && conv[this.DV_ccdIndexes.consumer_name].length > 1 && !conv[this.DV_ccdIndexes.consumer_name].toLowerCase().includes("test") && !conv[this.DV_ccdIndexes.consumer_name].toLowerCase().includes("unknown")) { 
        title += ` | ${conv[this.DV_ccdIndexes.consumer_name]}` 
      }

      return title;
    },

    getMessageContent(conv) {
      let content = "";

      if (!conv) {
        return content;
      }

      if (conv[5]) {
        content += `: ${conv[5]}`
      }

      return content;
    },

    toggleShowingConversationDetails(consumer_conversation_uuid) {
      // Close details
      if (this.DV_openedConversationUuid == consumer_conversation_uuid) { 
        this.DV_openedConversationUuid = null;
      
      // Open details
      } else { 
        this.DV_openedConversationUuid = consumer_conversation_uuid;
      }
    },

    associatedBusinesses(consumer_uid) {
      let consumerBusinessJoins = window.G_bi2_data.IV_consumer_business_joins.value;

      // Setting associated businesses
      if(consumer_uid in consumerBusinessJoins === false) { return ""; }
      
      let associatedBusinesses = []
      
      for (let array_index in consumerBusinessJoins[consumer_uid]) {
        let business_uid = consumerBusinessJoins[consumer_uid][array_index];
        const businessInfo = window.G_bi2_data.IV_consumer_businesses.value[business_uid];
        if (!businessInfo.name) { continue; };
          associatedBusinesses.push(businessInfo.name);          
      }

      return associatedBusinesses.join(", ")
    }
  },
};
</script>

<style scoped>
a {
  color: blue !important;
}
.message-list :last-child.message {
  border-bottom: none !important;
}
.last-message-container {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: baseline;
}
</style>
